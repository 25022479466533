var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"draw",rawName:"v-draw"}],staticClass:"total drag",attrs:{"id":"CfbParameter5"}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"title-hang flex"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.projectData.project)+"_参数05_1")]),_c('div',{staticClass:"icon",on:{"click":_vm.closeCompon}})])]),_vm._m(0),_c('div',{staticClass:"context flex"},[_c('div',{staticClass:"one"},[_c('div',{staticClass:"shadow shadow1 flex"},[_c('div',{staticClass:"column1"},[_vm._v("F01-SP")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
              _vm.infoList.FYSP_B1,
              'F01-SP',
              'FYSP_B1',
              _vm.infoList.FYSP_B1_node_string
            )}}},[_vm._v(" "+_vm._s("FYSP_B1" in _vm.infoList ? _vm.infoList.FYSP_B1 : 0)+" ")])]),_c('div',{staticClass:"shadow shadow2"},[_c('div',{staticClass:"flex float2"},[_c('div',{staticClass:"button_column1"},[_c('div',{staticClass:"button"},[_c('div',{staticClass:"button_word",style:({
                  background: _vm.infoList.SELYF == 1 ? '#2AFC30' : 'red',
                }),on:{"click":function($event){return _vm.toIpt(
                    '1',
                    '负压1',
                    'SELYF',
                    _vm.infoList.SELYF_node_string,
                    'true'
                  )}}},[_vm._v(" 负压1 ")])])]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                2,
                'PT124D_B',
                _vm.infoList.PT124D_B_node_string,
                'PT124D_BVV1',
                '炉膛负压1'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.PT124D_B,
                'PT124D_B',
                _vm.infoList.PT124D_B_node_string,
                'PT124D_BVV1'
              )}}},[_vm._v(" "+_vm._s("PT124D_B" in _vm.infoList ? _vm.infoList.PT124D_B : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"button_column1"},[_c('div',{staticClass:"button"},[_c('div',{staticClass:"button_word",style:({
                  background: _vm.infoList.SELYF == 2 ? '#2AFC30' : 'red',
                }),on:{"click":function($event){return _vm.toIpt(
                    '2',
                    '负压2',
                    'SELYF',
                    _vm.infoList.SELYF_node_string,
                    'true'
                  )}}},[_vm._v(" 负压2 ")])])]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                2,
                'PT125D_B',
                _vm.infoList.PT125D_B_node_string,
                'PT125D_BVV1',
                '炉膛负压2'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.PT125D_B,
                'PT125D_B',
                _vm.infoList.PT125D_B_node_string,
                'PT125D_BVV1'
              )}}},[_vm._v(" "+_vm._s("PT125D_B" in _vm.infoList ? _vm.infoList.PT125D_B : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"button_column1"},[_c('div',{staticClass:"button"},[_c('div',{staticClass:"button_word",style:({
                  background: _vm.infoList.SELYF == 4 ? '#2AFC30' : 'red',
                }),on:{"click":function($event){return _vm.toIpt(
                    '4',
                    '负压3',
                    'SELYF',
                    _vm.infoList.SELYF_node_string,
                    'true'
                  )}}},[_vm._v(" 负压3 ")])])]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                2,
                'PT126D_B',
                _vm.infoList.PT126D_B_node_string,
                'PT126D_BVV1',
                '炉膛负压3'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.PT126D_B,
                'PT126D_B',
                _vm.infoList.PT126D_B_node_string,
                'PT126D_BVV1'
              )}}},[_vm._v(" "+_vm._s("PT126D_B" in _vm.infoList ? _vm.infoList.PT126D_B : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"button_column1"},[_c('div',{staticClass:"button"},[_c('div',{staticClass:"button_word",style:({
                  background: _vm.infoList.SELYF == 8 ? '#2AFC30' : 'red',
                }),on:{"click":function($event){return _vm.toIpt(
                    '8',
                    '负压4',
                    'SELYF',
                    _vm.infoList.SELYF_node_string,
                    'true'
                  )}}},[_vm._v(" 负压4 ")])])]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                2,
                'PT127D_B',
                _vm.infoList.PT127D_B_node_string,
                'PT127D_BVV1',
                '炉膛负压4'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.PT127D_B,
                'PT127D_B',
                _vm.infoList.PT127D_B_node_string,
                'PT127D_BVV1'
              )}}},[_vm._v(" "+_vm._s("PT127D_B" in _vm.infoList ? _vm.infoList.PT127D_B : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1 float1"},[_vm._v("F01-TC")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.TCFY,
                'F01-TC',
                'TCFY',
                _vm.infoList.TCFY_node_string
              )}}},[_vm._v(" "+_vm._s("TCFY" in _vm.infoList ? _vm.infoList.TCFY : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1 float1"},[_vm._v("F01-YZ")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.FYHZYZ,
                'F01-YZ',
                'FYHZYZ',
                _vm.infoList.FYHZYZ_node_string
              )}}},[_vm._v(" "+_vm._s("FYHZYZ" in _vm.infoList ? _vm.infoList.FYHZYZ : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1 float1"},[_vm._v("F01-PV")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                2,
                'PV',
                _vm.infoList.XK06_node_string,
                'XK06_PVVV1',
                'XK06测量值',
                'XK06'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.XK06.PV,
                'PV',
                _vm.infoList.XK06_node_string,
                'XK06_PVVV1'
              )}}},[_vm._v(" "+_vm._s("XK06" in _vm.infoList ? _vm.infoList.XK06.PV : 0)+" ")])])]),_c('div',{staticClass:"shadow shadow3 flex"},[_c('div',{staticClass:"column1 float1"},[_vm._v("F02-SP")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
              _vm.infoList.FYSP_B1,
              'F02-SP',
              'FYSP_B1',
              _vm.infoList.FYSP_B1_node_string
            )}}},[_vm._v(" "+_vm._s("FYSP_B1" in _vm.infoList ? _vm.infoList.FYSP_B1 : 0)+" ")])])]),_c('div',{staticClass:"two flex"},[_c('div',{staticClass:"num",on:{"click":function($event){return _vm.toCompon(
            2,
            'LTPJZ',
            _vm.infoList.LTPJZ_node_string,
            'LTPJZVV1',
            '炉膛负压均值'
          )},"dblclick":function($event){return _vm.Cclick(
            _vm.infoList.LTPJZ,
            'LTPJZ',
            _vm.infoList.LTPJZ_node_string,
            'LTPJZVV1'
          )}}},[_vm._v(" "+_vm._s("LTPJZ" in _vm.infoList ? _vm.infoList.LTPJZ : 0)+" ")]),_c('div',[_c('div',{staticClass:"buttonx float1",style:({
            background:
              _vm.infoList.XK05 && !_vm.infoList.XK05.RM ? '#2AFC30' : 'red',
          }),on:{"click":function($event){_vm.infoList.XK05
              ? _vm.toDetail(
                  1,
                  'XK05',
                  _vm.infoList.XK05_node_string,
                  '',
                  '负压挡板调节'
                )
              : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"buttonx float2",style:({
            background:
              _vm.infoList.XK06 && !_vm.infoList.XK06.RM ? '#2AFC30' : 'red',
          }),on:{"click":function($event){_vm.infoList.XK06
              ? _vm.toDetail(
                  1,
                  'XK06',
                  _vm.infoList.XK06_node_string,
                  '',
                  '负压变频调节'
                )
              : ''}}},[_vm._v(" X ")])]),_c('div',[_c('div',{staticClass:"button1"},[_c('div',{staticClass:"button1_word",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.QK04SEL,
                '按钮',
                'QK04SEL',
                _vm.infoList.QK04SEL_node_string
              )}}},[_vm._v(" "+_vm._s("QK04SEL" in _vm.infoList ? _vm.infoList.QK04SEL ? "前馈投用" : "前馈切除" : 0)+" ")])]),_c('div',{staticClass:"buttonr float3",style:({
            background:
              _vm.infoList.QK04 && !_vm.infoList.QK04.TS ? '#2AFC30' : 'red',
          }),on:{"click":function($event){_vm.infoList.QK04
              ? _vm.toDetail(
                  2,
                  'QK04',
                  _vm.infoList.QK04_node_string,
                  '',
                  '送风前馈引风'
                )
              : ''}}},[_vm._v(" K ")])]),_c('div',[_c('div',{staticClass:"shadow shadow1 float4 flex"},[_c('div',{staticClass:"column1"},[_vm._v("F01-AV")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                2,
                'AV',
                _vm.infoList.XK05_node_string,
                'XK05_AVVV1',
                'XK05总输出',
                'XK05'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.XK05.AV,
                'AV',
                _vm.infoList.XK05_node_string,
                'XK05_AVVV1'
              )}}},[_vm._v(" "+_vm._s("XK05" in _vm.infoList ? _vm.infoList.XK05.AV : 0)+" ")])]),_c('div',{staticClass:"shadow shadow2"},[_c('div',{staticClass:"float5 flex"},[_c('div',{staticClass:"column1"},[_vm._v("F03-DLTC")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.DLLB,
                  'F03-DLTC',
                  'DLLB',
                  _vm.infoList.DLLB_node_string
                )}}},[_vm._v(" "+_vm._s("DLLB" in _vm.infoList ? _vm.infoList.DLLB : 0)+" ")])]),_c('div',{staticClass:"float5 flex"},[_c('div',{staticClass:"column11"},[_vm._v("F03-DLPV1")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'II_YFJ1_B',
                  _vm.infoList.II_YFJ1_B_node_string,
                  'II_YFJ1_BVV1',
                  '1#引风机电流'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.II_YFJ1_B,
                  'II_YFJ1_B',
                  _vm.infoList.II_YFJ1_B_node_string,
                  'II_YFJ1_BVV1'
                )}}},[_vm._v(" "+_vm._s("II_YFJ1_B" in _vm.infoList ? _vm.infoList.II_YFJ1_B : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"button2"},[_c('div',{staticClass:"button2_word",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.RSF0HSEL,
                    '按钮',
                    'RSF0HSEL',
                    _vm.infoList.RSF0HSEL_node_string
                  )}}},[_vm._v(" "+_vm._s("RSF0HSEL" in _vm.infoList ? _vm.infoList.RSF0HSEL ? "电流纠偏投用" : "电流纠偏切除" : 0)+" ")])]),_c('div',{staticClass:"buttonr float6",style:({
                background:
                  _vm.infoList.RSF0H && !_vm.infoList.RSF0H.SP ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.RSF0H
                  ? _vm.toDetail(
                      3,
                      'RSF0H',
                      _vm.infoList.RSF0H_node_string,
                      '',
                      '引风机电流纠偏'
                    )
                  : ''}}},[_vm._v(" R ")])]),_c('div',{staticClass:"float5 flex"},[_c('div',{staticClass:"column11"},[_vm._v("F03-DLPV2")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'II_YFJ2_B',
                  _vm.infoList.II_YFJ2_B_node_string,
                  'II_YFJ2_BVV1',
                  '2#引风机电流'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.II_YFJ2_B,
                  'II_YFJ2_B',
                  _vm.infoList.II_YFJ2_B_node_string,
                  'II_YFJ2_BVV1'
                )}}},[_vm._v(" "+_vm._s("II_YFJ2_B" in _vm.infoList ? _vm.infoList.II_YFJ2_B : 0)+" ")])])]),_c('div',{staticClass:"shadow shadow1 float7 flex"},[_c('div',{staticClass:"column1"},[_vm._v("F02-AV2")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                2,
                'AV',
                _vm.infoList.XK06_node_string,
                'XK06_AVVV1',
                'XK06总输出',
                'XK06'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.XK06.AV,
                'AV',
                _vm.infoList.XK06_node_string,
                'XK06_AVVV1'
              )}}},[_vm._v(" "+_vm._s("XK06" in _vm.infoList ? _vm.infoList.XK06.AV : 0)+" ")])])]),_vm._m(1),_c('div',{staticClass:"buttonx float8",style:({
          background:
            _vm.infoList.MAN19.RM == 1 ||
            _vm.infoList.MAN20.RM == 1 ||
            _vm.infoList.MAN21.RM == 1 ||
            _vm.infoList.MAN22.RM == 1
              ? '#2AFC30'
              : 'red',
        }),on:{"click":function($event){return _vm.toCompon(0, 'CFB_MANYF', 4)}}},[_vm._v(" A ")])]),_c('div',{staticClass:"three"},[_c('div',{staticClass:"shadow shadow1 float1 flex"},[_c('div',{staticClass:"column1"},[_vm._v("F01-A01")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
              2,
              'YFDBAO1_B',
              _vm.infoList.YFDBAO1_B_node_string,
              'YFDBAO1_BVV1',
              '1#引风挡板调节输出'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.YFDBAO1_B,
              'YFDBAO1_B',
              _vm.infoList.YFDBAO1_B_node_string,
              'YFDBAO1_BVV1'
            )}}},[_vm._v(" "+_vm._s("YFDBAO1_B" in _vm.infoList ? _vm.infoList.YFDBAO1_B : 0)+" ")])]),_c('div',{staticClass:"shadow shadow1 float2 flex"},[_c('div',{staticClass:"column1"},[_vm._v("F01-A02")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
              2,
              'YFDBAO2_B',
              _vm.infoList.YFDBAO2_B_node_string,
              'YFDBAO2_BVV1',
              '2#引风挡板调节输出'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.YFDBAO2_B,
              'YFDBAO2_B',
              _vm.infoList.YFDBAO2_B_node_string,
              'YFDBAO2_BVV1'
            )}}},[_vm._v(" "+_vm._s("YFDBAO2_B" in _vm.infoList ? _vm.infoList.YFDBAO2_B : 0)+" ")])]),_c('div',{staticClass:"shadow shadow1 float3 flex"},[_c('div',{staticClass:"column1"},[_vm._v("F02-A01")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
              2,
              'YFBPAO1_B',
              _vm.infoList.YFBPAO1_B_node_string,
              'YFBPAO1_BVV1',
              '1#引风变频阀位输出'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.YFBPAO1_B,
              'YFBPAO1_B',
              _vm.infoList.YFBPAO1_B_node_string,
              'YFBPAO1_BVV1'
            )}}},[_vm._v(" "+_vm._s("YFBPAO1_B" in _vm.infoList ? _vm.infoList.YFBPAO1_B : 0)+" ")])]),_c('div',{staticClass:"shadow shadow1 float4 flex"},[_c('div',{staticClass:"column1"},[_vm._v("F02-A02")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
              2,
              'YFBPAO2_B',
              _vm.infoList.YFBPAO2_B_node_string,
              'YFBPAO2_BVV1',
              '2#引风变频阀位输出'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.YFBPAO2_B,
              'YFBPAO2_B',
              _vm.infoList.YFBPAO2_B_node_string,
              'YFBPAO2_BVV1'
            )}}},[_vm._v(" "+_vm._s("YFBPAO2_B" in _vm.infoList ? _vm.infoList.YFBPAO2_B : 0)+" ")])])])]),_c('inputVal',{ref:"inputVal"}),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.node,"node1":_vm.node1,"Lkname":_vm.Lkname,"chName":_vm.chName,"infoList":_vm.infoList},on:{"sendStatus":function($event){_vm.isHshow = false}}}):_vm._e(),(_vm.manyManual)?_c('manyManual',{attrs:{"titname":_vm.Manualtitname,"numM":_vm.Manualnode,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"main_title"},[_vm._v("引风优化控制模型")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pz"},[_c('div',{staticClass:"pz1"},[_vm._v("PZ1")]),_c('div',{staticClass:"pz2"},[_vm._v("PZ2")]),_c('div',{staticClass:"pz3"},[_vm._v("PZ3")]),_c('div',{staticClass:"pz4"},[_vm._v("PZ4")])])}]

export { render, staticRenderFns }