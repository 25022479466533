var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"total"},[(_vm.maskbok)?_c('div',{staticClass:"maskbok"}):_vm._e(),_c('div',{staticClass:"title-hang"},[_c('div',{staticClass:"fl-t"},[_c('dv-decoration-1',{staticStyle:{"width":"14vw","height":"3vh","margin-top":"1vh"}})],1),_c('div',{staticClass:"zhuangshizj fl-t"},[_vm._v(" "+_vm._s(_vm.projectData.project)+" ")]),_c('div',{staticClass:"fr-t"},[_c('div',{staticClass:"fl-t"},[_c('dv-decoration-1',{staticStyle:{"width":"14vw","height":"3vh","margin-top":"1vh"}})],1)])]),_c('div',{staticClass:"part1 flex"},[_c('div',{staticClass:"float1"},[_c('div',{staticClass:"words"},[_vm._v("锅炉负荷")]),_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
              2,
              'BYMNL59',
              _vm.infoList.BYMNL59_node_string,
              'BYMNL59VV1',
              '主蒸汽流量'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.BYMNL59,
              'BYMNL59',
              _vm.infoList.BYMNL59_node_string,
              'BYMNL59VV1'
            )}}},[_vm._v(" "+_vm._s("BYMNL59" in _vm.infoList ? _vm.infoList.BYMNL59 : 0)+" t/h ")])]),_c('div',{staticClass:"float2"},[_c('div',{staticClass:"words"},[_vm._v("汽压控制点")]),_c('div',{staticClass:"num2",on:{"click":function($event){return _vm.toIpt(
              _vm.infoList.XTPR,
              '汽压控制点',
              'XTPR',
              _vm.infoList.XTPR_node_string
            )}}},[_vm._v(" "+_vm._s("XTPR" in _vm.infoList ? _vm.infoList.XTPR : 0)+" MPa ")])]),_c('div',{staticClass:"float3"},[_c('div',{staticClass:"words"},[_vm._v("汽温控制点")]),_c('div',{staticClass:"num2",on:{"click":function($event){return _vm.toIpt(
              _vm.infoList.ZQTSP_B1,
              '汽温控制点',
              'ZQTSP_B1',
              _vm.infoList.ZQTSP_B1_node_string
            )}}},[_vm._v(" "+_vm._s("ZQTSP_B1" in _vm.infoList ? _vm.infoList.ZQTSP_B1 : 0)+" ℃ ")])]),_c('div',{staticClass:"float4"},[_c('div',{staticClass:"words"},[_vm._v("水位控制点")]),_c('div',{staticClass:"num2",on:{"click":function($event){return _vm.toIpt(
              _vm.infoList.SWSP_B1,
              '水位控制点',
              'SWSP_B1',
              _vm.infoList.SWSP_B1_node_string
            )}}},[_vm._v(" "+_vm._s("SWSP_B1" in _vm.infoList ? _vm.infoList.SWSP_B1 : 0)+" mmm ")])])]),_c('div',{staticClass:"part2 flex"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"block1 flex"},[_c('div',{staticClass:"float1"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'FT102D_B',
                  _vm.infoList.FT102D_B_node_string,
                  'FT102D_BVV1',
                  '1#主蒸汽流量'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.FT102D_B,
                  'FT102D_B',
                  _vm.infoList.FT102D_B_node_string,
                  'FT102D_BVV1'
                )}}},[_vm._v(" "+_vm._s("FT102D_B" in _vm.infoList ? _vm.infoList.FT102D_B : 0)+" t/h ")])]),_c('div',{staticClass:"float2"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'BYMNL39',
                  _vm.infoList.BYMNL39_node_string,
                  'BYMNL39VV1',
                  '主蒸汽压力平均值'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.BYMNL39,
                  'BYMNL39',
                  _vm.infoList.BYMNL39_node_string,
                  'BYMNL39VV1'
                )}}},[_vm._v(" "+_vm._s("BYMNL39" in _vm.infoList ? _vm.infoList.BYMNL39 : 0)+" MPa ")])]),_c('div',{staticClass:"float3"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'PT103D_B',
                  _vm.infoList.PT103D_B_node_string,
                  'PT103D_BVV1',
                  '一级主蒸汽压力'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.PT103D_B,
                  'PT103D_B',
                  _vm.infoList.PT103D_B_node_string,
                  'PT103D_BVV1'
                )}}},[_vm._v(" "+_vm._s("PT103D_B" in _vm.infoList ? _vm.infoList.PT103D_B : 0)+" MPa ")])]),_c('div',{staticClass:"float4"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'TE165_B',
                  _vm.infoList.TE165_B_node_string,
                  'TE165_BVV1',
                  '二级甲减温器蒸汽出温度'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.TE165_B,
                  'TE165_B',
                  _vm.infoList.TE165_B_node_string,
                  'TE165_BVV1'
                )}}},[_vm._v(" "+_vm._s("TE165_B" in _vm.infoList ? _vm.infoList.TE165_B : 0)+" ℃ ")])]),_c('div',{staticClass:"float5"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'TE166_B',
                  _vm.infoList.TE166_B_node_string,
                  'TE166_BVV1',
                  '二级乙减温器蒸汽出温度'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.TE166_B,
                  'TE166_B',
                  _vm.infoList.TE166_B_node_string,
                  'TE166_BVV1'
                )}}},[_vm._v(" "+_vm._s("TE166_B" in _vm.infoList ? _vm.infoList.TE166_B : 0)+" ℃ ")])])]),_c('div',{staticClass:"block2 flex"},[_c('div',{staticClass:"device",on:{"click":function($event){return _vm.toCompon(5)}}},[_vm._v("煤斗")]),_c('div',{staticClass:"water_level_ball flex"},[_c('div',{staticClass:"water_level"},[_c('div',{staticClass:"top flex"},[_c('div',{staticClass:"words",style:({
                    background:
                      'SWHSEL' in _vm.infoList
                        ? _vm.infoList.SWHSEL == 1
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }),on:{"click":function($event){return _vm.toIpt(
                      '1',
                      '水位1',
                      'SWHSEL',
                      _vm.infoList.SWHSEL_node_string,
                      'true'
                    )}}},[_vm._v(" 水位1 ")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'LT101D_B',
                        _vm.infoList.LT101D_B_node_string,
                        'LT101D_BVV1',
                        '汽包水位1'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.LT101D_B,
                        'LT101D_B',
                        _vm.infoList.LT101D_B_node_string,
                        'LT101D_BVV1'
                      )}}},[_vm._v(" "+_vm._s("LT101D_B" in _vm.infoList ? _vm.infoList.LT101D_B : 0)+" mm ")])])]),_c('div',{staticClass:"top flex"},[_c('div',{staticClass:"words",style:({
                    background:
                      'SWHSEL' in _vm.infoList
                        ? _vm.infoList.SWHSEL == 2
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }),on:{"click":function($event){return _vm.toIpt(
                      '2',
                      '水位2',
                      'SWHSEL',
                      _vm.infoList.SWHSEL_node_string,
                      'true'
                    )}}},[_vm._v(" 水位2 ")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'LT102D_B',
                        _vm.infoList.LT102D_B_node_string,
                        'LT102D_BVV1',
                        '汽包水位2'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.LT102D_B,
                        'LT102D_B',
                        _vm.infoList.LT102D_B_node_string,
                        'LT102D_BVV1'
                      )}}},[_vm._v(" "+_vm._s("LT102D_B" in _vm.infoList ? _vm.infoList.LT102D_B : 0)+" mm ")])])]),_c('div',{staticClass:"top flex"},[_c('div',{staticClass:"words",style:({
                    background:
                      'SWHSEL' in _vm.infoList
                        ? _vm.infoList.SWHSEL == 4
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }),on:{"click":function($event){return _vm.toIpt(
                      '4',
                      '水位3',
                      'SWHSEL',
                      _vm.infoList.SWHSEL_node_string,
                      'true'
                    )}}},[_vm._v(" 水位3 ")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'LT103D_B',
                        _vm.infoList.LT103D_B_node_string,
                        'LT103D_BVV1',
                        '汽包水位3'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.LT103D_B,
                        'LT103D_B',
                        _vm.infoList.LT103D_B_node_string,
                        'LT103D_BVV1'
                      )}}},[_vm._v(" "+_vm._s("LT103D_B" in _vm.infoList ? _vm.infoList.LT103D_B : 0)+" mm ")])])]),_c('div',{staticClass:"top flex"},[_c('div',{staticClass:"words",style:({
                    background:
                      'SWHSEL' in _vm.infoList
                        ? _vm.infoList.SWHSEL == 8
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }),on:{"click":function($event){return _vm.toIpt(
                      '8',
                      '水位4',
                      'SWHSEL',
                      _vm.infoList.SWHSEL_node_string,
                      'true'
                    )}}},[_vm._v(" 水位4 ")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'LT104D_B',
                        _vm.infoList.LT104D_B_node_string,
                        'LT104D_BVV1',
                        '汽包水位4'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.LT104D_B,
                        'LT104D_B',
                        _vm.infoList.LT104D_B_node_string,
                        'LT104D_BVV1'
                      )}}},[_vm._v(" "+_vm._s("LT104D_B" in _vm.infoList ? _vm.infoList.LT104D_B : 0)+" mm ")])])])]),_c('div',{staticClass:"ball",on:{"click":function($event){return _vm.toCompon(11)}}},[_c('dv-water-level-pond',{staticStyle:{"width":"110px","height":"110px"},attrs:{"config":_vm.config},on:{"click":function($event){$event.stopPropagation();return _vm.toCompon(
                    2,
                    'SWSJPV',
                    _vm.infoList.SWSJPV_node_string,
                    'SWSJPVVV1'
                  )}}})],1),_c('div',{staticClass:"location"},[_c('div',{staticClass:"num1",on:{"click":function($event){$event.stopPropagation();return _vm.toCompon(
                    2,
                    'SWSJPV',
                    _vm.infoList.SWSJPV_node_string,
                    'SWSJPVVV1',
                    '汽包水位均值'
                  )},"dblclick":function($event){$event.stopPropagation();return _vm.Cclick(
                    _vm.infoList.SWSJPV,
                    'SWSJPV',
                    _vm.infoList.SWSJPV_node_string,
                    'SWSJPVVV1'
                  )}}},[_vm._v(" "+_vm._s("SWSJPV" in _vm.infoList ? _vm.infoList.SWSJPV : 0)+" mm ")])])]),_c('div',{staticClass:"location1"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){_vm.toCompon(
                    2,
                    'TE136_D_B',
                    _vm.infoList.TE136_D_B_node_string,
                    'TE136_D_BVV1',
                    '二级甲入口蒸汽温度（左)'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.TE136_D_B,
                    'TE136_D_B',
                    _vm.infoList.TE136_D_B_node_string,
                    'TE136_D_BVV1'
                  )}}},[_vm._v(" "+_vm._s("TE136_D_B" in _vm.infoList ? _vm.infoList.TE136_D_B : 0)+" ℃ ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){_vm.toCompon(
                    2,
                    'TE137_D_B',
                    _vm.infoList.TE137_D_B_node_string,
                    'TE137_D_BVV1',
                    '二级乙入口蒸汽温度（右)'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.TE137_D_B,
                    'TE137_D_B',
                    _vm.infoList.TE137_D_B_node_string,
                    'TE137_D_BVV1'
                  )}}},[_vm._v(" "+_vm._s("TE137_D_B" in _vm.infoList ? _vm.infoList.TE137_D_B : 0)+" ℃ ")])])]),_c('div',{staticClass:"location2"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){_vm.toCompon(
                    2,
                    'TE138_D_B',
                    _vm.infoList.TE138_D_B_node_string,
                    'TE138_D_BVV1',
                    '一级甲入口蒸汽温度（左)'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.TE138_D_B,
                    'TE138_D_B',
                    _vm.infoList.TE138_D_B_node_string,
                    'TE138_D_BVV1'
                  )}}},[_vm._v(" "+_vm._s("TE138_D_B" in _vm.infoList ? _vm.infoList.TE138_D_B : 0)+" ℃ ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){_vm.toCompon(
                    2,
                    'TE139_D_B',
                    _vm.infoList.TE139_D_B_node_string,
                    'TE139_D_BVV1',
                    '一级乙入口蒸汽温度（右)'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.TE139_D_B,
                    'TE139_D_B',
                    _vm.infoList.TE139_D_B_node_string,
                    'TE139_D_BVV1'
                  )}}},[_vm._v(" "+_vm._s("TE139_D_B" in _vm.infoList ? _vm.infoList.TE139_D_B : 0)+" ℃ ")])])])]),_c('div',{staticClass:"block3 flex"},[_c('div',{staticClass:"zy",on:{"click":function($event){return _vm.toCompon(10)}}}),_vm._m(0),_c('div',{staticClass:"two"},[_c('div',{staticClass:"location1"},[_c('div',[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'KF_GM6_B',
                        _vm.infoList.KF_GM6_B_node_string,
                        'KF_GM6_BVV1',
                        '6#给煤机反馈'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.KF_GM6_B,
                        'KF_GM6_B',
                        _vm.infoList.KF_GM6_B_node_string,
                        'KF_GM6_BVV1'
                      )}}},[_vm._v(" "+_vm._s("KF_GM6_B" in _vm.infoList ? _vm.infoList.KF_GM6_B : 0)+" % ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'MLZ6',
                        _vm.infoList.MLZ6_node_string,
                        'MLZ6VV1',
                        '6#给煤量选择后的值'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.MLZ6,
                        'MLZ6',
                        _vm.infoList.MLZ6_node_string,
                        'MLZ6VV1'
                      )}}},[_vm._v(" "+_vm._s("MLZ6" in _vm.infoList ? _vm.infoList.MLZ6 : 0)+" t/h ")])])]),_c('div',{staticClass:"float2"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'KF_GM5_B',
                        _vm.infoList.KF_GM5_B_node_string,
                        'KF_GM5_BVV1',
                        '5#给煤机反馈'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.KF_GM5_B,
                        'KF_GM5_B',
                        _vm.infoList.KF_GM5_B_node_string,
                        'KF_GM5_BVV1'
                      )}}},[_vm._v(" "+_vm._s("KF_GM5_B" in _vm.infoList ? _vm.infoList.KF_GM5_B : 0)+" % ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'MLZ5',
                        _vm.infoList.MLZ5_node_string,
                        'MLZ5VV1',
                        '3#给煤量选择后的值'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.MLZ5,
                        'MLZ5',
                        _vm.infoList.MLZ5_node_string,
                        'MLZ5VV1'
                      )}}},[_vm._v(" "+_vm._s("MLZ5" in _vm.infoList ? _vm.infoList.MLZ5 : 0)+" t/h ")])])]),_c('div',{staticClass:"float3"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'KF_GM4_B',
                        _vm.infoList.KF_GM4_B_node_string,
                        'KF_GM4_BVV1',
                        '4#给煤机反馈'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.KF_GM4_B,
                        'KF_GM4_B',
                        _vm.infoList.KF_GM4_B_node_string,
                        'KF_GM4_BVV1'
                      )}}},[_vm._v(" "+_vm._s("KF_GM4_B" in _vm.infoList ? _vm.infoList.KF_GM4_B : 0)+" % ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'MLZ4',
                        _vm.infoList.MLZ4_node_string,
                        'MLZ4VV1',
                        '4#给煤量选择后的值'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.MLZ4,
                        'MLZ4',
                        _vm.infoList.MLZ4_node_string,
                        'MLZ4VV1'
                      )}}},[_vm._v(" "+_vm._s("MLZ4" in _vm.infoList ? _vm.infoList.MLZ4 : 0)+" t/h ")])])]),_c('div',{staticClass:"float4"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'KF_GM3_B',
                        _vm.infoList.KF_GM3_B_node_string,
                        'KF_GM3_BVV1',
                        '3#给煤机反馈'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.KF_GM3_B,
                        'KF_GM3_B',
                        _vm.infoList.KF_GM3_B_node_string,
                        'KF_GM3_BVV1'
                      )}}},[_vm._v(" "+_vm._s("KF_GM3_B" in _vm.infoList ? _vm.infoList.KF_GM3_B : 0)+" % ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'MLZ3',
                        _vm.infoList.MLZ3_node_string,
                        'MLZ3VV1',
                        '3#给煤量选择后的值'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.MLZ3,
                        'MLZ3',
                        _vm.infoList.MLZ3_node_string,
                        'MLZ3VV1'
                      )}}},[_vm._v(" "+_vm._s("MLZ3" in _vm.infoList ? _vm.infoList.MLZ3 : 0)+" t/h ")])])]),_c('div',{staticClass:"float5"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'KF_GM2_B',
                        _vm.infoList.KF_GM2_B_node_string,
                        'KF_GM2_BVV1',
                        '2#给煤机反馈'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.KF_GM2_B,
                        'KF_GM2_B',
                        _vm.infoList.KF_GM2_B_node_string,
                        'KF_GM2_BVV1'
                      )}}},[_vm._v(" "+_vm._s("KF_GM2_B" in _vm.infoList ? _vm.infoList.KF_GM2_B : 0)+" % ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'MLZ2',
                        _vm.infoList.MLZ2_node_string,
                        'MLZ2VV1',
                        '2#给煤量选择后的值'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.MLZ2,
                        'MLZ2',
                        _vm.infoList.MLZ2_node_string,
                        'MLZ2VV1'
                      )}}},[_vm._v(" "+_vm._s("MLZ2" in _vm.infoList ? _vm.infoList.MLZ2 : 0)+" t/h ")])])]),_c('div',{staticClass:"float6"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'KF_GM1_B',
                        _vm.infoList.KF_GM1_B_node_string,
                        'KF_GM1_BVV1',
                        '1#给煤机反馈'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.KF_GM1_B,
                        'KF_GM1_B',
                        _vm.infoList.KF_GM1_B_node_string,
                        'KF_GM1_BVV1'
                      )}}},[_vm._v(" "+_vm._s("KF_GM1_B" in _vm.infoList ? _vm.infoList.KF_GM1_B : 0)+" % ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'MLZ1',
                        _vm.infoList.MLZ1_node_string,
                        'MLZ1VV1',
                        '1#给煤量选择后的值'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.MLZ1,
                        'MLZ1',
                        _vm.infoList.MLZ1_node_string,
                        'MLZ1VV1'
                      )}}},[_vm._v(" "+_vm._s("MLZ1" in _vm.infoList ? _vm.infoList.MLZ1 : 0)+" t/h ")])])])]),_c('div',{staticClass:"location2"},[_c('div',{staticClass:"words"},[_vm._v("实际给煤量")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'ZGML',
                      _vm.infoList.ZGML_node_string,
                      'ZGMLVV1',
                      '总给煤量'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.ZGML,
                      'ZGML',
                      _vm.infoList.ZGML_node_string,
                      'ZGMLVV1'
                    )}}},[_vm._v(" "+_vm._s("ZGML" in _vm.infoList ? _vm.infoList.ZGML : 0)+" t/h ")])]),_c('div',{staticClass:"float1 flex"},[_c('div',{staticClass:"buttonx",style:({
                    background:
                      'XK0O' in _vm.infoList
                        ? _vm.infoList.XK0O && !_vm.infoList.XK0O.RM
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }),on:{"click":function($event){_vm.infoList.XK0O
                      ? _vm.toDetail(
                          1,
                          'XK0O',
                          _vm.infoList.XK0O_node_string,
                          '',
                          '主汽压力广义预测调节先控'
                        )
                      : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"buttonx",style:({
                    background:
                      'XK0P' in _vm.infoList
                        ? _vm.infoList.XK0P && !_vm.infoList.XK0P.RM
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }),on:{"click":function($event){_vm.infoList.XK0P
                      ? _vm.toDetail(
                          1,
                          'XK0P',
                          _vm.infoList.XK0P_node_string,
                          '',
                          '广义预测流量偏差调节先控'
                        )
                      : ''}}},[_vm._v(" X ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"buttonr",style:({
                    background:
                      'QK07' in _vm.infoList
                        ? _vm.infoList.QK07 && !_vm.infoList.QK07.TS
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }),on:{"click":function($event){_vm.infoList.QK07
                      ? _vm.toDetail(
                          2,
                          'QK07',
                          _vm.infoList.QK07_node_string,
                          '',
                          '主汽流量前馈'
                        )
                      : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"buttonx",style:({
                    background:
                      'XK03' in _vm.infoList
                        ? _vm.infoList.XK03 && !_vm.infoList.XK03.RM
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }),on:{"click":function($event){_vm.infoList.XK03
                      ? _vm.toDetail(
                          1,
                          'XK03',
                          _vm.infoList.XK03_node_string,
                          '',
                          '主汽压力调节'
                        )
                      : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"buttonx",style:({
                    background:
                      'XK04' in _vm.infoList
                        ? _vm.infoList.XK04 && _vm.infoList.XK04.RM == 1
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }),on:{"click":function($event){_vm.infoList.XK04
                      ? _vm.toDetail(
                          1,
                          'XK04',
                          _vm.infoList.XK04_node_string,
                          '',
                          '床温调节'
                        )
                      : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"buttonx",style:({
                    background:
                      'XK02' in _vm.infoList
                        ? _vm.infoList.XK02 && _vm.infoList.XK02.RM == 1
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }),on:{"click":function($event){_vm.infoList.XK02
                      ? _vm.toDetail(
                          1,
                          'XK02',
                          _vm.infoList.XK02_node_string,
                          '',
                          '给煤流量调节'
                        )
                      : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"buttonx",style:({
                    background:
                      'MAN1' ||
                      'MAN2' ||
                      'MAN3' ||
                      'MAN4' ||
                      'MAN23' ||
                      'MAN24' in _vm.infoList
                        ? _vm.infoList.MAN1.RM == 1 ||
                          _vm.infoList.MAN2.RM == 1 ||
                          _vm.infoList.MAN3.RM == 1 ||
                          _vm.infoList.MAN4.RM == 1 ||
                          _vm.infoList.MAN23.RM == 1 ||
                          _vm.infoList.MAN24.RM == 1
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }),on:{"click":function($event){return _vm.toCompon(0, 'CFB_MANGM', 6)}}},[_vm._v(" A ")])])])]),_c('div',{staticClass:"three"},[_c('div',{staticClass:"location1"},[_c('div',{staticClass:"float1 flex"},[_c('div',[_c('div',{staticClass:"shadow flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'PT124D_B',
                          _vm.infoList.PT124D_B_node_string,
                          'PT124D_BVV1',
                          '炉膛负压1'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.PT124D_B,
                          'PT124D_B',
                          _vm.infoList.PT124D_B_node_string,
                          'PT124D_BVV1'
                        )}}},[_vm._v(" "+_vm._s("PT124D_B" in _vm.infoList ? _vm.infoList.PT124D_B : 0)+" Pa ")])]),_c('div',{staticClass:"words"},[_vm._v("负压1")])]),_c('div',[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num4",on:{"click":function($event){return _vm.toIpt(
                          _vm.infoList.FYSP_B1,
                          '汽压控制点',
                          'FYSP_B1',
                          _vm.infoList.FYSP_B1_node_string
                        )}}},[_vm._v(" "+_vm._s("FYSP_B1" in _vm.infoList ? _vm.infoList.FYSP_B1 : 0)+" Pa ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num6",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'LTPJZ',
                          _vm.infoList.LTPJZ_node_string,
                          'LTPJZVV1',
                          '炉膛负压均值'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.LTPJZ,
                          'LTPJZ',
                          _vm.infoList.LTPJZ_node_string,
                          'LTPJZVV1'
                        )}}},[_vm._v(" "+_vm._s("LTPJZ" in _vm.infoList ? _vm.infoList.LTPJZ : 0)+" Pa ")])])]),_c('div',[_c('div',{staticClass:"shadow flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'PT125D_B',
                          _vm.infoList.PT125D_B_node_string,
                          'PT125D_BVV1',
                          '炉膛负压2'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.PT125D_B,
                          'PT125D_B',
                          _vm.infoList.PT125D_B_node_string,
                          'PT125D_BVV1'
                        )}}},[_vm._v(" "+_vm._s("PT125D_B" in _vm.infoList ? _vm.infoList.PT125D_B : 0)+" Pa ")])]),_c('div',{staticClass:"words"},[_vm._v("负压2")])])]),_c('div',{staticClass:"flex"},[_c('div',[_c('div',{staticClass:"shadow flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'PT126D_B',
                          _vm.infoList.PT126D_B_node_string,
                          'PT126D_BVV1',
                          '炉膛负压3'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.PT126D_B,
                          'PT126D_B',
                          _vm.infoList.PT126D_B_node_string,
                          'PT126D_BVV1'
                        )}}},[_vm._v(" "+_vm._s("PT126D_B" in _vm.infoList ? _vm.infoList.PT126D_B : 0)+" Pa ")])]),_c('div',{staticClass:"words"},[_vm._v("负压3")])]),_c('div',{staticClass:"float2"},[_c('div',{staticClass:"shadow flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'PT127D_B',
                          _vm.infoList.PT127D_B_node_string,
                          'PT127D_BVV1',
                          '炉膛负压4'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.PT127D_B,
                          'PT127D_B',
                          _vm.infoList.PT127D_B_node_string,
                          'PT127D_BVV1'
                        )}}},[_vm._v(" "+_vm._s("PT127D_B" in _vm.infoList ? _vm.infoList.PT127D_B : 0)+" Pa ")])]),_c('div',{staticClass:"words"},[_vm._v("负压4")])])])]),_c('div',{staticClass:"location2 flex"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TE133D_B',
                        _vm.infoList.TE133D_B_node_string,
                        'TE133D_BVV1',
                        '炉床上部温度'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TE133D_B,
                        'TE133D_B',
                        _vm.infoList.TE133D_B_node_string,
                        'TE133D_BVV1'
                      )}}},[_vm._v(" "+_vm._s("TE133D_B" in _vm.infoList ? _vm.infoList.TE133D_B : 0)+" ℃ ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TE132D_B',
                        _vm.infoList.TE132D_B_node_string,
                        'TE132D_BVV1',
                        '炉床上部温度'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TE132D_B,
                        'TE132D_B',
                        _vm.infoList.TE132D_B_node_string,
                        'TE132D_BVV1'
                      )}}},[_vm._v(" "+_vm._s("TE132D_B" in _vm.infoList ? _vm.infoList.TE132D_B : 0)+" ℃ ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TE129D_B',
                        _vm.infoList.TE129D_B_node_string,
                        'TE129D_BVV1',
                        '炉床上部温度'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TE129D_B,
                        'TE129D_B',
                        _vm.infoList.TE129D_B_node_string,
                        'TE129D_BVV1'
                      )}}},[_vm._v(" "+_vm._s("TE129D_B" in _vm.infoList ? _vm.infoList.TE129D_B : 0)+" ℃ ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TE128D_B',
                        _vm.infoList.TE128D_B_node_string,
                        'TE128D_BVV1',
                        '炉床上部温度'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TE128D_B,
                        'TE128D_B',
                        _vm.infoList.TE128D_B_node_string,
                        'TE128D_BVV1'
                      )}}},[_vm._v(" "+_vm._s("TE128D_B" in _vm.infoList ? _vm.infoList.TE128D_B : 0)+" ℃ ")])])]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TE125D_B',
                        _vm.infoList.TE125D_B_node_string,
                        'TE125D_BVV1',
                        '炉床上部温度'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TE125D_B,
                        'TE125D_B',
                        _vm.infoList.TE125D_B_node_string,
                        'TE125D_BVV1'
                      )}}},[_vm._v(" "+_vm._s("TE125D_B" in _vm.infoList ? _vm.infoList.TE125D_B : 0)+" ℃ ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TE124D_B',
                        _vm.infoList.TE124D_B_node_string,
                        'TE124D_BVV1',
                        '炉床上部温度'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TE124D_B,
                        'TE124D_B',
                        _vm.infoList.TE124D_B_node_string,
                        'TE124D_BVV1'
                      )}}},[_vm._v(" "+_vm._s("TE124D_B" in _vm.infoList ? _vm.infoList.TE124D_B : 0)+" ℃ ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TE123D_B',
                        _vm.infoList.TE123D_B_node_string,
                        'TE123D_BVV1',
                        '炉床上部温度'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TE123D_B,
                        'TE123D_B',
                        _vm.infoList.TE123D_B_node_string,
                        'TE123D_BVV1'
                      )}}},[_vm._v(" "+_vm._s("TE123D_B" in _vm.infoList ? _vm.infoList.TE123D_B : 0)+" ℃ ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TE122D_B',
                        _vm.infoList.TE122D_B_node_string,
                        'TE122D_BVV1',
                        '炉床上部温度'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TE122D_B,
                        'TE122D_B',
                        _vm.infoList.TE122D_B_node_string,
                        'TE122D_BVV1'
                      )}}},[_vm._v(" "+_vm._s("TE122D_B" in _vm.infoList ? _vm.infoList.TE122D_B : 0)+" ℃ ")])])])]),_c('div',{staticClass:"location3"},[_c('div',{staticClass:"float1 flex"},[_c('div',{staticClass:"num3",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'ZJAV1',
                      _vm.infoList.ZJAV1_node_string,
                      'ZJAV1VV1',
                      '床温变化显示'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.ZJAV1,
                      'ZJAV1',
                      _vm.infoList.ZJAV1_node_string,
                      'ZJAV1VV1'
                    )}}},[_vm._v(" "+_vm._s("ZJAV1" in _vm.infoList ? _vm.infoList.ZJAV1 : 0)+" ℃/min ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num4",on:{"click":function($event){return _vm.toIpt(
                        _vm.infoList.CWSP_B1,
                        '汽压控制点',
                        'CWSP_B1',
                        _vm.infoList.CWSP_B1_node_string
                      )}}},[_vm._v(" "+_vm._s("CWSP_B1" in _vm.infoList ? _vm.infoList.CWSP_B1 : 0)+" ℃ ")])]),_c('div',{staticClass:"float6 flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'CWSP_SJ',
                        _vm.infoList.CWSP_SJ_node_string,
                        'CWSP_SJVV1',
                        '床温实际控制点'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.CWSP_SJ,
                        'CWSP_SJ',
                        _vm.infoList.CWSP_SJ_node_string,
                        'CWSP_SJVV1'
                      )}}},[_vm._v(" "+_vm._s("CWSP_SJ" in _vm.infoList ? _vm.infoList.CWSP_SJ : 0)+" ℃ ")])])]),_c('div',{staticClass:"float2 flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'MAXCW',
                      _vm.infoList.MAXCW_node_string,
                      'MAXCWVV1',
                      '床温最大值'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.MAXCW,
                      'MAXCW',
                      _vm.infoList.MAXCW_node_string,
                      'MAXCWVV1'
                    )}}},[_vm._v(" "+_vm._s("MAXCW" in _vm.infoList ? _vm.infoList.MAXCW : 0)+" ℃ ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"float3 flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'CWJZ_Z',
                        _vm.infoList.CWJZ_Z_node_string,
                        'CWJZ_ZVV1',
                        '左侧床温均值'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.CWJZ_Z,
                        'CWJZ_Z',
                        _vm.infoList.CWJZ_Z_node_string,
                        'CWJZ_ZVV1'
                      )}}},[_vm._v(" "+_vm._s("CWJZ_Z" in _vm.infoList ? _vm.infoList.CWJZ_Z : 0)+" ℃ ")])]),_c('div',{staticClass:"float4 flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'CWJZ',
                        _vm.infoList.CWJZ_node_string,
                        'CWJZVV1',
                        '床温均值'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.CWJZ,
                        'CWJZ',
                        _vm.infoList.CWJZ_node_string,
                        'CWJZVV1'
                      )}}},[_vm._v(" "+_vm._s("CWJZ" in _vm.infoList ? _vm.infoList.CWJZ : 0)+" ℃ ")])]),_c('div',{staticClass:"float5 flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'CWJZ_Y',
                        _vm.infoList.CWJZ_Y_node_string,
                        'CWJZ_YVV1',
                        '右侧床温均值'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.CWJZ_Y,
                        'CWJZ_Y',
                        _vm.infoList.CWJZ_Y_node_string,
                        'CWJZ_YVV1'
                      )}}},[_vm._v(" "+_vm._s("CWJZ_Y" in _vm.infoList ? _vm.infoList.CWJZ_Y : 0)+" ℃ ")])])])]),_c('div',{staticClass:"location7 flex"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TE110D_B',
                        _vm.infoList.TE110D_B_node_string,
                        'TE110D_BVV1',
                        '炉床温度5-左侧'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TE110D_B,
                        'TE110D_B',
                        _vm.infoList.TE110D_B_node_string,
                        'TE110D_BVV1'
                      )}}},[_vm._v(" "+_vm._s("TE110D_B" in _vm.infoList ? _vm.infoList.TE110D_B : 0)+" ℃ ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TE111D_B',
                        _vm.infoList.TE111D_B_node_string,
                        'TE111D_BVV1',
                        '炉床温度6-右侧'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TE111D_B,
                        'TE111D_B',
                        _vm.infoList.TE111D_B_node_string,
                        'TE111D_BVV1'
                      )}}},[_vm._v(" "+_vm._s("TE111D_B" in _vm.infoList ? _vm.infoList.TE111D_B : 0)+" ℃ ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TE112D_B',
                        _vm.infoList.TE112D_B_node_string,
                        'TE112D_BVV1',
                        '炉床温度7-左侧'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TE112D_B,
                        'TE112D_B',
                        _vm.infoList.TE112D_B_node_string,
                        'TE112D_BVV1'
                      )}}},[_vm._v(" "+_vm._s("TE112D_B" in _vm.infoList ? _vm.infoList.TE112D_B : 0)+" ℃ ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TE113D_B',
                        _vm.infoList.TE113D_B_node_string,
                        'TE113D_BVV1',
                        '炉床温度8-右侧'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TE113D_B,
                        'TE113D_B',
                        _vm.infoList.TE113D_B_node_string,
                        'TE113D_BVV1'
                      )}}},[_vm._v(" "+_vm._s("TE113D_B" in _vm.infoList ? _vm.infoList.TE113D_B : 0)+" ℃ ")])])]),_c('div',{staticClass:"left"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TE116D_B',
                        _vm.infoList.TE116D_B_node_string,
                        'TE116D_BVV1',
                        '炉床温度11-左侧'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TE116D_B,
                        'TE116D_B',
                        _vm.infoList.TE116D_B_node_string,
                        'TE116D_BVV1'
                      )}}},[_vm._v(" "+_vm._s("TE116D_B" in _vm.infoList ? _vm.infoList.TE116D_B : 0)+" ℃ ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TE117D_B',
                        _vm.infoList.TE117D_B_node_string,
                        'TE117D_BVV1',
                        '炉床温度12-右侧'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TE117D_B,
                        'TE117D_B',
                        _vm.infoList.TE117D_B_node_string,
                        'TE117D_BVV1'
                      )}}},[_vm._v(" "+_vm._s("TE117D_B" in _vm.infoList ? _vm.infoList.TE117D_B : 0)+" ℃ ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TE118D_B',
                        _vm.infoList.TE118D_B_node_string,
                        'TE118D_BVV1',
                        '炉床温度13-左侧'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TE118D_B,
                        'TE118D_B',
                        _vm.infoList.TE118D_B_node_string,
                        'TE118D_BVV1'
                      )}}},[_vm._v(" "+_vm._s("TE118D_B" in _vm.infoList ? _vm.infoList.TE118D_B : 0)+" ℃ ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TE120D_B',
                        _vm.infoList.TE120D_B_node_string,
                        'TE120D_BVV1',
                        '炉床温度15-左侧'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TE120D_B,
                        'TE120D_B',
                        _vm.infoList.TE120D_B_node_string,
                        'TE120D_BVV1'
                      )}}},[_vm._v(" "+_vm._s("TE120D_B" in _vm.infoList ? _vm.infoList.TE120D_B : 0)+" ℃ ")])])]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TE106D_B',
                        _vm.infoList.TE106D_B_node_string,
                        'TE106D_BVV1',
                        '炉床温度1-左侧'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TE106D_B,
                        'TE106D_B',
                        _vm.infoList.TE106D_B_node_string,
                        'TE106D_BVV1'
                      )}}},[_vm._v(" "+_vm._s("TE106D_B" in _vm.infoList ? _vm.infoList.TE106D_B : 0)+" ℃ ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TE107D_B',
                        _vm.infoList.TE107D_B_node_string,
                        'TE107D_BVV1',
                        '炉床温度2-右侧'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TE107D_B,
                        'TE107D_B',
                        _vm.infoList.TE107D_B_node_string,
                        'TE107D_BVV1'
                      )}}},[_vm._v(" "+_vm._s("TE107D_B" in _vm.infoList ? _vm.infoList.TE107D_B : 0)+" ℃ ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TE108D_B',
                        _vm.infoList.TE108D_B_node_string,
                        'TE108D_BVV1',
                        '炉床温度3-左侧'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TE108D_B,
                        'TE108D_B',
                        _vm.infoList.TE108D_B_node_string,
                        'TE108D_BVV1'
                      )}}},[_vm._v(" "+_vm._s("TE108D_B" in _vm.infoList ? _vm.infoList.TE108D_B : 0)+" ℃ ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TE109D_B',
                        _vm.infoList.TE109D_B_node_string,
                        'TE109D_BVV1',
                        '炉床温度4-右侧'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TE109D_B,
                        'TE109D_B',
                        _vm.infoList.TE109D_B_node_string,
                        'TE109D_BVV1'
                      )}}},[_vm._v(" "+_vm._s("TE109D_B" in _vm.infoList ? _vm.infoList.TE109D_B : 0)+" ℃ ")])])]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TE114D_B',
                        _vm.infoList.TE114D_B_node_string,
                        'TE114D_BVV1',
                        '炉床温度9-左侧'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TE114D_B,
                        'TE114D_B',
                        _vm.infoList.TE114D_B_node_string,
                        'TE114D_BVV1'
                      )}}},[_vm._v(" "+_vm._s("TE114D_B" in _vm.infoList ? _vm.infoList.TE114D_B : 0)+" ℃ ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TE115D_B',
                        _vm.infoList.TE115D_B_node_string,
                        'TE115D_BVV1',
                        '炉床温度10-右侧'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TE115D_B,
                        'TE115D_B',
                        _vm.infoList.TE115D_B_node_string,
                        'TE115D_BVV1'
                      )}}},[_vm._v(" "+_vm._s("TE115D_B" in _vm.infoList ? _vm.infoList.TE115D_B : 0)+" ℃ ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TE111D_B',
                        _vm.infoList.TE111D_B_node_string,
                        'TE111D_BVV1',
                        '炉床温度6-右侧'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TE111D_B,
                        'TE111D_B',
                        _vm.infoList.TE111D_B_node_string,
                        'TE111D_BVV1'
                      )}}},[_vm._v(" "+_vm._s("TE111D_B" in _vm.infoList ? _vm.infoList.TE111D_B : 0)+" ℃ ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TE113D_B',
                        _vm.infoList.TE113D_B_node_string,
                        'TE113D_BVV1',
                        '炉床温度8-右侧'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TE113D_B,
                        'TE113D_B',
                        _vm.infoList.TE113D_B_node_string,
                        'TE113D_BVV1'
                      )}}},[_vm._v(" "+_vm._s("TE113D_B" in _vm.infoList ? _vm.infoList.TE113D_B : 0)+" ℃ ")])])])]),_c('div',{staticClass:"location4"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num4",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.LCSP_B1,
                      '汽压控制点',
                      'LCSP_B1',
                      _vm.infoList.LCSP_B1_node_string
                    )}}},[_vm._v(" "+_vm._s("" in _vm.infoList ? _vm.infoList.LCSP_B1 : 0)+" ")]),_c('div',{staticClass:"num5"},[_vm._v("KPa")])])]),_c('div',{staticClass:"location5 flex"},[_c('div',{staticClass:"left flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'DPT101D_B',
                      _vm.infoList.DPT101D_B_node_string,
                      'DPT101D_BVV1',
                      '左侧料层差压'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.DPT101D_B,
                      'DPT101D_B',
                      _vm.infoList.DPT101D_B_node_string,
                      'DPT101D_BVV1'
                    )}}},[_vm._v(" "+_vm._s("DPT101D_B" in _vm.infoList ? _vm.infoList.DPT101D_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("KPa")])]),_c('div',{staticClass:"right flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'DPT102D_B',
                      _vm.infoList.DPT102D_B_node_string,
                      'DPT102D_BVV1',
                      '右侧料层差压'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.DPT102D_B,
                      'DPT102D_B',
                      _vm.infoList.DPT102D_B_node_string,
                      'DPT102D_BVV1'
                    )}}},[_vm._v(" "+_vm._s("DPT102D_B" in _vm.infoList ? _vm.infoList.DPT102D_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("KPa")])])]),_c('div',{staticClass:"location6 flex"},[_c('div',[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TLZQ1_B',
                        _vm.infoList.TLZQ1_B_node_string,
                        'TLZQ1_BVV1',
                        '冷渣器水温'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TLZQ1_B,
                        'TLZQ1_B',
                        _vm.infoList.TLZQ1_B_node_string,
                        'TLZQ1_BVV1'
                      )}}},[_vm._v(" "+_vm._s("TLZQ1_B" in _vm.infoList ? _vm.infoList.TLZQ1_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("℃")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'KF_CZ1_B',
                        _vm.infoList.KF_CZ1_B_node_string,
                        'KF_CZ1_BVV1',
                        '1#除渣机调节反馈'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.KF_CZ1_B,
                        'KF_CZ1_B',
                        _vm.infoList.KF_CZ1_B_node_string,
                        'KF_CZ1_BVV1'
                      )}}},[_vm._v(" "+_vm._s("KF_CZ1_B" in _vm.infoList ? _vm.infoList.KF_CZ1_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("%")])])]),_c('div',{staticClass:"float1"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TLZQ2_B',
                        _vm.infoList.TLZQ2_B_node_string,
                        'TLZQ2_BVV1',
                        '冷渣器水温'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TLZQ2_B,
                        'TLZQ2_B',
                        _vm.infoList.TLZQ2_B_node_string,
                        'TLZQ2_BVV1'
                      )}}},[_vm._v(" "+_vm._s("TLZQ2_B" in _vm.infoList ? _vm.infoList.TLZQ2_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("℃")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'KF_CZ2_B',
                        _vm.infoList.KF_CZ2_B_node_string,
                        'KF_CZ2_BVV1',
                        '2#除渣机调节反馈'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.KF_CZ2_B,
                        'KF_CZ2_B',
                        _vm.infoList.KF_CZ2_B_node_string,
                        'KF_CZ2_BVV1'
                      )}}},[_vm._v(" "+_vm._s("KF_CZ2_B" in _vm.infoList ? _vm.infoList.KF_CZ2_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("%")])])]),_c('div',{staticClass:"float1"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TLZQ3_B',
                        _vm.infoList.TLZQ3_B_node_string,
                        'TLZQ3_BVV1',
                        '右侧回料器1#调节风门'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TLZQ3_B,
                        'TLZQ3_B',
                        _vm.infoList.TLZQ3_B_node_string,
                        'TLZQ3_BVV1'
                      )}}},[_vm._v(" "+_vm._s("TLZQ3_B" in _vm.infoList ? _vm.infoList.TLZQ3_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("℃")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'KF_CZ3_B',
                        _vm.infoList.KF_CZ3_B_node_string,
                        'KF_CZ3_BVV1',
                        '3#除渣反馈'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.KF_CZ3_B,
                        'KF_CZ3_B',
                        _vm.infoList.KF_CZ3_B_node_string,
                        'KF_CZ3_BVV1'
                      )}}},[_vm._v(" "+_vm._s("KF_CZ3_B" in _vm.infoList ? _vm.infoList.KF_CZ3_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("%")])])]),_c('div',{staticClass:"float1"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TLZQ4_B',
                        _vm.infoList.TLZQ4_B_node_string,
                        'TLZQ4_BVV1',
                        '右侧回料器2#调节风门'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TLZQ4_B,
                        'TLZQ4_B',
                        _vm.infoList.TLZQ4_B_node_string,
                        'TLZQ4_BVV1'
                      )}}},[_vm._v(" "+_vm._s("TLZQ4_B" in _vm.infoList ? _vm.infoList.TLZQ4_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("℃")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'KF_CZ4_B',
                        _vm.infoList.KF_CZ4_B_node_string,
                        'KF_CZ4_BVV1',
                        '4#除渣反馈'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.KF_CZ4_B,
                        'KF_CZ4_B',
                        _vm.infoList.KF_CZ4_B_node_string,
                        'KF_CZ4_BVV1'
                      )}}},[_vm._v(" "+_vm._s("KF_CZ4_B" in _vm.infoList ? _vm.infoList.KF_CZ4_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("%")])])])])]),_c('div',{staticClass:"four"},[_c('div',{staticClass:"location1"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"words"},[_vm._v("TC")]),_c('div',{staticClass:"num2",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.ECFPKP_TC,
                      'TC',
                      'ECFPKP_TC',
                      _vm.infoList.ECFPKP_TC_node_string
                    )}}},[_vm._v(" "+_vm._s("ECFPKP_TC" in _vm.infoList ? _vm.infoList.ECFPKP_TC : 0)+" ")])]),_c('div',{staticClass:"words"},[_vm._v("二次风压")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num4",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.BCSBY1SP_B,
                      '二次风压',
                      'BCSBY1SP_B',
                      _vm.infoList.BCSBY1SP_B_node_string
                    )}}},[_vm._v(" "+_vm._s("BCSBY1SP_B" in _vm.infoList ? _vm.infoList.BCSBY1SP_B : 0)+" ")]),_c('div',{staticClass:"num5"},[_vm._v("KPa")])]),_c('div',{staticClass:"buttonr",style:({
                  background:
                    'RSF0G' in _vm.infoList
                      ? !_vm.infoList.RSF0G.TS
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }),on:{"click":function($event){_vm.infoList.RSF0G
                    ? _vm.toDetail(
                        3,
                        'RSF0G',
                        _vm.infoList.RSF0G_node_string,
                        '',
                        '软伺服'
                      )
                    : ''}}},[_vm._v(" R ")]),_c('div',{staticClass:"buttonx float1",style:({
                  background:
                    'MAN31' in _vm.infoList
                      ? _vm.infoList.MAN31.RM == 1 || _vm.infoList.MAN32.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }),on:{"click":function($event){return _vm.toCompon(0, 'CFB_MANECFY', 2)}}},[_vm._v(" A ")])]),_c('div',{staticClass:"location2"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"buttonx",style:({
                    background:
                      'XK0C' in _vm.infoList
                        ? _vm.infoList.XK0C && _vm.infoList.XK0C.RM == 1
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }),on:{"click":function($event){_vm.infoList.XK0C
                      ? _vm.toDetail(
                          1,
                          'XK0C',
                          _vm.infoList.XK0C_node_string,
                          '',
                          '料层调节'
                        )
                      : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"buttonx float1",style:({
                    background:
                      'MAN13' || 'MAN14' || 'MAN28' || 'MAN29' in _vm.infoList
                        ? _vm.infoList.MAN13.RM == 1 ||
                          _vm.infoList.MAN14.RM == 1 ||
                          _vm.infoList.MAN28.RM == 1 ||
                          _vm.infoList.MAN29.RM == 1
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }),on:{"click":function($event){return _vm.toCompon(0, 'CFB_MANCZ', 4)}}},[_vm._v(" A ")])]),_c('div',{staticClass:"words",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toCompon(4)}}},[_vm._v(" 冷渣机 ")])])])])]),_c('div',{staticClass:"middle"},[_c('div',{staticClass:"location1"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){_vm.toCompon(
                  2,
                  'TE140_D_B',
                  _vm.infoList.TE140_D_B_node_string,
                  'TE140_D_BVV1',
                  '高过再热入口蒸汽温度（左)'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.TE140_D_B,
                  'TE140_D_B',
                  _vm.infoList.TE140_D_B_node_string,
                  'TE140_D_BVV1'
                )}}},[_vm._v(" "+_vm._s("TE140_D_B" in _vm.infoList ? _vm.infoList.TE140_D_B : 0)+" ℃ ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){_vm.toCompon(
                  2,
                  'TE141_D_B',
                  _vm.infoList.TE141_D_B_node_string,
                  'TE141_D_BVV1',
                  '高过再热入口蒸汽温度（右)'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.TE141_D_B,
                  'TE141_D_B',
                  _vm.infoList.TE141_D_B_node_string,
                  'TE141_D_BVV1'
                )}}},[_vm._v(" "+_vm._s("TE141_D_B" in _vm.infoList ? _vm.infoList.TE141_D_B : 0)+" ℃ ")])])]),_c('div',{staticClass:"location2"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"buttonr",style:({
                background:
                  'QK05' in _vm.infoList
                    ? _vm.infoList.QK05 && !_vm.infoList.QK05.TS
                      ? '#2AFC30'
                      : 'red'
                    : 'red',
              }),on:{"click":function($event){_vm.infoList.QK05
                  ? _vm.toDetail(
                      2,
                      'QK05',
                      _vm.infoList.QK05_node_string,
                      '',
                      '二级左再热前馈'
                    )
                  : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"buttonx right",style:({
                background:
                  'XK0A' in _vm.infoList
                    ? _vm.infoList.XK0A && _vm.infoList.XK0A.RM == 1
                      ? '#2AFC30'
                      : 'red'
                    : 'red',
              }),on:{"click":function($event){_vm.infoList.XK0A
                  ? _vm.toDetail(
                      1,
                      'XK0A',
                      _vm.infoList.XK0A_node_string,
                      '',
                      '右1烟气挡板调节'
                    )
                  : ''}}},[_vm._v(" X ")])]),_c('div',{staticClass:"flex up"},[_c('div',{staticClass:"buttonr",style:({
                background:
                  'QK06' in _vm.infoList
                    ? _vm.infoList.QK06 && !_vm.infoList.QK06.TS
                      ? '#2AFC30'
                      : 'red'
                    : 'red',
              }),on:{"click":function($event){_vm.infoList.QK06
                  ? _vm.toDetail(
                      2,
                      'QK06',
                      _vm.infoList.QK06_node_string,
                      '',
                      '二级右再热前馈'
                    )
                  : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"buttonx right",style:({
                background:
                  'XK0B' in _vm.infoList
                    ? _vm.infoList.XK0B && _vm.infoList.XK0B.RM == 1
                      ? '#2AFC30'
                      : 'red'
                    : 'red',
              }),on:{"click":function($event){_vm.infoList.XK0B
                  ? _vm.toDetail(
                      1,
                      'XK0B',
                      _vm.infoList.XK0B_node_string,
                      '',
                      '右2烟气挡板调节'
                    )
                  : ''}}},[_vm._v(" X ")])]),_c('div',{staticClass:"flex up"},[_c('div',{staticClass:"buttonr",style:({
                background:
                  'QK0A' in _vm.infoList
                    ? _vm.infoList.QK0A && !_vm.infoList.QK0A.TS
                      ? '#2AFC30'
                      : 'red'
                    : 'red',
              }),on:{"click":function($event){_vm.infoList.QK0A
                  ? _vm.toDetail(
                      2,
                      'QK0A',
                      _vm.infoList.QK0A_node_string,
                      '',
                      'QK0A设定值'
                    )
                  : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"buttonx right",style:({
                background:
                  'XK0K' in _vm.infoList
                    ? _vm.infoList.XK0K && _vm.infoList.XK0K.RM == 1
                      ? '#2AFC30'
                      : 'red'
                    : 'red',
              }),on:{"click":function($event){_vm.infoList.XK0K
                  ? _vm.toDetail(
                      1,
                      'XK0K',
                      _vm.infoList.XK0K_node_string,
                      '',
                      '左1烟气挡板调节'
                    )
                  : ''}}},[_vm._v(" X ")])]),_c('div',{staticClass:"flex up"},[_c('div',{staticClass:"buttonr",style:({
                background:
                  'QK0B' in _vm.infoList
                    ? _vm.infoList.QK0B && !_vm.infoList.QK0B.TS
                      ? '#2AFC30'
                      : 'red'
                    : 'red',
              }),on:{"click":function($event){_vm.infoList.QK0B
                  ? _vm.toDetail(
                      2,
                      'QK0B',
                      _vm.infoList.QK0B_node_string,
                      '',
                      'QK0B设定值'
                    )
                  : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"buttonx right",style:({
                background:
                  'XK0L' in _vm.infoList
                    ? _vm.infoList.XK0L && _vm.infoList.XK0L.RM == 1
                      ? '#2AFC30'
                      : 'red'
                    : 'red',
              }),on:{"click":function($event){_vm.infoList.XK0L
                  ? _vm.toDetail(
                      1,
                      'XK0L',
                      _vm.infoList.XK0L_node_string,
                      '',
                      '左1烟气挡板调节'
                    )
                  : ''}}},[_vm._v(" X ")])])]),_c('div',{staticClass:"location3 flex"},[_c('div',[_c('div',{staticClass:"words",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toCompon(3)}}},[_vm._v(" 再热汽温 ")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num4",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.ZQTSP_B2,
                    '再热汽温',
                    'ZQTSP_B2',
                    _vm.infoList.ZQTSP_B2_node_string
                  )}}},[_vm._v(" "+_vm._s("ZQTSP_B2" in _vm.infoList ? _vm.infoList.ZQTSP_B2 : 0)+" ℃ ")])])]),_c('div',{staticClass:"buttonx float1",style:({
              background:
                'MAN33' || 'MAN34' || 'MAN35' || 'MAN36' in _vm.infoList
                  ? _vm.infoList.MAN33.RM == 1 ||
                    _vm.infoList.MAN34.RM == 1 ||
                    _vm.infoList.MAN35.RM == 1 ||
                    _vm.infoList.MAN36.RM == 1
                    ? '#2AFC30'
                    : 'red'
                  : 'red',
            }),on:{"click":function($event){return _vm.toCompon(0, 'CFB_MANQWZ', 4)}}},[_vm._v(" A ")])]),_c('div',{staticClass:"location4"},[_c('div',{staticClass:"float1"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){_vm.toCompon(
                    2,
                    'TE142_D_B',
                    _vm.infoList.TE142_D_B_node_string,
                    'TE142_D_BVV1',
                    '低过再热入口蒸汽温度（左)'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.TE142_D_B,
                    'TE142_D_B',
                    _vm.infoList.TE142_D_B_node_string,
                    'TE142_D_BVV1'
                  )}}},[_vm._v(" "+_vm._s("TE142_D_B" in _vm.infoList ? _vm.infoList.TE142_D_B : 0)+" ℃ ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num6",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.C_O2,
                    '低温过热器',
                    'C_O2',
                    _vm.infoList.C_O2_node_string
                  )}}},[_vm._v(" "+_vm._s("C_O2" in _vm.infoList ? _vm.infoList.C_O2 : 0)+" % ")])])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'AT101AD_B',
                    _vm.infoList.AT101AD_B_node_string,
                    'AT101AD_BVV1',
                    '烟气含氧量'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.AT101AD_B,
                    'AT101AD_B',
                    _vm.infoList.AT101AD_B_node_string,
                    'AT101AD_BVV1'
                  )}}},[_vm._v(" "+_vm._s("AT101AD_B" in _vm.infoList ? _vm.infoList.AT101AD_B : 0)+" % ")])]),_c('div',{staticClass:"float2 flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'AT101BD_B',
                    _vm.infoList.AT101BD_B_node_string,
                    'AT101BD_BVV1',
                    '烟气含氧量'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.AT101BD_B,
                    'AT101BD_B',
                    _vm.infoList.AT101BD_B_node_string,
                    'AT101BD_BVV1'
                  )}}},[_vm._v(" "+_vm._s("AT101BD_B" in _vm.infoList ? _vm.infoList.AT101BD_B : 0)+" % ")])])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"button1"},[_c('div',{staticClass:"word1",style:({
                  background:
                    'SEL_O2' in _vm.infoList
                      ? _vm.infoList.SEL_O2 == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }),on:{"click":function($event){return _vm.toIpt(
                    '1',
                    '氧量左',
                    'SEL_O2',
                    _vm.infoList.SEL_O2_node_string,
                    'true'
                  )}}},[_vm._v(" 氧量左 ")])]),_c('div',{staticClass:"button1 float3"},[_c('div',{staticClass:"word1",style:({
                  background:
                    'SEL_O2' in _vm.infoList
                      ? _vm.infoList.SEL_O2 == 2
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }),on:{"click":function($event){return _vm.toIpt(
                    '2',
                    '氧量右',
                    'SEL_O2',
                    _vm.infoList.SEL_O2_node_string,
                    'true'
                  )}}},[_vm._v(" 氧量右 ")])])]),_c('div',{staticClass:"float4 flex"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'TE154_D_B',
                    _vm.infoList.TE154_D_B_node_string,
                    'TE154_D_BVV1',
                    '省煤器后烟气温度'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.TE154_D_B,
                    'TE154_D_B',
                    _vm.infoList.TE154_D_B_node_string,
                    'TE154_D_BVV1'
                  )}}},[_vm._v(" "+_vm._s("TE154_D_B" in _vm.infoList ? _vm.infoList.TE154_D_B : 0)+" ℃ ")])]),_c('div',{staticClass:"float2 flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'PT148_D_B',
                    _vm.infoList.PT148_D_B_node_string,
                    'PT148_D_BVV1',
                    '省煤器后烟气负压'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.PT148_D_B,
                    'PT148_D_B',
                    _vm.infoList.PT148_D_B_node_string,
                    'PT148_D_BVV1'
                  )}}},[_vm._v(" "+_vm._s("PT148_D_B" in _vm.infoList ? _vm.infoList.PT148_D_B : 0)+" ℃ ")])])])]),_c('div',{staticClass:"location5"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"words"},[_vm._v("目标值")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'FGFSP_B2',
                    _vm.infoList.FGFSP_B2_node_string,
                    'FGFSP_B2VV1',
                    '风量目标值'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.FGFSP_B2,
                    'FGFSP_B2',
                    _vm.infoList.FGFSP_B2_node_string,
                    'FGFSP_B2VV1'
                  )}}},[_vm._v(" "+_vm._s("FGFSP_B2" in _vm.infoList ? _vm.infoList.FGFSP_B2 : 0)+" m3/h ")])])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"words"},[_vm._v("测量值")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'ECFL',
                    _vm.infoList.ECFL_node_string,
                    'ECFLVV1',
                    '二次风量'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.ECFL,
                    'ECFL',
                    _vm.infoList.ECFL_node_string,
                    'ECFLVV1'
                  )}}},[_vm._v(" "+_vm._s("ECFL" in _vm.infoList ? _vm.infoList.ECFL : 0)+" m3/h ")])])])]),_c('div',{staticClass:"location6"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"words"},[_vm._v("目标值")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'FGFSP_B1',
                    _vm.infoList.FGFSP_B1_node_string,
                    'FGFSP_B1VV1',
                    '风量目标值'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.FGFSP_B1,
                    'FGFSP_B1',
                    _vm.infoList.FGFSP_B1_node_string,
                    'FGFSP_B1VV1'
                  )}}},[_vm._v(" "+_vm._s("FGFSP_B1" in _vm.infoList ? _vm.infoList.FGFSP_B1 : 0)+" m3/h ")])])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"words"},[_vm._v("测量值")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'JSFL',
                    _vm.infoList.JSFL_node_string,
                    'JSFLVV1',
                    '一次风量'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.JSFL,
                    'JSFL',
                    _vm.infoList.JSFL_node_string,
                    'JSFLVV1'
                  )}}},[_vm._v(" "+_vm._s("JSFL" in _vm.infoList ? _vm.infoList.JSFL : 0)+" m3/h ")])])])]),_c('div',{staticClass:"location7 flex"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'TE158_D_B',
                  _vm.infoList.TE158_D_B_node_string,
                  'TE158_D_BVV1',
                  '左侧排烟温度'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.TE158_D_B,
                  'TE158_D_B',
                  _vm.infoList.TE158_D_B_node_string,
                  'TE158_D_BVV1'
                )}}},[_vm._v(" "+_vm._s("TE158_D_B" in _vm.infoList ? _vm.infoList.TE158_D_B : 0)+" ℃ ")])]),_c('div',{staticClass:"float1 flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'TE159_D_B',
                  _vm.infoList.TE159_D_B_node_string,
                  'TE159_D_BVV1',
                  '右侧排烟温度'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.TE159_D_B,
                  'TE159_D_B',
                  _vm.infoList.TE159_D_B_node_string,
                  'TE159_D_BVV1'
                )}}},[_vm._v(" "+_vm._s("TE159_D_B" in _vm.infoList ? _vm.infoList.TE159_D_B : 0)+" ℃ ")])])]),_c('div',{staticClass:"location8 flex"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num4",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'PT152_D_B',
                  _vm.infoList.PT152_D_B_node_string,
                  'PT152_D_BVV1',
                  '引风机入口压力'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.PT152_D_B,
                  'PT152_D_B',
                  _vm.infoList.PT152_D_B_node_string,
                  'PT152_D_BVV1'
                )}}},[_vm._v(" "+_vm._s("PT152_D_B" in _vm.infoList ? _vm.infoList.PT152_D_B : 0)+" KPa ")])])])]),_c('div',{staticClass:"rightt"},[_c('div',{staticClass:"one flex"},[_c('div',{staticClass:"tap"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'TE165_B',
                      _vm.infoList.TE165_B_node_string,
                      'TE165_BVV1',
                      '二级甲减温器蒸汽出温度'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.TE165_B,
                      'TE165_B',
                      _vm.infoList.TE165_B_node_string,
                      'TE165_BVV1'
                    )}}},[_vm._v(" "+_vm._s("TE165_B" in _vm.infoList ? _vm.infoList.TE165_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("℃")])]),_c('div',{staticClass:"valve float1"}),_c('div',{staticClass:"float2 flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'KF_QW3_B',
                      _vm.infoList.KF_QW3_B_node_string,
                      'KF_QW3_BVV1',
                      '3#减温调节阀反馈'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.KF_QW3_B,
                      'KF_QW3_B',
                      _vm.infoList.KF_QW3_B_node_string,
                      'KF_QW3_BVV1'
                    )}}},[_vm._v(" "+_vm._s("KF_QW3_B" in _vm.infoList ? _vm.infoList.KF_QW3_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("%")])])]),_c('div',{staticClass:"line1 flex"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'TE166_B',
                      _vm.infoList.TE166_B_node_string,
                      'TE166_BVV1',
                      '二级乙减温器蒸汽出温度'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.TE166_B,
                      'TE166_B',
                      _vm.infoList.TE166_B_node_string,
                      'TE166_BVV1'
                    )}}},[_vm._v(" "+_vm._s("TE166_B" in _vm.infoList ? _vm.infoList.TE166_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("℃")])]),_c('div',{staticClass:"valve float1"}),_c('div',{staticClass:"float2 flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'KF_QW4_B',
                      _vm.infoList.KF_QW4_B_node_string,
                      'KF_QW4_BVV1',
                      '4#减温调节阀反馈'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.KF_QW4_B,
                      'KF_QW4_B',
                      _vm.infoList.KF_QW4_B_node_string,
                      'KF_QW4_BVV1'
                    )}}},[_vm._v(" "+_vm._s("KF_QW4_B" in _vm.infoList ? _vm.infoList.KF_QW4_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("%")])])]),_c('div',{staticClass:"line2 flex"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'TE163_B',
                      _vm.infoList.TE163_B_node_string,
                      'TE163_BVV1',
                      '一级甲减温器蒸汽出温度'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.TE163_B,
                      'TE163_B',
                      _vm.infoList.TE163_B_node_string,
                      'TE163_BVV1'
                    )}}},[_vm._v(" "+_vm._s("TE163_B" in _vm.infoList ? _vm.infoList.TE163_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("℃")])]),_c('div',{staticClass:"valve float3"}),_c('div',{staticClass:"float2 flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'KF_QW1_B',
                      _vm.infoList.KF_QW1_B_node_string,
                      'KF_QW1_BVV1',
                      '1#减温调节阀反馈'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.KF_QW1_B,
                      'KF_QW1_B',
                      _vm.infoList.KF_QW1_B_node_string,
                      'KF_QW1_BVV1'
                    )}}},[_vm._v(" "+_vm._s("KF_QW1_B" in _vm.infoList ? _vm.infoList.KF_QW1_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("%")])])]),_c('div',{staticClass:"line3 flex"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'TE164_B',
                      _vm.infoList.TE164_B_node_string,
                      'TE164_BVV1',
                      '一级乙减温器蒸汽出温度'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.TE164_B,
                      'TE164_B',
                      _vm.infoList.TE164_B_node_string,
                      'TE164_BVV1'
                    )}}},[_vm._v(" "+_vm._s("TE164_B" in _vm.infoList ? _vm.infoList.TE164_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("℃")])]),_c('div',{staticClass:"valve float4"}),_c('div',{staticClass:"float2 flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'KF_QW2_B',
                      _vm.infoList.KF_QW2_B_node_string,
                      'KF_QW2_BVV1',
                      '2#减温调节阀反馈'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.KF_QW2_B,
                      'KF_QW2_B',
                      _vm.infoList.KF_QW2_B_node_string,
                      'KF_QW2_BVV1'
                    )}}},[_vm._v(" "+_vm._s("KF_QW2_B" in _vm.infoList ? _vm.infoList.KF_QW2_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("%")])])]),_c('div',{staticClass:"line4 flex"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'KF_SW1_B',
                      _vm.infoList.KF_SW1_B_node_string,
                      'KF_SW1_BVV1',
                      '汽包水位A调节阀反馈'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.KF_SW1_B,
                      'KF_SW1_B',
                      _vm.infoList.KF_SW1_B_node_string,
                      'KF_SW1_BVV1'
                    )}}},[_vm._v(" "+_vm._s("KF_SW1_B" in _vm.infoList ? _vm.infoList.KF_SW1_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("%")])]),_c('div',{staticClass:"float3 valve"})]),_c('div',{staticClass:"line5 flex"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'KF_SW2_B',
                      _vm.infoList.KF_SW2_B_node_string,
                      'KF_SW2_BVV1',
                      '汽包水位B调节阀反馈'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.KF_SW2_B,
                      'KF_SW2_B',
                      _vm.infoList.KF_SW2_B_node_string,
                      'KF_SW2_BVV1'
                    )}}},[_vm._v(" "+_vm._s("KF_SW2_B" in _vm.infoList ? _vm.infoList.KF_SW2_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("%")])]),_c('div',{staticClass:"float5 valve"})])]),_c('div',{staticClass:"switch"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"buttonr",style:({
                  background:
                    'QK01' in _vm.infoList
                      ? _vm.infoList.QK01 && !_vm.infoList.QK01.TS
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }),on:{"click":function($event){_vm.infoList.QK01
                    ? _vm.toDetail(
                        2,
                        'QK01',
                        _vm.infoList.QK01_node_string,
                        '',
                        '电负荷前馈'
                      )
                    : ''}}},[_vm._v(" K ")])]),_c('div',{staticClass:"top flex"},[_c('div',{staticClass:"buttonr",style:({
                  background:
                    'QK02' in _vm.infoList
                      ? _vm.infoList.QK02 && !_vm.infoList.QK02.TS
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }),on:{"click":function($event){_vm.infoList.QK02
                    ? _vm.toDetail(
                        2,
                        'QK02',
                        _vm.infoList.QK02_node_string,
                        '',
                        'QK02设定值'
                      )
                    : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"buttonx right",style:({
                  background:
                    'XK07' in _vm.infoList
                      ? _vm.infoList.XK07 && _vm.infoList.XK07.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }),on:{"click":function($event){_vm.infoList.XK07
                    ? _vm.toDetail(
                        1,
                        'XK07',
                        _vm.infoList.XK07_node_string,
                        '',
                        '二级左主汽温度调节'
                      )
                    : ''}}},[_vm._v(" X ")])]),_c('div',{staticClass:"top flex"},[_c('div',{staticClass:"buttonr",style:({
                  background:
                    'QK03' in _vm.infoList
                      ? _vm.infoList.QK03 && !_vm.infoList.QK03.TS
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }),on:{"click":function($event){_vm.infoList.QK03
                    ? _vm.toDetail(
                        2,
                        'QK03',
                        _vm.infoList.QK03_node_string,
                        '',
                        'QK03设定值'
                      )
                    : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"buttonx right",style:({
                  background:
                    'XK08' in _vm.infoList
                      ? _vm.infoList.XK08 && _vm.infoList.XK08.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }),on:{"click":function($event){_vm.infoList.XK08
                    ? _vm.toDetail(
                        1,
                        'XK08',
                        _vm.infoList.XK08_node_string,
                        '',
                        '二级右主汽温度调节'
                      )
                    : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"words",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toCompon(6)}}},[_vm._v(" 减温水 ")])]),_c('div',{staticClass:"top flex"},[_c('div',{staticClass:"buttonr",style:({
                  background:
                    'QK08' in _vm.infoList
                      ? _vm.infoList.QK08 && !_vm.infoList.QK08.TS
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }),on:{"click":function($event){_vm.infoList.QK08
                    ? _vm.toDetail(
                        2,
                        'QK08',
                        _vm.infoList.QK08_node_string,
                        '',
                        'QK08设定值'
                      )
                    : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"buttonx right",style:({
                  background:
                    'XK0I' in _vm.infoList
                      ? _vm.infoList.XK0I && !_vm.infoList.XK0I.RM
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }),on:{"click":function($event){_vm.infoList.XK0I
                    ? _vm.toDetail(
                        1,
                        'XK0I',
                        _vm.infoList.XK0I_node_string,
                        '',
                        '一级左主汽温度调节'
                      )
                    : ''}}},[_vm._v(" X ")])]),_c('div',{staticClass:"top flex"},[_c('div',{staticClass:"buttonr",style:({
                  background:
                    'QK09' in _vm.infoList
                      ? _vm.infoList.QK09 && !_vm.infoList.QK09.TS
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }),on:{"click":function($event){_vm.infoList.QK09
                    ? _vm.toDetail(
                        2,
                        'QK09',
                        _vm.infoList.QK09_node_string,
                        '',
                        'QK09设定值'
                      )
                    : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"buttonx right",style:({
                  background:
                    'XK0J' in _vm.infoList
                      ? _vm.infoList.XK0J && !_vm.infoList.XK0J.RM
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }),on:{"click":function($event){_vm.infoList.XK0J
                    ? _vm.toDetail(
                        1,
                        'XK0J',
                        _vm.infoList.XK0J_node_string,
                        '',
                        '一级右主汽温度调节'
                      )
                    : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"buttonr right",style:({
                  background:
                    'MAN7' || 'MAN8' || 'MAN9' || 'MAN10' in _vm.infoList
                      ? _vm.infoList.MAN7.RM == 1 ||
                        _vm.infoList.MAN8.RM == 1 ||
                        _vm.infoList.MAN9.RM == 1 ||
                        _vm.infoList.MAN10.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }),on:{"click":function($event){return _vm.toCompon(0, 'CFB_MANQW', 4)}}},[_vm._v(" A ")])]),_c('div',{staticClass:"float1 flex"},[_c('div',{staticClass:"valve"}),_c('div',{staticClass:"float2"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TE101D_B',
                        _vm.infoList.TE101D_B_node_string,
                        'TE101D_BVV1',
                        '给水温度'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TE101D_B,
                        'TE101D_B',
                        _vm.infoList.TE101D_B_node_string,
                        'TE101D_BVV1'
                      )}}},[_vm._v(" "+_vm._s("TE101D_B" in _vm.infoList ? _vm.infoList.TE101D_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("℃")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'PT101D_B',
                        _vm.infoList.PT101D_B_node_string,
                        'PT101D_BVV1',
                        '给水压力'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.PT101D_B,
                        'PT101D_B',
                        _vm.infoList.PT101D_B_node_string,
                        'PT101D_BVV1'
                      )}}},[_vm._v(" "+_vm._s("PT101D_B" in _vm.infoList ? _vm.infoList.PT101D_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("MPa")])])])]),_c('div',{staticClass:"float3 flex"},[_c('div',{staticClass:"buttonx",style:({
                  background:
                    'XK01' in _vm.infoList
                      ? _vm.infoList.XK01 && _vm.infoList.XK01.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }),on:{"click":function($event){_vm.infoList.XK01
                    ? _vm.toDetail(
                        1,
                        'XK01',
                        _vm.infoList.XK01_node_string,
                        '',
                        '汽包液位调节'
                      )
                    : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"buttonr right",style:({
                  background:
                    'XK0M' in _vm.infoList
                      ? _vm.infoList.XK0M && _vm.infoList.XK0M.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }),on:{"click":function($event){_vm.infoList.XK0M
                    ? _vm.toDetail(
                        1,
                        'XK0M',
                        _vm.infoList.XK0M_node_string,
                        '',
                        '主给水阀门调节先控'
                      )
                    : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"buttonr right",style:({
                  background:
                    'XK0N' in _vm.infoList
                      ? _vm.infoList.XK0N && _vm.infoList.XK0N.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }),on:{"click":function($event){_vm.infoList.XK0N
                    ? _vm.toDetail(
                        1,
                        'XK0N',
                        _vm.infoList.XK0N_node_string,
                        '',
                        '副给水阀门调节先控'
                      )
                    : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"buttonr right",style:({
                  background:
                    'MAN6' in _vm.infoList
                      ? _vm.infoList.MAN5.RM == 1 || _vm.infoList.MAN6.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }),on:{"click":function($event){return _vm.toCompon(0, 'CFB_MANGS', 2)}}},[_vm._v(" A ")]),_c('div',{staticClass:"float4 flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'FT101D_B',
                      _vm.infoList.FT101D_B_node_string,
                      'FT101D_BVV1',
                      '给水流量'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.FT101D_B,
                      'FT101D_B',
                      _vm.infoList.FT101D_B_node_string,
                      'FT101D_BVV1'
                    )}}},[_vm._v(" "+_vm._s("FT101D_B" in _vm.infoList ? _vm.infoList.FT101D_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("t/h")])])])])]),_c('div',{staticClass:"two flex"},[_c('div',[_c('div',{staticClass:"up"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"float1 flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'KF_ECFBP_B',
                        _vm.infoList.KF_ECFBP_B_node_string,
                        'KF_ECFBP_BVV1',
                        '二次风机变频反馈'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.KF_ECFBP_B,
                        'KF_ECFBP_B',
                        _vm.infoList.KF_ECFBP_B_node_string,
                        'KF_ECFBP_BVV1'
                      )}}},[_vm._v(" "+_vm._s("KF_ECFBP_B" in _vm.infoList ? _vm.infoList.KF_ECFBP_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("m3/h")])]),_c('div',{staticClass:"float2"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'ECFBPAO_B',
                          _vm.infoList.ECFBPAO_B_node_string,
                          'ECFBPAO_BVV1',
                          '1#二次风机变频阀位输出'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.ECFBPAO_B,
                          'ECFBPAO_B',
                          _vm.infoList.ECFBPAO_B_node_string,
                          'ECFBPAO_BVV1'
                        )}}},[_vm._v(" "+_vm._s("ECFBPAO_B" in _vm.infoList ? _vm.infoList.ECFBPAO_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("%")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'KF_ECFDB_B',
                          _vm.infoList.KF_ECFDB_B_node_string,
                          'KF_ECFDB_BVV1',
                          '二次风机挡板反馈'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.KF_ECFDB_B,
                          'KF_ECFDB_B',
                          _vm.infoList.KF_ECFDB_B_node_string,
                          'KF_ECFDB_BVV1'
                        )}}},[_vm._v(" "+_vm._s("KF_ECFDB_B" in _vm.infoList ? _vm.infoList.KF_ECFDB_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("%")])])]),_c('div',{staticClass:"fan float3"}),_c('div',{staticClass:"float4 flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'II_ECFJ_B',
                        _vm.infoList.II_ECFJ_B_node_string,
                        'II_ECFJ_BVV1',
                        '1#二次风机电流'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.II_ECFJ_B,
                        'II_ECFJ_B',
                        _vm.infoList.II_ECFJ_B_node_string,
                        'II_ECFJ_BVV1'
                      )}}},[_vm._v(" "+_vm._s("II_ECFJ_B" in _vm.infoList ? _vm.infoList.II_ECFJ_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("A")])]),_c('div',[_c('div',{staticClass:"valve float5"}),_c('div',{staticClass:"float6"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                            2,
                            'ECFDBAO_B',
                            _vm.infoList.ECFDBAO_B_node_string,
                            'ECFDBAO_BVV1',
                            '1#二次风机挡板阀位输出'
                          )},"dblclick":function($event){return _vm.Cclick(
                            _vm.infoList.ECFDBAO_B,
                            'ECFDBAO_B',
                            _vm.infoList.ECFDBAO_B_node_string,
                            'ECFDBAO_BVV1'
                          )}}},[_vm._v(" "+_vm._s("ECFDBAO_B" in _vm.infoList ? _vm.infoList.ECFDBAO_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("%")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                            2,
                            'ECFDBAO_B',
                            _vm.infoList.ECFDBAO_B_node_string,
                            'ECFDBAO_BVV1',
                            '1#二次风机挡板阀位输出'
                          )},"dblclick":function($event){return _vm.Cclick(
                            _vm.infoList.ECFDBAO_B,
                            'ECFDBAO_B',
                            _vm.infoList.ECFDBAO_B_node_string,
                            'ECFDBAO_BVV1'
                          )}}},[_vm._v(" "+_vm._s("ECFDBAO_B" in _vm.infoList ? _vm.infoList.ECFDBAO_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("%")])])])])])]),_c('div',{staticClass:"down"},[_c('div',{staticClass:"flex"},[_vm._m(1),_c('div',{staticClass:"float2"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'KF_ECFBP2_B',
                          _vm.infoList.KF_ECFBP2_B_node_string,
                          'KF_ECFBP2_BVV1',
                          '2#二次风变频反馈'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.KF_ECFBP2_B,
                          'KF_ECFBP2_B',
                          _vm.infoList.KF_ECFBP2_B_node_string,
                          'KF_ECFBP2_BVV1'
                        )}}},[_vm._v(" "+_vm._s("KF_ECFBP2_B" in _vm.infoList ? _vm.infoList.KF_ECFBP2_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("%")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'ECFBPAO2_B',
                          _vm.infoList.ECFBPAO2_B_node_string,
                          'ECFBPAO2_BVV1',
                          '2#二次风机变频阀位输出'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.ECFBPAO2_B,
                          'ECFBPAO2_B',
                          _vm.infoList.ECFBPAO2_B_node_string,
                          'ECFBPAO2_BVV1'
                        )}}},[_vm._v(" "+_vm._s("ECFBPAO2_B" in _vm.infoList ? _vm.infoList.ECFBPAO2_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("%")])])]),_c('div',{staticClass:"fan float3"}),_c('div',{staticClass:"float4 flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'II_ECFJ2_B',
                        _vm.infoList.II_ECFJ2_B_node_string,
                        'II_ECFJ2_BVV1',
                        '2#二次风机电流'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.II_ECFJ2_B,
                        'II_ECFJ2_B',
                        _vm.infoList.II_ECFJ2_B_node_string,
                        'II_ECFJ2_BVV1'
                      )}}},[_vm._v(" "+_vm._s("II_ECFJ2_B" in _vm.infoList ? _vm.infoList.II_ECFJ2_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("A")])]),_c('div',[_c('div',{staticClass:"valve float5"}),_c('div',{staticClass:"float6"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                            2,
                            'KF_ECFDB2_B',
                            _vm.infoList.KF_ECFDB2_B_node_string,
                            'KF_ECFDB2_BVV1',
                            '2#二次风挡板反馈'
                          )},"dblclick":function($event){return _vm.Cclick(
                            _vm.infoList.KF_ECFDB2_B,
                            'KF_ECFDB2_B',
                            _vm.infoList.KF_ECFDB2_B_node_string,
                            'KF_ECFDB2_BVV1'
                          )}}},[_vm._v(" "+_vm._s("KF_ECFDB2_B" in _vm.infoList ? _vm.infoList.KF_ECFDB2_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("%")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                            2,
                            'ECFDBAO2_B',
                            _vm.infoList.ECFDBAO2_B_node_string,
                            'ECFDBAO2_BVV1',
                            '2#二次风机挡板阀位输出'
                          )},"dblclick":function($event){return _vm.Cclick(
                            _vm.infoList.ECFDBAO2_B,
                            'ECFDBAO2_B',
                            _vm.infoList.ECFDBAO2_B_node_string,
                            'ECFDBAO2_BVV1'
                          )}}},[_vm._v(" "+_vm._s("ECFDBAO2_B" in _vm.infoList ? _vm.infoList.ECFDBAO2_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("%")])])])])])])]),_c('div',{staticClass:"buttons"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"button1"},[_c('div',{staticClass:"word1",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.O2_ECF_QY,
                      '按钮',
                      'O2_ECF_QY',
                      _vm.infoList.O2_ECF_QY_node_string
                    )}}},[_vm._v(" "+_vm._s("O2_ECF_QY" in _vm.infoList ? _vm.infoList.O2_ECF_QY ? "氧量投用" : "氧量切除" : "氧量切除")+" ")])]),_c('div',{staticClass:"buttonx right",style:({
                  background:
                    'XK0Q' in _vm.infoList
                      ? _vm.infoList.XK0Q && _vm.infoList.XK0Q.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }),on:{"click":function($event){_vm.infoList.XK0Q
                    ? _vm.toDetail(
                        1,
                        'XK0Q',
                        _vm.infoList.XK0Q_node_string,
                        '',
                        '烟气含氧量调节先控'
                      )
                    : ''}}},[_vm._v(" X ")])]),_c('div',{staticClass:"line1 flex"},[_c('div',{staticClass:"button2"},[_c('div',{staticClass:"word2",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.ECF_CWSEL,
                      '按钮',
                      'ECF_CWSEL',
                      _vm.infoList.ECF_CWSEL_node_string
                    )}}},[_vm._v(" "+_vm._s("ECF_CWSEL" in _vm.infoList ? _vm.infoList.ECF_CWSEL ? "床温投用" : "床温切除" : 0)+" ")])]),_c('div',{staticClass:"buttonr right",style:({
                  background:
                    'RSF0E' in _vm.infoList
                      ? !_vm.infoList.RSF0E.TS
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }),on:{"click":function($event){_vm.infoList.RSF0E
                    ? _vm.toDetail(
                        3,
                        'RSF0E',
                        _vm.infoList.RSF0E_node_string,
                        '',
                        '床温二次风软伺服'
                      )
                    : ''}}},[_vm._v(" R ")])]),_c('div',{staticClass:"line1 flex"},[_c('div',{staticClass:"buttonr",style:({
                  background:
                    'RSF07' in _vm.infoList
                      ? !_vm.infoList.RSF07.TS
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }),on:{"click":function($event){_vm.infoList.RSF07
                    ? _vm.toDetail(
                        3,
                        'RSF07',
                        _vm.infoList.RSF07_node_string,
                        '',
                        '二次风电流纠偏'
                      )
                    : ''}}},[_vm._v(" R ")]),_c('div',{staticClass:"buttonx right",style:({
                  background:
                    'XK0D' in _vm.infoList
                      ? _vm.infoList.XK0D && !_vm.infoList.XK0D.RM
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }),on:{"click":function($event){_vm.infoList.XK0D
                    ? _vm.toDetail(
                        1,
                        'XK0D',
                        _vm.infoList.XK0D_node_string,
                        '',
                        '二次风挡板调节'
                      )
                    : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"buttonx right",style:({
                  background:
                    'MAN17' || 'MAN18' || 'MAN26' || 'MAN30' in _vm.infoList
                      ? _vm.infoList.MAN17.RM == 1 ||
                        _vm.infoList.MAN18.RM == 1 ||
                        _vm.infoList.MAN26.RM == 1 ||
                        _vm.infoList.MAN30.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }),on:{"click":function($event){return _vm.toCompon(0, 'CFB_MANECF', 4)}}},[_vm._v(" A ")]),_c('div',{staticClass:"buttonx right",style:({
                  background:
                    'XK0E' in _vm.infoList
                      ? _vm.infoList.XK0E && _vm.infoList.XK0E.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }),on:{"click":function($event){_vm.infoList.XK0E
                    ? _vm.toDetail(
                        1,
                        'XK0E',
                        _vm.infoList.XK0E_node_string,
                        '',
                        '二次风变频调节'
                      )
                    : ''}}},[_vm._v(" X ")])]),_c('div',{staticClass:"words",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toCompon(7)}}},[_vm._v(" 二次风机 ")])])]),_c('div',{staticClass:"three flex"},[_c('div',[_c('div',{staticClass:"up"},[_c('div',{staticClass:"flex"},[_vm._m(2),_c('div',{staticClass:"float2"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'KF_YCFBP_B',
                          _vm.infoList.KF_YCFBP_B_node_string,
                          'KF_YCFBP_BVV1',
                          '一次风机变频反馈'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.KF_YCFBP_B,
                          'KF_YCFBP_B',
                          _vm.infoList.KF_YCFBP_B_node_string,
                          'KF_YCFBP_BVV1'
                        )}}},[_vm._v(" "+_vm._s("KF_YCFBP_B" in _vm.infoList ? _vm.infoList.KF_YCFBP_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("%")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'YCFBPAO_B',
                          _vm.infoList.YCFBPAO_B_node_string,
                          'YCFBPAO_BVV1',
                          '1#一次风机变频阀位输出'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.YCFBPAO_B,
                          'YCFBPAO_B',
                          _vm.infoList.YCFBPAO_B_node_string,
                          'YCFBPAO_BVV1'
                        )}}},[_vm._v(" "+_vm._s("YCFBPAO_B" in _vm.infoList ? _vm.infoList.YCFBPAO_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("%")])])]),_c('div',{staticClass:"fan float3"}),_c('div',{staticClass:"float4 flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'II_YCFJ_B',
                        _vm.infoList.II_YCFJ_B_node_string,
                        'II_YCFJ_BVV1',
                        '1#一次风机电流'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.II_YCFJ_B,
                        'II_YCFJ_B',
                        _vm.infoList.II_YCFJ_B_node_string,
                        'II_YCFJ_BVV1'
                      )}}},[_vm._v(" "+_vm._s("II_YCFJ_B" in _vm.infoList ? _vm.infoList.II_YCFJ_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("A")])]),_c('div',[_c('div',{staticClass:"valve float5"}),_c('div',{staticClass:"float6"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                            2,
                            'KF_YCFDB_B',
                            _vm.infoList.KF_YCFDB_B_node_string,
                            'KF_YCFDB_BVV1',
                            '一次风机挡板反馈'
                          )},"dblclick":function($event){return _vm.Cclick(
                            _vm.infoList.KF_YCFDB_B,
                            'KF_YCFDB_B',
                            _vm.infoList.KF_YCFDB_B_node_string,
                            'KF_YCFDB_BVV1'
                          )}}},[_vm._v(" "+_vm._s("KF_YCFDB_B" in _vm.infoList ? _vm.infoList.KF_YCFDB_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("%")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                            2,
                            'YCFDBAO_B',
                            _vm.infoList.YCFDBAO_B_node_string,
                            'YCFDBAO_BVV1',
                            '1#一次风机挡板阀位输出'
                          )},"dblclick":function($event){return _vm.Cclick(
                            _vm.infoList.YCFDBAO_B,
                            'YCFDBAO_B',
                            _vm.infoList.YCFDBAO_B_node_string,
                            'YCFDBAO_BVV1'
                          )}}},[_vm._v(" "+_vm._s("YCFDBAO_B" in _vm.infoList ? _vm.infoList.YCFDBAO_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("%")])])])])])]),_c('div',{staticClass:"down"},[_c('div',{staticClass:"flex"},[_vm._m(3),_c('div',{staticClass:"float2"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'KF_YCFBP2_B',
                          _vm.infoList.KF_YCFBP2_B_node_string,
                          'KF_YCFBP2_BVV1',
                          '2#一次风变频反馈'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.KF_YCFBP2_B,
                          'KF_YCFBP2_B',
                          _vm.infoList.KF_YCFBP2_B_node_string,
                          'KF_YCFBP2_BVV1'
                        )}}},[_vm._v(" "+_vm._s("KF_YCFBP2_B" in _vm.infoList ? _vm.infoList.KF_YCFBP2_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("%")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'YCFBPAO2_B',
                          _vm.infoList.YCFBPAO2_B_node_string,
                          'YCFBPAO2_BVV1',
                          '2#一次风机变频阀位输出'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.YCFBPAO2_B,
                          'YCFBPAO2_B',
                          _vm.infoList.YCFBPAO2_B_node_string,
                          'YCFBPAO2_BVV1'
                        )}}},[_vm._v(" "+_vm._s("YCFBPAO2_B" in _vm.infoList ? _vm.infoList.YCFBPAO2_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("%")])])]),_c('div',{staticClass:"fan float3"}),_c('div',{staticClass:"float4 flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'II_YCFJ2_B',
                        _vm.infoList.II_YCFJ2_B_node_string,
                        'II_YCFJ2_BVV1',
                        '2#一次风机电流'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.II_YCFJ2_B,
                        'II_YCFJ2_B',
                        _vm.infoList.II_YCFJ2_B_node_string,
                        'II_YCFJ2_BVV1'
                      )}}},[_vm._v(" "+_vm._s("II_YCFJ2_B" in _vm.infoList ? _vm.infoList.II_YCFJ2_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("A")])]),_c('div',[_c('div',{staticClass:"valve float5"}),_c('div',{staticClass:"float6"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                            2,
                            'KF_YCFDB2_B',
                            _vm.infoList.KF_YCFDB2_B_node_string,
                            'KF_YCFDB2_BVV1',
                            '2#一次风挡板反馈'
                          )},"dblclick":function($event){return _vm.Cclick(
                            _vm.infoList.KF_YCFDB2_B,
                            'KF_YCFDB2_B',
                            _vm.infoList.KF_YCFDB2_B_node_string,
                            'KF_YCFDB2_BVV1'
                          )}}},[_vm._v(" "+_vm._s("KF_YCFDB2_B" in _vm.infoList ? _vm.infoList.KF_YCFDB2_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("%")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                            2,
                            'YCFDBAO2_B',
                            _vm.infoList.YCFDBAO2_B_node_string,
                            'YCFDBAO2_BVV1',
                            '2#一次风机挡板阀位输出'
                          )},"dblclick":function($event){return _vm.Cclick(
                            _vm.infoList.YCFDBAO2_B,
                            'YCFDBAO2_B',
                            _vm.infoList.YCFDBAO2_B_node_string,
                            'YCFDBAO2_BVV1'
                          )}}},[_vm._v(" "+_vm._s("YCFDBAO2_B" in _vm.infoList ? _vm.infoList.YCFDBAO2_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("%")])])])])])])]),_c('div',{staticClass:"buttons"},[_c('div',{staticClass:"buttonr float1",style:({
                background:
                  'RSF08' in _vm.infoList
                    ? !_vm.infoList.RSF08.TS
                      ? '#2AFC30'
                      : 'red'
                    : 'red',
              }),on:{"click":function($event){_vm.infoList.RSF08
                  ? _vm.toDetail(
                      3,
                      'RSF08',
                      _vm.infoList.RSF08_node_string,
                      '',
                      '一次风机电流纠偏'
                    )
                  : ''}}},[_vm._v(" R ")]),_c('div',{staticClass:"line1 flex"},[_c('div',{staticClass:"buttonr",style:({
                  background:
                    'RSF04' in _vm.infoList
                      ? !_vm.infoList.RSF04.TS
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }),on:{"click":function($event){_vm.infoList.RSF04
                    ? _vm.toDetail(
                        3,
                        'RSF04',
                        _vm.infoList.RSF04_node_string,
                        '',
                        '一次风变频软伺服'
                      )
                    : ''}}},[_vm._v(" R ")]),_c('div',{staticClass:"buttonx right",style:({
                  background:
                    'MAN15' || 'MAN16' || 'MAN25' || 'MAN27' in _vm.infoList
                      ? _vm.infoList.MAN15.RM == 1 ||
                        _vm.infoList.MAN16.RM == 1 ||
                        _vm.infoList.MAN25.RM == 1 ||
                        _vm.infoList.MAN27.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }),on:{"click":function($event){return _vm.toCompon(0, 'CFB_MANYCF', 4)}}},[_vm._v(" A ")]),_c('div',{staticClass:"buttonr right",style:({
                  background:
                    'RSF05' in _vm.infoList
                      ? !_vm.infoList.RSF05.TS
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }),on:{"click":function($event){_vm.infoList.RSF05
                    ? _vm.toDetail(
                        3,
                        'RSF05',
                        _vm.infoList.RSF05_node_string,
                        '',
                        '一次风挡板软伺服'
                      )
                    : ''}}},[_vm._v(" R ")])]),_c('div',{staticClass:"words float2",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toCompon(8)}}},[_vm._v(" 一次风机 ")])])]),_c('div',{staticClass:"four flex"},[_c('div',[_c('div',{staticClass:"up flex"},[_c('div',[_c('div',{staticClass:"valve float1"}),_c('div',{staticClass:"float2"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'KF_YFDB1_B',
                          _vm.infoList.KF_YFDB1_B_node_string,
                          'KF_YFDB1_BVV1',
                          '1#引风机挡板反馈'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.KF_YFDB1_B,
                          'KF_YFDB1_B',
                          _vm.infoList.KF_YFDB1_B_node_string,
                          'KF_YFDB1_BVV1'
                        )}}},[_vm._v(" "+_vm._s("KF_YFDB1_B" in _vm.infoList ? _vm.infoList.KF_YFDB1_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("%")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'YFDBAO1_B',
                          _vm.infoList.YFDBAO1_B_node_string,
                          'YFDBAO1_BVV1',
                          '1#引风挡板调节输出'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.YFDBAO1_B,
                          'YFDBAO1_B',
                          _vm.infoList.YFDBAO1_B_node_string,
                          'YFDBAO1_BVV1'
                        )}}},[_vm._v(" "+_vm._s("YFDBAO1_B" in _vm.infoList ? _vm.infoList.YFDBAO1_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("%")])])])]),_c('div',{staticClass:"float3"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'KF_YFBP1_B',
                        _vm.infoList.KF_YFBP1_B_node_string,
                        'KF_YFBP1_BVV1',
                        '1#引风机变频反馈'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.KF_YFBP1_B,
                        'KF_YFBP1_B',
                        _vm.infoList.KF_YFBP1_B_node_string,
                        'KF_YFBP1_BVV1'
                      )}}},[_vm._v(" "+_vm._s("KF_YFBP1_B" in _vm.infoList ? _vm.infoList.KF_YFBP1_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("%")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'YFBPAO1_B',
                        _vm.infoList.YFBPAO1_B_node_string,
                        'YFBPAO1_BVV1',
                        '1#引风变频阀位输出'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.YFBPAO1_B,
                        'YFBPAO1_B',
                        _vm.infoList.YFBPAO1_B_node_string,
                        'YFBPAO1_BVV1'
                      )}}},[_vm._v(" "+_vm._s("YFBPAO1_B" in _vm.infoList ? _vm.infoList.YFBPAO1_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("%")])])]),_c('div',{staticClass:"fan float4"}),_c('div',{staticClass:"float5 flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'II_YFJ1_B',
                      _vm.infoList.II_YFJ1_B_node_string,
                      'II_YFJ1_BVV1',
                      '1#引风机电流'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.II_YFJ1_B,
                      'II_YFJ1_B',
                      _vm.infoList.II_YFJ1_B_node_string,
                      'II_YFJ1_BVV1'
                    )}}},[_vm._v(" "+_vm._s("II_YFJ1_B" in _vm.infoList ? _vm.infoList.II_YFJ1_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("A")])])]),_c('div',{staticClass:"down flex"},[_c('div',[_c('div',{staticClass:"valve float1"}),_c('div',{staticClass:"float2"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'KF_YFDB2_B',
                          _vm.infoList.KF_YFDB2_B_node_string,
                          'KF_YFDB2_BVV1',
                          '2#引风机挡板反馈'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.KF_YFDB2_B,
                          'KF_YFDB2_B',
                          _vm.infoList.KF_YFDB2_B_node_string,
                          'KF_YFDB2_BVV1'
                        )}}},[_vm._v(" "+_vm._s("KF_YFDB2_B" in _vm.infoList ? _vm.infoList.KF_YFDB2_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("%")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'YFDBAO2_B',
                          _vm.infoList.YFDBAO2_B_node_string,
                          'YFDBAO2_BVV1',
                          '2#引风挡板调节输出'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.YFDBAO2_B,
                          'YFDBAO2_B',
                          _vm.infoList.YFDBAO2_B_node_string,
                          'YFDBAO2_BVV1'
                        )}}},[_vm._v(" "+_vm._s("YFDBAO2_B" in _vm.infoList ? _vm.infoList.YFDBAO2_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("%")])])])]),_c('div',{staticClass:"float3"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'KF_YFBP2_B',
                        _vm.infoList.KF_YFBP2_B_node_string,
                        'KF_YFBP2_BVV1',
                        '2#引风机变频反馈'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.KF_YFBP2_B,
                        'KF_YFBP2_B',
                        _vm.infoList.KF_YFBP2_B_node_string,
                        'KF_YFBP2_BVV1'
                      )}}},[_vm._v(" "+_vm._s("KF_YFBP2_B" in _vm.infoList ? _vm.infoList.KF_YFBP2_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("%")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'YFBPAO2_B',
                        _vm.infoList.YFBPAO2_B_node_string,
                        'YFBPAO2_BVV1',
                        '2#引风变频阀位输出'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.YFBPAO2_B,
                        'YFBPAO2_B',
                        _vm.infoList.YFBPAO2_B_node_string,
                        'YFBPAO2_BVV1'
                      )}}},[_vm._v(" "+_vm._s("YFBPAO2_B" in _vm.infoList ? _vm.infoList.YFBPAO2_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("%")])])]),_c('div',{staticClass:"fan float4"}),_c('div',{staticClass:"float5 flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'II_YFJ2_B',
                      _vm.infoList.II_YFJ2_B_node_string,
                      'II_YFJ2_BVV1',
                      '2#引风机电流'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.II_YFJ2_B,
                      'II_YFJ2_B',
                      _vm.infoList.II_YFJ2_B_node_string,
                      'II_YFJ2_BVV1'
                    )}}},[_vm._v(" "+_vm._s("II_YFJ2_B" in _vm.infoList ? _vm.infoList.II_YFJ2_B : 0)+" ")]),_c('div',{staticClass:"num3"},[_vm._v("A")])])])]),_c('div',{staticClass:"buttons"},[_c('div',{staticClass:"float1 flex"},[_c('div',{staticClass:"buttonr",style:({
                  background:
                    'QK04' in _vm.infoList
                      ? _vm.infoList.QK04 && !_vm.infoList.QK04.TS
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }),on:{"click":function($event){_vm.infoList.QK04
                    ? _vm.toDetail(
                        2,
                        'QK04',
                        _vm.infoList.QK04_node_string,
                        '',
                        '送风前馈引风'
                      )
                    : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"buttonr right",style:({
                  background:
                    'RSF0H' in _vm.infoList
                      ? _vm.infoList.RSF0H && !_vm.infoList.RSF0H.TS
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }),on:{"click":function($event){_vm.infoList.RSF0H
                    ? _vm.toDetail(
                        3,
                        'RSF0H',
                        _vm.infoList.RSF0H_node_string,
                        '',
                        '引风机电流纠偏'
                      )
                    : ''}}},[_vm._v(" R ")])]),_c('div',{staticClass:"line1 flex"},[_c('div',{staticClass:"buttonx",style:({
                  background:
                    'XK05' in _vm.infoList
                      ? _vm.infoList.XK05.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }),on:{"click":function($event){_vm.infoList.XK05
                    ? _vm.toDetail(
                        1,
                        'XK05',
                        _vm.infoList.XK05_node_string,
                        '',
                        '负压挡板调节'
                      )
                    : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"buttonx right",style:({
                  background:
                    'MAN19' || 'MAN20' || 'MAN21' || 'MAN22' in _vm.infoList
                      ? _vm.infoList.MAN19.RM == 1 ||
                        _vm.infoList.MAN20.RM == 1 ||
                        _vm.infoList.MAN21.RM == 1 ||
                        _vm.infoList.MAN22.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }),on:{"click":function($event){return _vm.toCompon(0, 'CFB_MANYF', 4)}}},[_vm._v(" A ")]),_c('div',{staticClass:"buttonx right",style:({
                  background:
                    'XK06' in _vm.infoList
                      ? _vm.infoList.XK06 && _vm.infoList.XK06.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }),on:{"click":function($event){_vm.infoList.XK06
                    ? _vm.toDetail(
                        1,
                        'XK06',
                        _vm.infoList.XK06_node_string,
                        '',
                        '负压变频调节'
                      )
                    : ''}}},[_vm._v(" X ")])]),_c('div',{staticClass:"words float2",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toCompon(9)}}},[_vm._v(" 引风机 ")])])])])]),_c('inputVal',{ref:"inputVal"}),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.nodename,"Lkname":_vm.Aname,"chName":_vm.chName,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.Parameter04)?_c('Parameter04',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.manyManual)?_c('manyManual',{attrs:{"titname":_vm.Manualtitname,"numM":_vm.Manualnode,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.Parameter022)?_c('Parameter022',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.Parameter08)?_c('Parameter08',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.Parameter02)?_c('Parameter02',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.Parameter07)?_c('Parameter07',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.Parameter06)?_c('Parameter06',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.Parameter05)?_c('Parameter05',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.Parameter03)?_c('Parameter03',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.Parameter01)?_c('Parameter01',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.flag == 5 ? (_vm.isComShow = true) : (_vm.isComShow = false))?_c('Cfbcysz',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.flag == 7 ? (_vm.isComShow = true) : (_vm.isComShow = false))?_c('Cfbserve',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"one flex"},[_c('div',{staticClass:"valve"}),_c('div',{staticClass:"valve float1"}),_c('div',{staticClass:"valve float2"}),_c('div',{staticClass:"valve float3"}),_c('div',{staticClass:"valve float4"}),_c('div',{staticClass:"valve float5"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"float1 flex"},[_c('div',{staticClass:"num1"},[_vm._v("0.0")]),_c('div',{staticClass:"num3"},[_vm._v("m3/h")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"float1 flex"},[_c('div',{staticClass:"num1"},[_vm._v("0")]),_c('div',{staticClass:"num3"},[_vm._v("m3/h")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"float1 flex"},[_c('div',{staticClass:"num1"},[_vm._v("0")]),_c('div',{staticClass:"num3"},[_vm._v("m3/h")])])}]

export { render, staticRenderFns }