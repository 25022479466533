var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"draw",rawName:"v-draw"}],staticClass:"total drag",attrs:{"id":"CfbParameter8"}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"title-hang flex"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.projectData.project)+"_参数08_1")]),_c('div',{staticClass:"icon",on:{"click":_vm.closeCompon}})])]),_vm._m(0),_c('div',{staticClass:"context flex"},[_c('div',{staticClass:"one"},[_c('div',{staticClass:"shadow shadow1 flex"},[_c('div',{staticClass:"column1"},[_vm._v("I01-SP")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(_vm.infoList.LCSP_B1, 'I01-SP', 'LCSP_B1', _vm.infoList.LCSP_B1_node_string)}}},[_vm._v(" "+_vm._s('LCSP_B1' in _vm.infoList? _vm.infoList.LCSP_B1 :0)+" ")])]),_c('div',{staticClass:"shadow shadow2"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("I02-TC")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(_vm.infoList.TC002, 'I02-TC', 'TC002', _vm.infoList.TC002_node_string)}}},[_vm._v(" "+_vm._s('TC002' in _vm.infoList? _vm.infoList.TC002 :0)+" ")])]),_vm._m(1),_c('div',{staticClass:"float1 flex"},[_vm._m(2),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'DPT101D_B',
                  _vm.infoList.DPT101D_B_node_string,
                  'DPT101D_BVV1',
                  '左侧料层差压'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.DPT101D_B,
                  'DPT101D_B',
                  _vm.infoList.DPT101D_B_node_string,
                  'DPT101D_BVV1'
                )}}},[_vm._v(" "+_vm._s('DPT101D_B' in _vm.infoList? _vm.infoList.DPT101D_B :0)+" ")])]),_c('div',{staticClass:"flex"},[_vm._m(3),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'DPT102D_B',
                  _vm.infoList.DPT102D_B_node_string,
                  'DPT102D_BVV1',
                  '右侧料层差压'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.DPT102D_B,
                  'DPT102D_B',
                  _vm.infoList.DPT102D_B_node_string,
                  'DPT102D_BVV1'
                )}}},[_vm._v(" "+_vm._s('DPT102D_B' in _vm.infoList? _vm.infoList.DPT102D_B :0)+" ")])])])]),_c('div',{staticClass:"two flex"},[_c('div',{staticClass:"shadow shadow1 float1"},[_c('div',{staticClass:"shadow_words"},[_vm._v("料层预测模型")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("I02-PV")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(2, 'SJLCCY', _vm.infoList.SJLCCY_node_string, 'SJLCCYVV1')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.SJLCCY, 'SJLCCY', _vm.infoList.SJLCCY_node_string, 'SJLCCYVV1')}}},[_vm._v(" "+_vm._s('SJLCCY' in _vm.infoList? _vm.infoList.SJLCCY :0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("I02-CYZQ")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(_vm.infoList.BYMNL74, 'I02-CYZQ', 'BYMNL74', _vm.infoList.BYMNL74_node_string)}}},[_vm._v(" "+_vm._s('BYMNL74' in _vm.infoList? _vm.infoList.BYMNL74 :0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("I02-LBSJ")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(_vm.infoList.BYMNL75, 'I02-LBSJ', 'BYMNL75', _vm.infoList.BYMNL75_node_string)}}},[_vm._v(" "+_vm._s('BYMNL75' in _vm.infoList? _vm.infoList.BYMNL75 :0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("I02-YCSJ")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(_vm.infoList.BYMNL76, 'I02-YCSJ', 'BYMNL76', _vm.infoList.BYMNL76_node_string)}}},[_vm._v(" "+_vm._s('BYMNL76' in _vm.infoList? _vm.infoList.BYMNL76 :0)+" ")])])]),_c('div',{staticClass:"shadow shadow2 float2 flex"},[_c('div',{staticClass:"column1"},[_vm._v("I01-PV1")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(2, 'PV', _vm.infoList.XK0C_node_string, 'XK0C_PVVV1', 'XK0C测量值','XK0C')},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.XK0C.PV,
                'PV',
                _vm.infoList.XK0C_node_string,
                'XK0C_PVVV1'
              )}}},[_vm._v(" "+_vm._s('XK0C' in _vm.infoList? _vm.infoList.XK0C.PV :0)+" ")])]),_c('div',{staticClass:"buttonx float3",style:({
            background:
              _vm.infoList.XK0C && !_vm.infoList.XK0C.RM
                ? '#2AFC30'
                : 'red',
          }),on:{"click":function($event){_vm.infoList.XK0C
              ? _vm.toDetail(1, 'XK0C', _vm.infoList.XK0C_node_string, '', '料层调节')
              : ''}}},[_vm._v(" X ")]),_c('div',[_c('div',{staticClass:"shadow shadow2 float4 flex"},[_c('div',{staticClass:"column1"},[_vm._v("I01-AV")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(2, 'AV', _vm.infoList.XK0C_node_string, 'XK0C_AVVV1', 'XK0C总输出','XK0C')},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.XK0C.AV,
                  'AV',
                  _vm.infoList.XK0C_node_string,
                  'XK0C_AVVV1'
                )}}},[_vm._v(" "+_vm._s('XK0C' in _vm.infoList? _vm.infoList.XK0C.AV :0)+" ")])]),_c('div',{staticClass:"shadow shadow3 float5"},[_c('div',{staticClass:"shadow_words"},[_vm._v("渣温水温条件")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("I04-SWH")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(_vm.infoList.CZSWH, 'I04-SWH', 'CZSWH', _vm.infoList.CZSWH_node_string)}}},[_vm._v(" "+_vm._s('CZSWH' in _vm.infoList? _vm.infoList.CZSWH :0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("I04-PZH")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(_vm.infoList.CZPZH, 'I04-PZH', 'CZPZH', _vm.infoList.CZPZH_node_string)}}},[_vm._v(" "+_vm._s('CZPZH' in _vm.infoList? _vm.infoList.CZPZH :0)+" ")])])])]),_vm._m(4),_c('div',{staticClass:"buttonx float6",style:({
            background:
			'MAN13'||'MAN14'||'MAN28'||'MAN29' in _vm.infoList?
              _vm.infoList.MAN13.RM == 1 ||
              _vm.infoList.MAN14.RM == 1 ||
              _vm.infoList.MAN28.RM == 1 ||
              _vm.infoList.MAN29.RM == 1
                ? '#2AFC30'
                : 'red': 'red',
          }),on:{"click":function($event){return _vm.toCompon(0, 'CFB_MANCZ', 4)}}},[_vm._v(" A ")])]),_c('div',{staticClass:"three"},[_c('div',{staticClass:"shadow shadow1 float1"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("I06-A01")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'CZAO1_B',
                  _vm.infoList.CZAO1_B_node_string,
                  'CZAO1_BVV1',
                  '1#除渣机调节输出'
                )},"dblclick":function($event){return _vm.Cclick(_vm.infoList.CZAO1_B, 'CZAO1_B', _vm.infoList.CZAO1_B_node_string, 'CZAO1_BVV1')}}},[_vm._v(" "+_vm._s('CZAO1_B' in _vm.infoList? _vm.infoList.CZAO1_B :0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("I06-A0-HL")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.MAN13.OUTT,
                  'I06-A0-HL',
                  'OUTT',
                  _vm.infoList.MAN13_node_string
                )}}},[_vm._v(" "+_vm._s('MAN13' in _vm.infoList? _vm.infoList.MAN13.OUTT :0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("I06-A0-LL")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.MAN13.OUTB,
                  'I06-A0-LL',
                  'OUTB',
                  _vm.infoList.MAN13_node_string
                )}}},[_vm._v(" "+_vm._s('MAN13' in _vm.infoList? _vm.infoList.MAN13.OUTB :0)+" ")])])]),_c('div',{staticClass:"shadow shadow1 float2"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("I06-A02")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'CZAO2_B',
                  _vm.infoList.CZAO2_B_node_string,
                  'CZAO2_BVV1',
                  '2#除渣机调节输出'
                )},"dblclick":function($event){return _vm.Cclick(_vm.infoList.CZAO2_B, 'CZAO2_B', _vm.infoList.CZAO2_B_node_string, 'CZAO2_BVV1')}}},[_vm._v(" "+_vm._s('CZAO2_B' in _vm.infoList? _vm.infoList.CZAO2_B :0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("I06-A0-HL")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.MAN14.OUTT,
                  'I06-A0-HL',
                  'OUTT',
                  _vm.infoList.MAN14_node_string
                )}}},[_vm._v(" "+_vm._s('MAN14' in _vm.infoList? _vm.infoList.MAN14.OUTT :0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("I06-A0-LL")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.MAN14.OUTB,
                  'I06-A0-LL',
                  'OUTB',
                  _vm.infoList.MAN14_node_string
                )}}},[_vm._v(" "+_vm._s('MAN14' in _vm.infoList? _vm.infoList.MAN14.OUTB :0)+" ")])])]),_c('div',{staticClass:"shadow shadow1 float3"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("I06-A03")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'CZAO3_B',
                  _vm.infoList.CZAO3_B_node_string,
                  'CZAO3_BVV1',
                  '1#除渣机调节输出'
                )},"dblclick":function($event){return _vm.Cclick(_vm.infoList.CZAO3_B, 'CZAO3_B', _vm.infoList.CZAO3_B_node_string, 'CZAO3_BVV1')}}},[_vm._v(" "+_vm._s('CZAO3_B' in _vm.infoList? _vm.infoList.CZAO3_B :0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("I06-A0-HL")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.MAN28.OUTT,
                  'I06-A0-HL',
                  'OUTT',
                  _vm.infoList.MAN28_node_string
                )}}},[_vm._v(" "+_vm._s('MAN28' in _vm.infoList? _vm.infoList.MAN28.OUTT :0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("I06-A0-LL")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.MAN28.OUTB,
                  'I06-A0-LL',
                  'OUTB',
                  _vm.infoList.MAN28_node_string
                )}}},[_vm._v(" "+_vm._s('MAN28' in _vm.infoList? _vm.infoList.MAN28.OUTB :0)+" ")])])]),_c('div',{staticClass:"shadow shadow1 float4"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("I06-A04")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'CZAO4_B',
                  _vm.infoList.CZAO4_B_node_string,
                  'CZAO4_BVV1',
                  '2#除渣机调节输出'
                )},"dblclick":function($event){return _vm.Cclick(_vm.infoList.CZAO4_B, 'CZAO4_B', _vm.infoList.CZAO4_B_node_string, 'CZAO4_BVV1')}}},[_vm._v(" "+_vm._s('CZAO4_B' in _vm.infoList? _vm.infoList.CZAO4_B :0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("I06-A0-HL")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.MAN29.OUTT,
                  'I06-A0-HL',
                  'OUTT',
                  _vm.infoList.MAN29_node_string
                )}}},[_vm._v(" "+_vm._s('MAN29' in _vm.infoList? _vm.infoList.MAN29.OUTT :0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("I06-A0-LL")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.MAN29.OUTB,
                  'I06-A0-LL',
                  'OUTB',
                  _vm.infoList.MAN29_node_string
                )}}},[_vm._v(" "+_vm._s('MAN29' in _vm.infoList? _vm.infoList.MAN29.OUTB :0)+" ")])])])])]),_c('inputVal',{ref:"inputVal"}),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.node,"node1":_vm.node1,"Lkname":_vm.Lkname,"chName":_vm.chName,"infoList":_vm.infoList},on:{"sendStatus":function($event){_vm.isHshow = false}}}):_vm._e(),(_vm.manyManual)?_c('manyManual',{attrs:{"titname":_vm.Manualtitname,"numM":_vm.Manualnode,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"main_title"},[_vm._v("冷渣机优化控制模型")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("I02-YZ")]),_c('div',{staticClass:"column2"},[_vm._v("0.00")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"button1"},[_c('div',{staticClass:"button1_word"},[_vm._v("I02-CYCL1")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"button2"},[_c('div',{staticClass:"button2_word"},[_vm._v("I02-CYCL2")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pz"},[_c('div',{staticClass:"pz1"},[_vm._v("PZ1")]),_c('div',{staticClass:"pz2"},[_vm._v("PZ2")]),_c('div',{staticClass:"pz3"},[_vm._v("PZ3")]),_c('div',{staticClass:"pz4"},[_vm._v("PZ4")])])}]

export { render, staticRenderFns }