var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"draw",rawName:"v-draw"}],staticClass:"total drag",attrs:{"id":"CfbParameter1"}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"title-hang flex"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.projectData.project)+"_参数01_1")]),_c('div',{staticClass:"icon",on:{"click":_vm.closeCompon}})])]),_vm._m(0),_c('div',{staticClass:"context"},[_c('div',{staticClass:"top flex"},[_c('div',[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"button"},[_c('div',{staticClass:"button_word",style:({
                  background:
                    _vm.infoList.SWHSEL == 1 ? '#2AFC30' : 'red',
                }),on:{"click":function($event){return _vm.toIpt('1', '水位1', 'SWHSEL', _vm.infoList.SWHSEL_node_string, 'true')}}},[_vm._v(" 水位1 ")])]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(2, 'LT101D_B', _vm.infoList.LT101D_B_node_string, 'LT101D_BVV1', '汽包水位1')},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.LT101D_B,
                  'LT101D_B',
                  _vm.infoList.LT101D_B_node_string,
                  'LT101D_BVV1'
                )}}},[_vm._v(" "+_vm._s('LT101D_B' in _vm.infoList? _vm.infoList.LT101D_B :0)+" ")])]),_c('div',{staticClass:"float1 flex"},[_c('div',{staticClass:"button"},[_c('div',{staticClass:"button_word",style:({
                  background:
                    _vm.infoList.SWHSEL == 2 ? '#2AFC30' : 'red',
                }),on:{"click":function($event){return _vm.toIpt('2', '水位2', 'SWHSEL', _vm.infoList.SWHSEL_node_string, 'true')}}},[_vm._v(" 水位2 ")])]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(2, 'LT102D_B', _vm.infoList.LT102D_B_node_string, 'LT102D_BVV1', '汽包水位2')},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.LT102D_B,
                  'LT102D_B',
                  _vm.infoList.LT102D_B_node_string,
                  'LT102D_BVV1'
                )}}},[_vm._v(" "+_vm._s('LT102D_B' in _vm.infoList? _vm.infoList.LT102D_B :0)+" ")])])]),_c('div',{staticClass:"shadow shadow1"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("A01-JBSP")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.SWSP_B1,
                  'A01-JBSP',
                  'SWSP_B1',
                  _vm.infoList.SWSP_B1_node_string
                )}}},[_vm._v(" "+_vm._s('SWSP_B1' in _vm.infoList? _vm.infoList.SWSP_B1 :0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("A01-KSP")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.KZQSW,
                    'A01-KSP',
                    'KZQSW',
                    _vm.infoList.KZQSW_node_string
                  )}}},[_vm._v(" "+_vm._s('KZQSW' in _vm.infoList? _vm.infoList.KZQSW :0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("A01-SJSP")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'SWSJSP',
                    _vm.infoList.SWSJSP_node_string,
                    'SWSJSPVV1',
                    '水位实际控制点'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.SWSJSP,
                    'SWSJSP',
                    _vm.infoList.SWSJSP_node_string,
                    'SWSJSPVV1'
                  )}}},[_vm._v(" "+_vm._s('SWSJSP' in _vm.infoList? _vm.infoList.SWSJSP :0)+" ")])])])]),_c('div',{staticClass:"button1"},[_c('div',{staticClass:"button1_word",on:{"click":function($event){return _vm.toIpt(_vm.infoList.GSMOD, '按钮', 'GSMOD', _vm.infoList.GSMOD_node_string)}}},[_vm._v(" "+_vm._s('GSMOD' in _vm.infoList? _vm.infoList.GSMOD ? "单回路模式" : "三冲量模式" :0)+" ")])])]),_c('div',{staticClass:"bottom flex"},[_c('div',{staticClass:"one"},[_c('div',{staticClass:"flex"},[_c('div',[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"button"},[_c('div',{staticClass:"button_word",style:({
                      background:
                        _vm.infoList.SWHSEL == 4 ? '#2AFC30' : 'red',
                    }),on:{"click":function($event){return _vm.toIpt('4', '水位3', 'SWHSEL', _vm.infoList.SWHSEL_node_string, 'true')}}},[_vm._v(" 水位3 ")])]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'LT103D_B',
                      _vm.infoList.LT103D_B_node_string,
                      'LT103D_BVV1',
                      '汽包水位3'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.LT103D_B,
                      'LT103D_B',
                      _vm.infoList.LT103D_B_node_string,
                      'LT103D_BVV1'
                    )}}},[_vm._v(" "+_vm._s('LT103D_B' in _vm.infoList? _vm.infoList.LT103D_B :0)+" ")])]),_c('div',{staticClass:"float1 flex"},[_c('div',{staticClass:"button"},[_c('div',{staticClass:"button_word",style:({
                      background:
                        _vm.infoList.SWHSEL == 8 ? '#2AFC30' : 'red',
                    }),on:{"click":function($event){return _vm.toIpt('8', '水位4', 'SWHSEL', _vm.infoList.SWHSEL_node_string, 'true')}}},[_vm._v(" 水位4 ")])]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'LT104D_B',
                      _vm.infoList.LT104D_B_node_string,
                      'LT104D_BVV1',
                      '汽包水位4'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.LT104D_B,
                      'LT104D_B',
                      _vm.infoList.LT104D_B_node_string,
                      'LT104D_BVV1'
                    )}}},[_vm._v(" "+_vm._s('LT104D_B' in _vm.infoList? _vm.infoList.LT104D_B :0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"word"},[_vm._v("坏值阈值")]),_c('div',{staticClass:"column4",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.TEMP3,
                      '坏值阈值',
                      'TEMP3',
                      _vm.infoList.TEMP3_node_string
                    )}}},[_vm._v(" "+_vm._s('TEMP3' in _vm.infoList? _vm.infoList.TEMP3 :0)+" ")])])]),_c('div',{staticClass:"shadow shadow1"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("A01-SWPV")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'SWSJPV',
                      _vm.infoList.SWSJPV_node_string,
                      'SWSJPVVV1',
                      '汽包水位均值'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.SWSJPV,
                      'SWSJPV',
                      _vm.infoList.SWSJPV_node_string,
                      'SWSJPVVV1'
                    )}}},[_vm._v(" "+_vm._s('SWSJPV' in _vm.infoList? _vm.infoList.SWSJPV :0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("A01-SWTC")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.SWTC,
                      'A01-SWTC',
                      'SWTC',
                      _vm.infoList.SWTC_node_string
                    )}}},[_vm._v(" "+_vm._s('SWTC' in _vm.infoList? _vm.infoList.SWTC :0)+" ")])])])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"shadow shadow2"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("A02-FGS")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'FT101D_B',
                      _vm.infoList.FT101D_B_node_string,
                      'FT101D_BVV1',
                      '给水流量'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.FT101D_B,
                      'FT101D_B',
                      _vm.infoList.FT101D_B_node_string,
                      'FT101D_BVV1'
                    )}}},[_vm._v(" "+_vm._s('FT101D_B' in _vm.infoList? _vm.infoList.FT101D_B :0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("A02-FZQ")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(2, 'BYMNL59', _vm.infoList.BYMNL59_node_string, 'BYMNL59VV1', '主蒸汽流量')},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.BYMNL59,
                      'BYMNL59',
                      _vm.infoList.BYMNL59_node_string,
                      'BYMNL59VV1'
                    )}}},[_vm._v(" "+_vm._s('BYMNL59' in _vm.infoList? _vm.infoList.BYMNL59 :0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("A02-QSTC")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.QSTC,
                      'A02-QSTC',
                      'QSTC',
                      _vm.infoList.QSTC_node_string
                    )}}},[_vm._v(" "+_vm._s('QSTC' in _vm.infoList? _vm.infoList.QSTC :0)+" ")])])]),_c('div',{staticClass:"shadow shadow3 flex"},[_c('div',{staticClass:"column1"},[_vm._v("A02-QSPC")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'PV',
                    _vm.infoList.XK0M_node_string,
                    'XK0M_PVVV1',
                    'XK0L测量值',
					'XK0M'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.XK0M.PV,
                    'PV',
                    _vm.infoList.XK0M_node_string,
                    'XK0M_PVVV1'
                  )}}},[_vm._v(" "+_vm._s('XK0M' in _vm.infoList? _vm.infoList.XK0M.PV :0)+" ")])])])]),_c('div',{staticClass:"two"},[_c('div',{staticClass:"buttonx float1",style:({
              background:
                _vm.infoList.XK01 && !_vm.infoList.XK01.SP
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.XK01
                ? _vm.toDetail(1, 'XK01', _vm.infoList.XK01_node_string, '', '汽包液位调节')
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"buttonx float2",style:({
              background:
                _vm.infoList.XK0M && !_vm.infoList.XK0M.SP
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.XK0M
                ? _vm.toDetail(
                    1,
                    'XK0M',
                    _vm.infoList.XK0M_node_string,
                    '',
                    '主给水阀门调节先控'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"buttonx float3",style:({
              background:
			  'MAN5'||'MAN6' in _vm.infoList?
                _vm.infoList.MAN5.RM == 1 ||
                _vm.infoList.MAN6.RM == 1
                  ? '#2AFC30'
                  : 'red': 'red',
            }),on:{"click":function($event){return _vm.toCompon(0, 'CFB_MANGS', 2)}}},[_vm._v(" A ")]),_c('div',{staticClass:"buttonx float4",style:({
              background:
                _vm.infoList.XK0N && !_vm.infoList.XK0N.SP
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.XK0N
                ? _vm.toDetail(
                    1,
                    'XK0N',
                    _vm.infoList.XK0N_node_string,
                    '',
                    '副给水阀门调节先控'
                  )
                : ''}}},[_vm._v(" X ")])]),_c('div',{staticClass:"three"},[_c('div',{staticClass:"shadow shadow1 float1 flex"},[_c('div',{staticClass:"column1"},[_vm._v("A03-A01")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'KF_SW1_B',
                  _vm.infoList.KF_SW1_B_node_string,
                  'KF_SW1_BVV1',
                  '汽包水位A调节阀反馈'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.KF_SW1_B,
                  'KF_SW1_B',
                  _vm.infoList.KF_SW1_B_node_string,
                  'KF_SW1_BVV1'
                )}}},[_vm._v(" "+_vm._s('KF_SW1_B' in _vm.infoList? _vm.infoList.KF_SW1_B :0)+" ")])]),_c('div',{staticClass:"shadow shadow1 float2 flex"},[_c('div',{staticClass:"column1"},[_vm._v("A03-A02")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'KF_SW2_B',
                  _vm.infoList.KF_SW2_B_node_string,
                  'KF_SW2_BVV1',
                  '汽包水位B调节阀反馈'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.KF_SW2_B,
                  'KF_SW2_B',
                  _vm.infoList.KF_SW2_B_node_string,
                  'KF_SW2_BVV1'
                )}}},[_vm._v(" "+_vm._s('KF_SW2_B' in _vm.infoList? _vm.infoList.KF_SW2_B :0)+" ")])])])])]),_c('inputVal',{ref:"inputVal"}),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.node,"node1":_vm.node1,"Lkname":_vm.Lkname,"chName":_vm.chName,"infoList":_vm.infoList},on:{"sendStatus":function($event){_vm.isHshow = false}}}):_vm._e(),(_vm.manyManual)?_c('manyManual',{attrs:{"titname":_vm.Manualtitname,"numM":_vm.Manualnode,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"main_title"},[_vm._v("汽包水位优化控制模型")])])}]

export { render, staticRenderFns }