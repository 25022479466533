<template>
  <div class="total drag" v-draw id="CfbParameter5">
    <!-- 抬头标题 -->
    <div class="flex">
      <div class="title-hang flex">
        <div class="title">{{ projectData.project }}_参数05_1</div>
        <div class="icon" @click="closeCompon"></div>
      </div>
    </div>
    <!-- 标题 -->
    <div class="main">
      <div class="main_title">引风优化控制模型</div>
    </div>
    <!-- 主体内容 -->
    <div class="context flex">
      <div class="one">
        <div class="shadow shadow1 flex">
          <div class="column1">F01-SP</div>
          <div
            class="column2"
            @click="
              toIpt(
                infoList.FYSP_B1,
                'F01-SP',
                'FYSP_B1',
                infoList.FYSP_B1_node_string
              )
            "
          >
            {{ "FYSP_B1" in infoList ? infoList.FYSP_B1 : 0 }}
          </div>
        </div>
        <div class="shadow shadow2">
          <div class="flex float2">
            <div class="button_column1">
              <div class="button">
                <div
                  class="button_word"
                  :style="{
                    background: infoList.SELYF == 1 ? '#2AFC30' : 'red',
                  }"
                  @click="
                    toIpt(
                      '1',
                      '负压1',
                      'SELYF',
                      infoList.SELYF_node_string,
                      'true'
                    )
                  "
                >
                  负压1
                </div>
              </div>
            </div>
            <div
              class="column3"
              @click="
                toCompon(
                  2,
                  'PT124D_B',
                  infoList.PT124D_B_node_string,
                  'PT124D_BVV1',
                  '炉膛负压1'
                )
              "
              @dblclick="
                Cclick(
                  infoList.PT124D_B,
                  'PT124D_B',
                  infoList.PT124D_B_node_string,
                  'PT124D_BVV1'
                )
              "
            >
              {{ "PT124D_B" in infoList ? infoList.PT124D_B : 0 }}
            </div>
          </div>
          <div class="flex">
            <div class="button_column1">
              <div class="button">
                <div
                  class="button_word"
                  :style="{
                    background: infoList.SELYF == 2 ? '#2AFC30' : 'red',
                  }"
                  @click="
                    toIpt(
                      '2',
                      '负压2',
                      'SELYF',
                      infoList.SELYF_node_string,
                      'true'
                    )
                  "
                >
                  负压2
                </div>
              </div>
            </div>
            <div
              class="column3"
              @click="
                toCompon(
                  2,
                  'PT125D_B',
                  infoList.PT125D_B_node_string,
                  'PT125D_BVV1',
                  '炉膛负压2'
                )
              "
              @dblclick="
                Cclick(
                  infoList.PT125D_B,
                  'PT125D_B',
                  infoList.PT125D_B_node_string,
                  'PT125D_BVV1'
                )
              "
            >
              {{ "PT125D_B" in infoList ? infoList.PT125D_B : 0 }}
            </div>
          </div>
          <div class="flex">
            <div class="button_column1">
              <div class="button">
                <div
                  class="button_word"
                  :style="{
                    background: infoList.SELYF == 4 ? '#2AFC30' : 'red',
                  }"
                  @click="
                    toIpt(
                      '4',
                      '负压3',
                      'SELYF',
                      infoList.SELYF_node_string,
                      'true'
                    )
                  "
                >
                  负压3
                </div>
              </div>
            </div>
            <div
              class="column3"
              @click="
                toCompon(
                  2,
                  'PT126D_B',
                  infoList.PT126D_B_node_string,
                  'PT126D_BVV1',
                  '炉膛负压3'
                )
              "
              @dblclick="
                Cclick(
                  infoList.PT126D_B,
                  'PT126D_B',
                  infoList.PT126D_B_node_string,
                  'PT126D_BVV1'
                )
              "
            >
              {{ "PT126D_B" in infoList ? infoList.PT126D_B : 0 }}
            </div>
          </div>
          <div class="flex">
            <div class="button_column1">
              <div class="button">
                <div
                  class="button_word"
                  :style="{
                    background: infoList.SELYF == 8 ? '#2AFC30' : 'red',
                  }"
                  @click="
                    toIpt(
                      '8',
                      '负压4',
                      'SELYF',
                      infoList.SELYF_node_string,
                      'true'
                    )
                  "
                >
                  负压4
                </div>
              </div>
            </div>
            <div
              class="column3"
              @click="
                toCompon(
                  2,
                  'PT127D_B',
                  infoList.PT127D_B_node_string,
                  'PT127D_BVV1',
                  '炉膛负压4'
                )
              "
              @dblclick="
                Cclick(
                  infoList.PT127D_B,
                  'PT127D_B',
                  infoList.PT127D_B_node_string,
                  'PT127D_BVV1'
                )
              "
            >
              {{ "PT127D_B" in infoList ? infoList.PT127D_B : 0 }}
            </div>
          </div>
          <div class="flex">
            <div class="column1 float1">F01-TC</div>
            <div
              class="column2"
              @click="
                toIpt(
                  infoList.TCFY,
                  'F01-TC',
                  'TCFY',
                  infoList.TCFY_node_string
                )
              "
            >
              {{ "TCFY" in infoList ? infoList.TCFY : 0 }}
            </div>
          </div>
          <div class="flex">
            <div class="column1 float1">F01-YZ</div>
            <div
              class="column2"
              @click="
                toIpt(
                  infoList.FYHZYZ,
                  'F01-YZ',
                  'FYHZYZ',
                  infoList.FYHZYZ_node_string
                )
              "
            >
              {{ "FYHZYZ" in infoList ? infoList.FYHZYZ : 0 }}
            </div>
          </div>
          <div class="flex">
            <div class="column1 float1">F01-PV</div>
            <div
              class="column3"
              @click="
                toCompon(
                  2,
                  'PV',
                  infoList.XK06_node_string,
                  'XK06_PVVV1',
                  'XK06测量值',
                  'XK06'
                )
              "
              @dblclick="
                Cclick(
                  infoList.XK06.PV,
                  'PV',
                  infoList.XK06_node_string,
                  'XK06_PVVV1'
                )
              "
            >
              {{ "XK06" in infoList ? infoList.XK06.PV : 0 }}
            </div>
          </div>
        </div>
        <div class="shadow shadow3 flex">
          <div class="column1 float1">F02-SP</div>
          <div
            class="column2"
            @click="
              toIpt(
                infoList.FYSP_B1,
                'F02-SP',
                'FYSP_B1',
                infoList.FYSP_B1_node_string
              )
            "
          >
            {{ "FYSP_B1" in infoList ? infoList.FYSP_B1 : 0 }}
          </div>
        </div>
      </div>
      <div class="two flex">
        <div
          class="num"
          @click="
            toCompon(
              2,
              'LTPJZ',
              infoList.LTPJZ_node_string,
              'LTPJZVV1',
              '炉膛负压均值'
            )
          "
          @dblclick="
            Cclick(
              infoList.LTPJZ,
              'LTPJZ',
              infoList.LTPJZ_node_string,
              'LTPJZVV1'
            )
          "
        >
          {{ "LTPJZ" in infoList ? infoList.LTPJZ : 0 }}
        </div>
        <div>
          <div
            class="buttonx float1"
            :style="{
              background:
                infoList.XK05 && !infoList.XK05.RM ? '#2AFC30' : 'red',
            }"
            @click="
              infoList.XK05
                ? toDetail(
                    1,
                    'XK05',
                    infoList.XK05_node_string,
                    '',
                    '负压挡板调节'
                  )
                : ''
            "
          >
            X
          </div>
          <div
            class="buttonx float2"
            :style="{
              background:
                infoList.XK06 && !infoList.XK06.RM ? '#2AFC30' : 'red',
            }"
            @click="
              infoList.XK06
                ? toDetail(
                    1,
                    'XK06',
                    infoList.XK06_node_string,
                    '',
                    '负压变频调节'
                  )
                : ''
            "
          >
            X
          </div>
        </div>
        <div>
          <div class="button1">
            <div
              class="button1_word"
              @click="
                toIpt(
                  infoList.QK04SEL,
                  '按钮',
                  'QK04SEL',
                  infoList.QK04SEL_node_string
                )
              "
            >
              {{
                "QK04SEL" in infoList
                  ? infoList.QK04SEL
                    ? "前馈投用"
                    : "前馈切除"
                  : 0
              }}
            </div>
          </div>
          <div
            class="buttonr float3"
            :style="{
              background:
                infoList.QK04 && !infoList.QK04.TS ? '#2AFC30' : 'red',
            }"
            @click="
              infoList.QK04
                ? toDetail(
                    2,
                    'QK04',
                    infoList.QK04_node_string,
                    '',
                    '送风前馈引风'
                  )
                : ''
            "
          >
            K
          </div>
        </div>
        <div>
          <div class="shadow shadow1 float4 flex">
            <div class="column1">F01-AV</div>
            <div
              class="column3"
              @click="
                toCompon(
                  2,
                  'AV',
                  infoList.XK05_node_string,
                  'XK05_AVVV1',
                  'XK05总输出',
                  'XK05'
                )
              "
              @dblclick="
                Cclick(
                  infoList.XK05.AV,
                  'AV',
                  infoList.XK05_node_string,
                  'XK05_AVVV1'
                )
              "
            >
              {{ "XK05" in infoList ? infoList.XK05.AV : 0 }}
            </div>
          </div>
          <div class="shadow shadow2">
            <div class="float5 flex">
              <div class="column1">F03-DLTC</div>
              <div
                class="column2"
                @click="
                  toIpt(
                    infoList.DLLB,
                    'F03-DLTC',
                    'DLLB',
                    infoList.DLLB_node_string
                  )
                "
              >
                {{ "DLLB" in infoList ? infoList.DLLB : 0 }}
              </div>
            </div>
            <div class="float5 flex">
              <div class="column11">F03-DLPV1</div>
              <div
                class="column3"
                @click="
                  toCompon(
                    2,
                    'II_YFJ1_B',
                    infoList.II_YFJ1_B_node_string,
                    'II_YFJ1_BVV1',
                    '1#引风机电流'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.II_YFJ1_B,
                    'II_YFJ1_B',
                    infoList.II_YFJ1_B_node_string,
                    'II_YFJ1_BVV1'
                  )
                "
              >
                {{ "II_YFJ1_B" in infoList ? infoList.II_YFJ1_B : 0 }}
              </div>
            </div>
            <div class="flex">
              <div class="button2">
                <div
                  class="button2_word"
                  @click="
                    toIpt(
                      infoList.RSF0HSEL,
                      '按钮',
                      'RSF0HSEL',
                      infoList.RSF0HSEL_node_string
                    )
                  "
                >
                  {{
                    "RSF0HSEL" in infoList
                      ? infoList.RSF0HSEL
                        ? "电流纠偏投用"
                        : "电流纠偏切除"
                      : 0
                  }}
                </div>
              </div>
              <div
                class="buttonr float6"
                :style="{
                  background:
                    infoList.RSF0H && !infoList.RSF0H.SP ? '#2AFC30' : 'red',
                }"
                @click="
                  infoList.RSF0H
                    ? toDetail(
                        3,
                        'RSF0H',
                        infoList.RSF0H_node_string,
                        '',
                        '引风机电流纠偏'
                      )
                    : ''
                "
              >
                R
              </div>
            </div>
            <div class="float5 flex">
              <div class="column11">F03-DLPV2</div>
              <div
                class="column3"
                @click="
                  toCompon(
                    2,
                    'II_YFJ2_B',
                    infoList.II_YFJ2_B_node_string,
                    'II_YFJ2_BVV1',
                    '2#引风机电流'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.II_YFJ2_B,
                    'II_YFJ2_B',
                    infoList.II_YFJ2_B_node_string,
                    'II_YFJ2_BVV1'
                  )
                "
              >
                {{ "II_YFJ2_B" in infoList ? infoList.II_YFJ2_B : 0 }}
              </div>
            </div>
          </div>
          <div class="shadow shadow1 float7 flex">
            <div class="column1">F02-AV2</div>
            <div
              class="column3"
              @click="
                toCompon(
                  2,
                  'AV',
                  infoList.XK06_node_string,
                  'XK06_AVVV1',
                  'XK06总输出',
                  'XK06'
                )
              "
              @dblclick="
                Cclick(
                  infoList.XK06.AV,
                  'AV',
                  infoList.XK06_node_string,
                  'XK06_AVVV1'
                )
              "
            >
              {{ "XK06" in infoList ? infoList.XK06.AV : 0 }}
            </div>
          </div>
        </div>
        <div class="pz">
          <div class="pz1">PZ1</div>
          <div class="pz2">PZ2</div>
          <div class="pz3">PZ3</div>
          <div class="pz4">PZ4</div>
        </div>
        <div
          class="buttonx float8"
          :style="{
            background:
              infoList.MAN19.RM == 1 ||
              infoList.MAN20.RM == 1 ||
              infoList.MAN21.RM == 1 ||
              infoList.MAN22.RM == 1
                ? '#2AFC30'
                : 'red',
          }"
          @click="toCompon(0, 'CFB_MANYF', 4)"
        >
          A
        </div>
      </div>
      <div class="three">
        <div class="shadow shadow1 float1 flex">
          <div class="column1">F01-A01</div>
          <div
            class="column3"
            @click="
              toCompon(
                2,
                'YFDBAO1_B',
                infoList.YFDBAO1_B_node_string,
                'YFDBAO1_BVV1',
                '1#引风挡板调节输出'
              )
            "
            @dblclick="
              Cclick(
                infoList.YFDBAO1_B,
                'YFDBAO1_B',
                infoList.YFDBAO1_B_node_string,
                'YFDBAO1_BVV1'
              )
            "
          >
            {{ "YFDBAO1_B" in infoList ? infoList.YFDBAO1_B : 0 }}
          </div>
        </div>
        <div class="shadow shadow1 float2 flex">
          <div class="column1">F01-A02</div>
          <div
            class="column3"
            @click="
              toCompon(
                2,
                'YFDBAO2_B',
                infoList.YFDBAO2_B_node_string,
                'YFDBAO2_BVV1',
                '2#引风挡板调节输出'
              )
            "
            @dblclick="
              Cclick(
                infoList.YFDBAO2_B,
                'YFDBAO2_B',
                infoList.YFDBAO2_B_node_string,
                'YFDBAO2_BVV1'
              )
            "
          >
            {{ "YFDBAO2_B" in infoList ? infoList.YFDBAO2_B : 0 }}
          </div>
        </div>
        <div class="shadow shadow1 float3 flex">
          <div class="column1">F02-A01</div>
          <div
            class="column3"
            @click="
              toCompon(
                2,
                'YFBPAO1_B',
                infoList.YFBPAO1_B_node_string,
                'YFBPAO1_BVV1',
                '1#引风变频阀位输出'
              )
            "
            @dblclick="
              Cclick(
                infoList.YFBPAO1_B,
                'YFBPAO1_B',
                infoList.YFBPAO1_B_node_string,
                'YFBPAO1_BVV1'
              )
            "
          >
            {{ "YFBPAO1_B" in infoList ? infoList.YFBPAO1_B : 0 }}
          </div>
        </div>
        <div class="shadow shadow1 float4 flex">
          <div class="column1">F02-A02</div>
          <div
            class="column3"
            @click="
              toCompon(
                2,
                'YFBPAO2_B',
                infoList.YFBPAO2_B_node_string,
                'YFBPAO2_BVV1',
                '2#引风变频阀位输出'
              )
            "
            @dblclick="
              Cclick(
                infoList.YFBPAO2_B,
                'YFBPAO2_B',
                infoList.YFBPAO2_B_node_string,
                'YFBPAO2_BVV1'
              )
            "
          >
            {{ "YFBPAO2_B" in infoList ? infoList.YFBPAO2_B : 0 }}
          </div>
        </div>
      </div>
    </div>
    <inputVal ref="inputVal"></inputVal>
    <Historical
      v-if="isHshow"
      @sendStatus="isHshow = false"
      :historyname="historyname"
      :node="node"
      :node1="node1"
      :Lkname="Lkname"
      :chName="chName"
      :infoList="infoList"
    ></Historical>
    <manyManual
      v-if="manyManual"
      :titname="Manualtitname"
      :numM="Manualnode"
      @sendStatus="isClose"
      :infoList="infoList"
    ></manyManual>
  </div>
</template>

<script>
import inputVal from "@/components/inputVal.vue"; //输入框组件
import Historical from "@/components/Historicaljrl.vue"; //历史趋势
import manyManual from "@/components/manyManual.vue"; //手操器组件
export default {
  name: "CfbParameter3",
  props: {
    infoList: {
      type: Object,
      default: () => {
        return {}; // 默认值
      },
    },
  },
  components: {
    inputVal,
    Historical,
    manyManual,
  },
  data: () => {
    return {
      chName: "",
      manyManual: false,
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      isComShow: false,
      isHshow: false,
      isMshow: false,
      isRshow: false,
      historyname: "",
      node: "",
      Lkname: "",
      isIndex: "",
      Firstcontrolname: "",
      Firstcontroltitname: "",
      Firstcontrolnode: "",
      isFshow: false,
      Rsfname: "",
      Rsfnode: "",
      Rsftitname: "",
      projectData: "",
      grouptime: null,
      spotArr: [],
      authInfo: [],
      UserInfo: [],
      node1:''
    };
  },
  watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
        console.log(this.infoList);
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  created() {
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
    this.authInfo = JSON.parse(localStorage.getItem("autharr"));
    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
  },
  methods: {
    // 打开先控多窗口
    toDetail(key, name, nodename, Aname, titname) {
      if (!this.authInfo[1]) {
        let str = this.authInfo[0];
        // 判断是否为调试还是查看权限
        if (str.charAt(str.length - 1) == "r") {
          this.$message.error("暂无调试权限");
        }
      } else {
        let pathname = "";
        switch (key) {
          case 1:
            pathname = "firstcontolJRL";
            break;
          case 2:
            pathname = "Cfbkfirstcontol";
            break;
          case 3:
            pathname = "CfbRfirstcontol";
            break;
        }
        const newurl = this.$router.resolve({
          name: pathname,
          query: {
            Firstcontrolname: name,
            Firstcontrolnode: nodename,
            Firstcontroltitname: titname,
          },
        });
        window.open(newurl.href, "_blank");
      }
    },
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }

        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
    isshowfase() {
      this.isComShow = false;
    },
    closeCompon() {
      this.$emit("sendStatus", "Parameter05", false);
    },
    isClose(val, val1) {
      switch (val) {
        case "Historical":
          return (this.isHshow = val1);
        case "manyManual":
          return (this.manyManual = val1);
      }
    },
    toIpt(data, name, historyname, node, type) {
      this.$refs.inputVal.open(data, name, historyname, node, type);
    },
    toCompon(key, name, name2, name3, name4, titname) {
      this.isIndex = key;
      this.historyname = name;
      this.node = name2;
      this.Lkname = name3;
      clearTimeout(this.grouptime);
      this.grouptime = setTimeout(() => {
        switch (key) {
          case 0:
            return (
              (this.manyManual = true),
              (this.Manualtitname = name),
              (this.Manualnode = name2)
            );
          case 1:
            return (
              (this.isFshow = true),
              (this.Firstcontrolname = name),
              (this.Firstcontrolnode = name2),
              (this.Firstcontroltitname = titname)
            );
          case 2:
            return (this.isHshow = true), (this.chName = name4),this.node1=titname;
          case 4:
            return (
              (this.isRshow = true),
              (this.Rsfname = name),
              (this.Rsfnode = name2),
              (this.Rsftitname = titname)
            );
        }
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
#CfbParameter5 {
  width: 53vw;
  height: 67vh;
  background-image: url("~@/assets/images/anquanshezhiditu.png");
  background-size: 100% 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -33.5vh 0 0 -26.5vw;
  z-index: 999;

  .title-hang {
    width: 53vw;
    height: 5vh;
    font-family: MicrosoftYaHei;
    font-size: 2vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vh;
    letter-spacing: 0vh;
    margin-left: 15vw;
  }

  .title {
    width: 19vw;
    height: 4vh;
    font-family: MicrosoftYaHei;
    font-size: 2vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 4vh;
    letter-spacing: 0vh;
    color: #0ef7ff;
    margin-top: 1vh;
    text-align: center;
    margin-left: 1.5vw;
  }

  .icon {
    width: 1.8vw;
    height: 3.8vh;
    background-image: url("~@/assets/images/RqBoiler/icon_close1.png");
    background-size: 100% 100%;
    margin-left: 15vw;
    margin-top: 1.8vh;
	cursor: pointer;
  }

  .main {
    width: 53vw;
    height: 2vh;
    margin-top: 3vh;

    .main_title {
      font-family: PingFang-SC-Regular;
      font-size: 3vh;
      font-weight: normal;
      font-stretch: normal;
      line-height: 2vh;
      letter-spacing: 1vh;
      color: #d5fffe;
      text-align: center;
    }
  }

  .context {
    width: 51.1vw;
    height: 31vh;
    margin-top: 3vh;
    margin-left: 1.1vw;
    font-family: PingFang-SC-Regular;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0vw;

    .shadow {
      background-color: #001b2860;
      box-shadow: 0vw 0vw 0vw 0vw #15384c;
      border-radius: 1px;
      border: solid 1px #236f8d;
      font-size: 1.8vh;
      line-height: 2.5vh;

      .column1 {
        width: 4vw;
        color: #8aeaff;
        margin-left: 0.3vw;
		cursor: pointer;
      }
	  .column11{
		width: 4.8vw;
        color: #8aeaff;
        margin-left: 0.3vw;
		cursor: pointer;
	  }

      .column2 {
        width: 2vw;
        color: #00ffb4;
        text-align: right;
		cursor: pointer;
      }

      .column3 {
        width: 2vw;
        color: #00e4ff;
        text-align: right;
		cursor: pointer;
      }
    }

    .buttonx {
      width: 1.3vw;
      height: 2.2vh;
      border: 2px solid rgb(217, 243, 145);
      text-align: center;
      line-height: 2vh;
      background-color: #00e4ff;
      color: white;
	  cursor: pointer;
    }

    .buttonr {
      width: 1.3vw;
      height: 2.2vh;
      border: 2px solid rgb(217, 243, 145);
      text-align: center;
      line-height: 2vh;
      background-color: red;
      color: white;
	  cursor: pointer;
    }

    .one {
      .shadow1 {
        width: 7.3vw;
        height: 2.2vh;
        margin-top: 4.7vh;

        .column1 {
          margin-left: 0.7vw;
        }
      }

      .shadow2 {
        width: 7.3vw;
        height: 21.2vh;
        margin-top: 2.7vh;

        .float2 {
          margin-top: 1vh;
        }

        .button_column1 {
          width: 4vw;

          .button {
            width: 3.1vw;
            height: 2.6vh;
            background-color: #22fff7;
            border-radius: 1px;
            margin-top: 0.3vh;
            margin-left: 0.5vw;
            text-align: center;

            .button_word {
              font-size: 1.5vh;
              line-height: 2.8vh;
              color: #0a4c62;
			  cursor: pointer;
            }
          }
        }

        .float1 {
          margin-left: 0.7vw;
        }
      }

      .shadow3 {
        width: 7.3vw;
        height: 2.2vh;
        margin-top: 2.4vh;

        .column1 {
          margin-left: 0.7vw;
        }
      }
    }

    .two {
      width: 35.7vw;
      height: 40.8vh;
      background-image: url("~@/assets/images/CfbBoiler/para051.png");
      background-size: 100% 100%;

      .num {
        font-size: 1.8vh;
        line-height: 1.9vh;
        color: #2fc3e3;
        margin-top: 17.6vh;
        margin-left: 1vw;
		cursor: pointer;
      }

      .float1 {
        margin-left: 0.3vw;
        margin-top: 5vh;
      }

      .float2 {
        margin-left: 0.3vw;
        margin-top: 26vh;
      }

      .button1 {
        width: 3.5vw;
        height: 1.9vh;
        background-color: #22fff7;
        border-radius: 1px;
        text-align: center;
        margin-left: 3.3vw;
        margin-top: 16vh;

        .button1_word {
          font-size: 1.6vh;
          line-height: 1.8vh;
          color: #0a4c62;
        }
      }

      .float3 {
        margin-left: 4.2vw;
        margin-top: 0.5vh;
      }

      .shadow1 {
        width: 7.3vw;
        height: 2.2vh;
      }

      .float4 {
        margin-left: 4.2vw;
        margin-top: 4.9vh;
      }

      .shadow2 {
        width: 8.3vw;
        height: 11.1vh;
        margin-left: 3vw;
        margin-top: 5.8vh;

        .float5 {
          margin-left: 0.2vw;
        }

        .button2 {
          width: 5.5vw;
          height: 2.5vh;
          border-radius: 1px;
          border: solid 1px #0bd0c9;
          text-align: center;
          margin-left: 0.3vw;

          .button2_word {
            font-size: 1.6vh;
            line-height: 2.4vh;
            color: #22fff7;
          }
        }

        .float6 {
          margin-left: 0.5vw;
        }
      }

      .float7 {
        margin-top: 9.2vh;
        margin-left: 4vw;
      }

      .pz {
        font-size: 1vh;
        line-height: 3vh;
        color: #2fc3e3;
        margin-left: 4.8vw;

        .pz1 {
          margin-top: 0.3vh;
        }

        .pz2 {
          margin-top: 5.1vh;
        }

        .pz3 {
          margin-top: 18vh;
        }

        .pz4 {
          margin-top: 5.1vh;
        }
      }

      .float8 {
        margin-top: 19vh;
        margin-left: 2.8vw;
      }
    }

    .three {
      .shadow1 {
        width: 7.7vw;
        height: 2.2vh;
      }

      .float1 {
        margin-top: 0.8vh;
      }

      .float2 {
        margin-top: 5.3vh;
      }

      .float3 {
        margin-top: 18.8vh;
      }

      .float4 {
        margin-top: 5.5vh;
      }
    }
  }
}
</style>
