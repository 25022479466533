var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"draw",rawName:"v-draw"}],staticClass:"total drag",attrs:{"id":"CfbParameter6"}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"title-hang flex"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.projectData.project)+"_参数06_1")]),_c('div',{staticClass:"icon",on:{"click":_vm.closeCompon}})])]),_vm._m(0),_c('div',{staticClass:"context"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"one"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"button1"},[_c('div',{staticClass:"button1_word",on:{"click":function($event){return _vm.toCompon(3)}}},[_vm._v("风量软测量")])]),_c('div',{staticClass:"button2"},[_c('div',{staticClass:"button2_word",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.SELFL1,
                  '按钮',
                  'SELFL1',
                  _vm.infoList.SELFL1_node_string
                )}}},[_vm._v(" "+_vm._s("SELFL1" in _vm.infoList ? _vm.infoList.SELFL1 ? "软测量" : "实际测量" : 0)+" ")])])]),_c('div',{staticClass:"shadow shadow1"},[_c('div',{staticClass:"flex"},[_c('div',[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("G01-K1")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.FLXS,
                      'G01-K1',
                      'FLXS',
                      _vm.infoList.FLXS_node_string
                    )}}},[_vm._v(" "+_vm._s("FLXS" in _vm.infoList ? _vm.infoList.FLXS : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("G01-PZ1")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.FLCS,
                      'G01-PZ1',
                      'FLCS',
                      _vm.infoList.FLCS_node_string
                    )}}},[_vm._v(" "+_vm._s("FLCS" in _vm.infoList ? _vm.infoList.FLCS : 0)+" ")])])]),_c('div',{staticClass:"location"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("G01-K2")]),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":String(_vm.infoList.FLXS2),"placement":"top"}},[_c('div',{staticClass:"column2 textov",on:{"click":function($event){return _vm.toIpt(
                        _vm.infoList.FLXS2,
                        'G01-K2',
                        'FLXS2',
                        _vm.infoList.FLXS2_node_string
                      )}}},[_vm._v(" "+_vm._s("FLXS2" in _vm.infoList ? _vm.infoList.FLXS2 : 0)+" ")])])],1),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("G01-PZ2")]),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":String(_vm.infoList.FLCS2),"placement":"top"}},[_c('div',{staticClass:"column2 textov",on:{"click":function($event){return _vm.toIpt(
                        _vm.infoList.FLCS2,
                        'G01-PZ2',
                        'FLCS2',
                        _vm.infoList.FLCS2_node_string
                      )}}},[_vm._v(" "+_vm._s("FLCS2" in _vm.infoList ? _vm.infoList.FLCS2 : 0)+" ")])])],1)])])]),_c('div',{staticClass:"shadow shadow2"},[_c('div',{staticClass:"words"},[_vm._v("一次风量信号处理模型")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("G02-TC2")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.TYCFFOP,
                    'G02-TC2',
                    'TYCFFOP',
                    _vm.infoList.TYCFFOP_node_string
                  )}}},[_vm._v(" "+_vm._s("TYCFFOP" in _vm.infoList ? _vm.infoList.TYCFFOP : 0)+" ")])]),_c('div',{staticClass:"location flex"},[_c('div',{staticClass:"column1"},[_vm._v("G02-01")]),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":String(_vm.infoList.JSFL),"placement":"top"}},[_c('div',{staticClass:"column3 textov",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'JSFL',
                      _vm.infoList.JSFL_node_string,
                      'JSFLVV1',
                      '一次风量'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.JSFL,
                      'JSFL',
                      _vm.infoList.JSFL_node_string,
                      'JSFLVV1'
                    )}}},[_vm._v(" "+_vm._s("JSFL" in _vm.infoList ? _vm.infoList.JSFL : 0)+" ")])])],1)]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("G02-LJ")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.YCFLJFL,
                  'G02-LJ',
                  'YCFLJFL',
                  _vm.infoList.YCFLJFL_node_string
                )}}},[_vm._v(" "+_vm._s('YCFLJFL' in _vm.infoList ? _vm.infoList.YCFLJFL : 0)+" ")])])]),_c('div',{staticClass:"shadow shadow3"},[_c('div',{staticClass:"words"},[_vm._v("煤量一次风模型")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("G03-WLYZ")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MLGBYCFYZ,
                    'G03-WLYZ',
                    'MLGBYCFYZ',
                    _vm.infoList.MLGBYCFYZ_node_string
                  )}}},[_vm._v(" "+_vm._s("MLGBYCFYZ" in _vm.infoList ? _vm.infoList.MLGBYCFYZ : 0)+" ")])]),_c('div',{staticClass:"location flex"},[_c('div',{staticClass:"column1"},[_vm._v("G03-YCFOT")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'GMYCFL',
                    _vm.infoList.GMYCFL_node_string,
                    'GMYCFLVV1',
                    '给煤改变一次风量'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.MCSFENG,
                    'GMYCFL',
                    _vm.infoList.GMYCFL_node_string,
                    'GMYCFLVV1'
                  )}}},[_vm._v(" "+_vm._s("GMYCFL" in _vm.infoList ? _vm.infoList.GMYCFL : 0)+" ")])])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("G03-YCFMLK")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.K1002,
                  'G03-YCFMLK',
                  'K1002',
                  _vm.infoList.K1002_node_string
                )}}},[_vm._v(" "+_vm._s("K1002" in _vm.infoList ? _vm.infoList.K1002 : 0)+" ")])])]),_c('div',{staticClass:"shadow shadow4"},[_c('div',{staticClass:"words"},[_vm._v("一次风床温保护模型")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("G04-K4")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.CWGCYCFK,
                    'G04-K4',
                    'CWGCYCFK',
                    _vm.infoList.CWGCYCFK_node_string
                  )}}},[_vm._v(" "+_vm._s("CWGCYCFK" in _vm.infoList ? _vm.infoList.CWGCYCFK : 0)+" ")])]),_c('div',{staticClass:"location flex"},[_c('div',{staticClass:"column1"},[_vm._v("G04-03")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'CWGCYCF',
                    _vm.infoList.CWGCYCF_node_string,
                    'CWGCYCFVV1',
                    '床温调节一次风输出量'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.CWGCYCF,
                    'CWGCYCF',
                    _vm.infoList.CWGCYCF_node_string,
                    'CWGCYCFVV1'
                  )}}},[_vm._v(" "+_vm._s("CWGCYCF" in _vm.infoList ? _vm.infoList.CWGCYCF : 0)+" ")])])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("G04-HL")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.CWBHYCFH,
                  'G04-HL',
                  'CWBHYCFH',
                  _vm.infoList.CWBHYCFH_node_string
                )}}},[_vm._v(" "+_vm._s("CWBHYCFH" in _vm.infoList ? _vm.infoList.CWBHYCFH : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("G04-RL")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.TC0001,
                    'G04-RL',
                    'TC0001',
                    _vm.infoList.TC0001_node_string
                  )}}},[_vm._v(" "+_vm._s("TC0001" in _vm.infoList ? _vm.infoList.TC0001 : 0)+" ")])]),_c('div',{staticClass:"button2"},[_c('div',{staticClass:"button2_word",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.CWMAX_ZBSEL,
                    '按钮',
                    'CWMAX_ZBSEL',
                    _vm.infoList.CWMAX_ZBSEL_node_string
                  )}}},[_vm._v(" "+_vm._s("CWMAX_ZBSEL" in _vm.infoList ? _vm.infoList.CWMAX_ZBSEL ? "投用" : "切除" : 0)+" ")])])])]),_c('div',{staticClass:"shadow shadow5"},[_c('div',{staticClass:"words"},[_vm._v("快速升降负荷模型")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("G05-MBZL")]),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":String(_vm.infoList.MBFHYCF_SP),"placement":"top"}},[_c('div',{staticClass:"column3 textov",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'MBFHYCF_SP',
                      _vm.infoList.MBFHYCF_SP_node_string,
                      'MBYCF_SPVV1',
                      '一次风升降负荷改变量'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.MBFHYCF_SP,
                      'MBFHYCF_SP',
                      _vm.infoList.MBFHYCF_SP_node_string,
                      'MBYCF_SPVV1'
                    )}}},[_vm._v(" "+_vm._s("MBFHYCF_SP" in _vm.infoList ? _vm.infoList.MBFHYCF_SP : 0)+" ")])])],1),_c('div',{staticClass:"location flex"},[_c('div',{staticClass:"column1"},[_vm._v("G05-02")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'YCF_SJFH',
                    _vm.infoList.YCF_SJFH_node_string,
                    'YCF_SJFHVV1',
                    ''
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.YCF_SJFH,
                    'YCF_SJFH',
                    _vm.infoList.YCF_SJFH_node_string,
                    'YCF_SJFHVV1'
                  )}}},[_vm._v(" "+_vm._s("YCF_SJFH" in _vm.infoList ? _vm.infoList.YCF_SJFH : 0)+" ")])])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("G05-CXSJ")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.SJFH_T,
                  'G04-RL',
                  'SJFH_T',
                  _vm.infoList.SJFH_T_node_string
                )}}},[_vm._v(" "+_vm._s("SJFH_T" in _vm.infoList ? _vm.infoList.SJFH_T : 0)+" ")])])])]),_c('div',{staticClass:"two flex"},[_c('div',{staticClass:"buttonr float1",style:({
            background:
              _vm.infoList.RSF06 && !_vm.infoList.RSF06.SP ? '#2AFC30' : 'red',
          }),on:{"click":function($event){_vm.infoList.RSF06
              ? _vm.toDetail(
                  3,
                  'RSF06',
                  _vm.infoList.RSF06_node_string,
                  '',
                  '床温保护一次风输出'
                )
              : ''}}},[_vm._v(" R ")]),_c('div',[_c('div',{staticClass:"button float2"},[_c('div',{staticClass:"button_word",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.SEL61,
                  '按钮',
                  'SEL61',
                  _vm.infoList.SEL61_node_string
                )}}},[_vm._v(" "+_vm._s("SEL61" in _vm.infoList ? (_vm.infoList.SEL61 ? "投用" : "切除") : 0)+" ")])]),_c('div',{staticClass:"button float3"},[_c('div',{staticClass:"button_word",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.CWMAXYCFSEL,
                  '按钮',
                  'CWMAXYCFSEL',
                  _vm.infoList.CWMAXYCFSEL_node_string
                )}}},[_vm._v(" "+_vm._s("CWMAXYCFSEL" in _vm.infoList ? _vm.infoList.CWMAXYCFSEL ? "投用" : "切除" : 0)+" ")])]),_c('div',{staticClass:"button float4"},[_c('div',{staticClass:"button_word",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.SJFHAUTO,
                  '按钮',
                  'SJFHAUTO',
                  _vm.infoList.SJFHAUTO_node_string
                )}}},[_vm._v(" "+_vm._s("SJFHAUTO" in _vm.infoList ? _vm.infoList.SJFHAUTO ? "投用" : "切除" : 0)+" ")])])]),_c('div',[_c('div',{staticClass:"shadow shadow1"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("G07-TC1")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.YCFTC,
                    'G07-TC1',
                    'YCFTC',
                    _vm.infoList.YCFTC_node_string
                  )}}},[_vm._v(" "+_vm._s("YCFTC" in _vm.infoList ? _vm.infoList.YCFTC : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("G07-SP1")]),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":String(_vm.infoList.FGFSP_B1),"placement":"top"}},[_c('div',{staticClass:"column3 textov",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'FGFSP_B1',
                      _vm.infoList.FGFSP_B1_node_string,
                      'FGFSP_B1VV1',
                      '风量目标值'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.FGFSP_B1,
                      'FGFSP_B1',
                      _vm.infoList.FGFSP_B1_node_string,
                      'FGFSP_B1VV1'
                    )}}},[_vm._v(" "+_vm._s("FGFSP_B1" in _vm.infoList ? _vm.infoList.FGFSP_B1 : 0)+" ")])])],1),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("G07-SPH")]),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":String(_vm.infoList.YCFSPH),"placement":"top"}},[_c('div',{staticClass:"column2 textov",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.YCFSPH,
                      'G07-SPH',
                      'YCFSPH',
                      _vm.infoList.YCFSPH_node_string
                    )}}},[_vm._v(" "+_vm._s("YCFSPH" in _vm.infoList ? _vm.infoList.YCFSPH : 0)+" ")])])],1),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("G07-SPL")]),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":String(_vm.infoList.YCFSPL),"placement":"top"}},[_c('div',{staticClass:"column2 textov",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.YCFSPL,
                      'G07-SPL',
                      'YCFSPL',
                      _vm.infoList.YCFSPL_node_string
                    )}}},[_vm._v(" "+_vm._s("YCFSPL" in _vm.infoList ? _vm.infoList.YCFSPL : 0)+" ")])])],1)]),_c('div',{staticClass:"shadow shadow2 flex"},[_c('div',{staticClass:"column1"},[_vm._v("G07-PV")]),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":String(_vm.infoList.RSF04.PV),"placement":"top"}},[_c('div',{staticClass:"column3 textov",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'PV',
                    _vm.infoList.RSF04_node_string,
                    'RSF04_PVVV1',
                    'RSF04测量值',
                    'RSF04'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.RSF04.PV,
                    'PV',
                    _vm.infoList.RSF04_node_string,
                    'RSF04_PVVV1'
                  )}}},[_vm._v(" "+_vm._s("RSF04" in _vm.infoList ? _vm.infoList.RSF04.PV : 0)+" ")])])],1),_c('div',{staticClass:"shadow shadow3"},[_c('div',{staticClass:"words"},[_vm._v("挡板开度自适应系数")]),_c('div',{staticClass:"left flex"},[_c('div',{staticClass:"column1_one"}),_c('div',{staticClass:"column1_two"}),_c('div',{staticClass:"column1_three",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.YCFDB.IN_FD__ll__1__rr__,
                    '挡板开度自适应系数',
                    'IN_FD__ll__1__rr__',
                    _vm.infoList.YCFDB_node_string
                  )}}},[_vm._v(" "+_vm._s("YCFDB" in _vm.infoList ? _vm.infoList.YCFDB.IN_FD__ll__1__rr__ : 0)+" ")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.YCFDB.AV_FD__ll__1__rr__,
                    '挡板开度自适应系数',
                    'AV_FD__ll__1__rr__',
                    _vm.infoList.YCFDB_node_string
                  )}}},[_vm._v(" "+_vm._s("YCFDB" in _vm.infoList ? _vm.infoList.YCFDB.AV_FD__ll__1__rr__ : 0)+" ")])]),_c('div',{staticClass:"left flex"},[_c('div',{staticClass:"column1_three",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.YCFDB.IN_FD__ll__1__rr__,
                    '挡板开度自适应系数',
                    'IN_FD__ll__1__rr__',
                    _vm.infoList.YCFDB_node_string
                  )}}},[_vm._v(" "+_vm._s("YCFDB" in _vm.infoList ? _vm.infoList.YCFDB.IN_FD__ll__1__rr__ : 0)+" ")]),_c('div',{staticClass:"column1_two"},[_vm._v("-")]),_c('div',{staticClass:"column1_three",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.YCFDB.IN_FD__ll__2__rr__,
                    '挡板开度自适应系数',
                    'IN_FD__ll__2__rr__',
                    _vm.infoList.YCFDB_node_string
                  )}}},[_vm._v(" "+_vm._s("YCFDB" in _vm.infoList ? _vm.infoList.YCFDB.IN_FD__ll__2__rr__ : 0)+" ")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.YCFDB.AV_FD__ll__2__rr__,
                    '挡板开度自适应系数',
                    'AV_FD__ll__2__rr__',
                    _vm.infoList.YCFDB_node_string
                  )}}},[_vm._v(" "+_vm._s("YCFDB" in _vm.infoList ? _vm.infoList.YCFDB.AV_FD__ll__2__rr__ : 0)+" ")])]),_c('div',{staticClass:"left flex"},[_c('div',{staticClass:"column1_three",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.YCFDB.IN_FD__ll__2__rr__,
                    '挡板开度自适应系数',
                    'IN_FD__ll__2__rr__',
                    _vm.infoList.YCFDB_node_string
                  )}}},[_vm._v(" "+_vm._s("YCFDB" in _vm.infoList ? _vm.infoList.YCFDB.IN_FD__ll__2__rr__ : 0)+" ")]),_c('div',{staticClass:"column1_two"},[_vm._v("-")]),_c('div',{staticClass:"column1_three",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.YCFDB.IN_FD__ll__3__rr__,
                    '挡板开度自适应系数',
                    'IN_FD__ll__3__rr__',
                    _vm.infoList.YCFDB_node_string
                  )}}},[_vm._v(" "+_vm._s("YCFDB" in _vm.infoList ? _vm.infoList.YCFDB.IN_FD__ll__3__rr__ : 0)+" ")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.YCFDB.AV_FD__ll__3__rr__,
                    '挡板开度自适应系数',
                    'AV_FD__ll__3__rr__',
                    _vm.infoList.YCFDB_node_string
                  )}}},[_vm._v(" "+_vm._s("YCFDB" in _vm.infoList ? _vm.infoList.YCFDB.AV_FD__ll__3__rr__ : 0)+" ")])]),_c('div',{staticClass:"left flex"},[_c('div',{staticClass:"column1_three",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.YCFDB.IN_FD__ll__3__rr__,
                    '挡板开度自适应系数',
                    'IN_FD__ll__3__rr__',
                    _vm.infoList.YCFDB_node_string
                  )}}},[_vm._v(" "+_vm._s("YCFDB" in _vm.infoList ? _vm.infoList.YCFDB.IN_FD__ll__3__rr__ : 0)+" ")]),_c('div',{staticClass:"column1_two"},[_vm._v("-")]),_c('div',{staticClass:"column1_three",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.YCFDB.IN_FD__ll__4__rr__,
                    '挡板开度自适应系数',
                    'IN_FD__ll__4__rr__',
                    _vm.infoList.YCFDB_node_string
                  )}}},[_vm._v(" "+_vm._s("YCFDB" in _vm.infoList ? _vm.infoList.YCFDB.IN_FD__ll__4__rr__ : 0)+" ")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.YCFDB.AV_FD__ll__4__rr__,
                    '挡板开度自适应系数',
                    'AV_FD__ll__4__rr__',
                    _vm.infoList.YCFDB_node_string
                  )}}},[_vm._v(" "+_vm._s("YCFDB" in _vm.infoList ? _vm.infoList.YCFDB.AV_FD__ll__4__rr__ : 0)+" ")])]),_c('div',{staticClass:"left flex"},[_c('div',{staticClass:"column1_one"},[_vm._v(">")]),_c('div',{staticClass:"column1_two"}),_c('div',{staticClass:"column1_three",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.YCFDB.IN_FD__ll__4__rr__,
                    '挡板开度自适应系数',
                    'IN_FD__ll__4__rr__',
                    _vm.infoList.YCFDB_node_string
                  )}}},[_vm._v(" "+_vm._s("YCFDB" in _vm.infoList ? _vm.infoList.YCFDB.IN_FD__ll__4__rr__ : 0)+" ")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.YCFDB.AV_FD__ll__5__rr__,
                    '挡板开度自适应系数',
                    'AV_FD__ll__5__rr__',
                    _vm.infoList.YCFDB_node_string
                  )}}},[_vm._v(" "+_vm._s("YCFDB" in _vm.infoList ? _vm.infoList.YCFDB.AV_FD__ll__5__rr__ : 0)+" ")])])])]),_c('div',[_c('div',{staticClass:"buttonr float5",style:({
              background:
                _vm.infoList.RSF04 && !_vm.infoList.RSF04.SP ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.RSF04
                ? _vm.toDetail(
                    3,
                    'RSF04',
                    _vm.infoList.RSF04_node_string,
                    '',
                    '一次风变频软伺服'
                  )
                : ''}}},[_vm._v(" R ")]),_c('div',{staticClass:"buttonr float6",style:({
              background:
                _vm.infoList.RSF05 && !_vm.infoList.RSF05.SP ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.RSF05
                ? _vm.toDetail(
                    3,
                    'RSF05',
                    _vm.infoList.RSF05_node_string,
                    '',
                    '一次风挡板软伺服'
                  )
                : ''}}},[_vm._v(" R ")])]),_c('div',{staticClass:"buttonr float7",style:({
            background:
              _vm.infoList.RSF08 && !_vm.infoList.RSF08.SP ? '#2AFC30' : 'red',
          }),on:{"click":function($event){_vm.infoList.RSF08
              ? _vm.toDetail(
                  3,
                  'RSF08',
                  _vm.infoList.RSF08_node_string,
                  '',
                  '一次风机电流纠偏'
                )
              : ''}}},[_vm._v(" R ")]),_c('div',[_c('div',{staticClass:"PZ1"},[_vm._v("PZ1")]),_c('div',{staticClass:"PZ2"},[_vm._v("PZ2")]),_c('div',{staticClass:"button1"},[_c('div',{staticClass:"button1_word",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.RSF08SEL,
                  '按钮',
                  'RSF08SEL',
                  _vm.infoList.RSF08SEL_node_string
                )}}},[_vm._v(" "+_vm._s("RSF08SEL" in _vm.infoList ? _vm.infoList.RSF08SEL ? "电流纠偏投用" : "电流纠偏切除" : 0)+" ")])]),_c('div',{staticClass:"shadow shadow4 flex"},[_c('div',{staticClass:"column1"},[_vm._v("F03-DLTC")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.YCF_LB,
                  'F03-DLTC',
                  'YCF_LB',
                  _vm.infoList.YCF_LB_node_string
                )}}},[_vm._v(" "+_vm._s("YCF_LB" in _vm.infoList ? _vm.infoList.YCF_LB : 0)+" ")])]),_c('div',{staticClass:"PZ3"},[_vm._v("PZ3")]),_c('div',{staticClass:"PZ4"},[_vm._v("PZ4")])])]),_c('div',{staticClass:"three"},[_c('div',{staticClass:"shadow shadow1 float1 flex"},[_c('div',{staticClass:"column1"},[_vm._v("G08-A01")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                2,
                'KF_ECFBP_B',
                _vm.infoList.KF_ECFBP_B_node_string,
                'KF_ECFBP_BVV1',
                '二次风机变频反馈'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.KF_ECFBP_B,
                'KF_ECFBP_B',
                _vm.infoList.KF_ECFBP_B_node_string,
                'KF_ECFBP_BVV1'
              )}}},[_vm._v(" "+_vm._s("KF_ECFBP_B" in _vm.infoList ? _vm.infoList.KF_ECFBP_B : 0)+" ")])]),_c('div',{staticClass:"shadow shadow1 float2 flex"},[_c('div',{staticClass:"column1"},[_vm._v("G08-A02")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                2,
                'KF_ECFBP2_B',
                _vm.infoList.KF_ECFBP2_B_node_string,
                'KF_ECFBP2_BVV1',
                '2#二次风变频反馈'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.KF_ECFBP2_B,
                'KF_ECFBP2_B',
                _vm.infoList.KF_ECFBP2_B_node_string,
                'KF_ECFBP2_BVV1'
              )}}},[_vm._v(" "+_vm._s("KF_ECFBP2_B" in _vm.infoList ? _vm.infoList.KF_ECFBP2_B : 0)+" ")])]),_c('div',{staticClass:"buttonx float3",style:({
            background:
              _vm.infoList.MAN15.RM == 1 ||
              _vm.infoList.MAN16.RM == 1 ||
              _vm.infoList.MAN25.RM == 1 ||
              _vm.infoList.MAN27.RM == 1
                ? '#2AFC30'
                : 'red',
          }),on:{"click":function($event){return _vm.toCompon(0, 'CFB_MANYCF', 4)}}},[_vm._v(" A ")]),_c('div',{staticClass:"shadow shadow1 float4 flex"},[_c('div',{staticClass:"column1"},[_vm._v("G09-A01")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                2,
                'KF_ECFDB_B',
                _vm.infoList.KF_ECFDB_B_node_string,
                'KF_ECFDB_BVV1',
                '二次风机挡板反馈'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.KF_ECFDB_B,
                'KF_ECFDB_B',
                _vm.infoList.KF_ECFDB_B_node_string,
                'KF_ECFDB_BVV1'
              )}}},[_vm._v(" "+_vm._s("KF_ECFDB_B" in _vm.infoList ? _vm.infoList.KF_ECFDB_B : 0)+" ")])]),_c('div',{staticClass:"shadow shadow1 float5 flex"},[_c('div',{staticClass:"column1"},[_vm._v("G09-A02")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                2,
                'KF_ECFDB2_B',
                _vm.infoList.KF_ECFDB2_B_node_string,
                'KF_ECFDB2_BVV1',
                '2#二次风挡板反馈'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.KF_ECFDB2_B,
                'KF_ECFDB2_B',
                _vm.infoList.KF_ECFDB2_B_node_string,
                'KF_ECFDB2_BVV1'
              )}}},[_vm._v(" "+_vm._s("KF_ECFDB2_B" in _vm.infoList ? _vm.infoList.KF_ECFDB2_B : 0)+" ")])])])]),_vm._m(1)]),_c('inputVal',{ref:"inputVal"}),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.node,"node1":_vm.node1,"Lkname":_vm.Lkname,"chName":_vm.chName,"infoList":_vm.infoList},on:{"sendStatus":function($event){_vm.isHshow = false}}}):_vm._e(),(_vm.manyManual)?_c('manyManual',{attrs:{"titname":_vm.Manualtitname,"numM":_vm.Manualnode,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.AirSoft)?_c('AirSoft',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"main_title"},[_vm._v("一次风优化控制模型")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"red"},[_c('div',[_vm._v("煤量一次风系数必须设置，否则影响快速升降负荷一次风改变量")])])}]

export { render, staticRenderFns }