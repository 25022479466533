<template>
  <div class="total drag" v-draw id="CfbParameter2">
    <!-- 抬头标题 -->
    <div class="flex">
      <div class="title-hang flex">
        <div class="title">{{ projectData.project}}_参数02_111</div>
        <div class="icon" @click="closeCompon"></div>
      </div>
    </div>
    <!-- 标题 -->
    <div class="main">
      <div class="main_title">主汽温度控制模型（两级减温）</div>
    </div>
    <!-- 主体内容 -->
    <div class="context flex">
      <div class="one">
        <div class="shadow shadow1">
          <div class="flex">
            <div class="column1">B05-SP</div>
            <div class="column2">0.0</div>
          </div>
          <div class="flex">
            <div class="column1">B05-PV</div>
            <div class="column3">0.0</div>
          </div>
        </div>
        <div class="shadow1-words">水煤比前馈</div>
      </div>
      <div class="two">
        <div class="bg1">
          <div class="flex">
            <div class="buttonr float1">R</div>
            <div class="button float2">
              <div class="button_word">切除</div>
            </div>
          </div>
          <div class="flex">
            <div
              class="buttonr float3"
              :style="{
                background:
                  infoList.QK01 && !infoList.QK01.TS
                    ? '#2AFC30'
                    : 'red',
              }"
              @click="
                infoList.QK01
                  ? toDetail(2, 'QK01', infoList.QK01_node_string, '', '电负荷前馈')
                  : ''
              "
            >
              K
            </div>
            <div class="button float4">
              <div
                class="button_word"
                @click="toIpt(infoList.SEL22, '按钮', 'SEL22', infoList.SEL22_node_string)"
              >
                {{ "SEL22" in infoList ?infoList.SEL22 ? "投用" : "切除" :0 }}
              </div>
            </div>
          </div>
          <!-- <div class="buttonx float5">A</div> -->
        </div>
      </div>
      <div class="three">
        <div class="shadow shadow2 float1">
          <div class="flex">
            <div class="column1">B01-SP</div>
            <div
              class="column2"
              @click="
                toIpt(
                  infoList.ZQTSP11,
                  'B01-SP',
                  'ZQTSP11',
                  infoList.ZQTSP11_node_string
                )
              "
            >
              {{ "ZQTSP11" in infoList ?infoList.ZQTSP11 :0 }}
            </div>
          </div>
          <div class="flex">
            <div class="column1">B01-PV</div>
            <div
              class="column3"
              @click="toCompon(2, 'QWSJPV1', infoList.QWSJPV1_node_string, 'QWSJPV1VV1')"
              @dblclick="
                Cclick(
                  infoList.QWSJPV1,
                  'QWSJPV1',
                  infoList.QWSJPV1_node_string,
                  'QWSJPV1VV1'
                )
              "
            >
              {{ "QWSJPV1" in infoList ?infoList.QWSJPV1 :0 }}
            </div>
          </div>
          <div class="flex">
            <div class="column1">B01-TC</div>
            <div
              class="column2"
              @click="
                toIpt(infoList.QWTC1, 'B01-TC', 'QWTC1', infoList.QWTC1_node_string)
              "
            >
              {{ "QWTC1" in infoList ?infoList.QWTC1 :0 }}
            </div>
          </div>
        </div>
        <div class="shadow shadow2 float2">
          <div class="flex">
            <div class="column1">B02-SP</div>
            <div
              class="column2"
              @click="
                toIpt(
                  infoList.ZQTSP12,
                  'B02-SP',
                  'ZQTSP12',
                  infoList.ZQTSP12_node_string
                )
              "
            >
              {{ "ZQTSP12" in infoList ?infoList.ZQTSP12 :0 }}
            </div>
          </div>
          <div class="flex">
            <div class="column1">B02-PV</div>
            <div
              class="column3"
              @click="toCompon(2, 'QWSJPV2', infoList.QWSJPV2_node_string, 'QWSJPV2VV1')"
              @dblclick="
                Cclick(
                  infoList.QWSJPV2,
                  'QWSJPV2',
                  infoList.QWSJPV2_node_string,
                  'QWSJPV2VV1'
                )
              "
            >
              {{ "QWSJPV2" in infoList ?infoList.QWSJPV2 :0 }}
            </div>
          </div>
          <div class="flex">
            <div class="column1">B02-TC</div>
            <div
              class="column2"
              @click="
                toIpt(infoList.QWTC2, 'B02-TC', 'QWTC2', infoList.QWTC2_node_string)
              "
            >
              {{ "QWTC2" in infoList ?infoList.QWTC2 :0 }}
            </div>
          </div>
        </div>
        <div class="shadow shadow2 float3">
          <div class="flex">
            <div class="column1">B03-SP</div>
            <div
              class="column2"
              @click="
                toIpt(
                  infoList.ZQTSP_B1,
                  'B03-TC',
                  'ZQTSP_B1',
				  infoList.ZQTSP_B1_node_string
                )
              "
            >
              {{ "ZQTSP_B1" in infoList ?infoList.ZQTSP_B1 :0 }}
            </div>
          </div>
          <div class="flex">
            <div class="column1">B03-PV</div>
            <div
              class="column3"
              @click="toCompon(2, 'QWSJPV3', infoList.QWSJPV3_node_string, 'QWSJPV3VV1')"
              @dblclick="
                Cclick(
                  infoList.QWSJPV3,
                  'QWSJPV3',
                 infoList.QWSJPV3_node_string,
                  'QWSJPV3VV1'
                )
              "
            >
              {{ "QWSJPV3" in infoList ?infoList.QWSJPV3 :0 }}
            </div>
          </div>
          <div class="flex">
            <div class="column1">B03-TC</div>
            <div
              class="column2"
              @click="
                toIpt(infoList.QWTC3, 'B03-TC', 'QWTC3', infoList.QWTC3_node_string)
              "
            >
              {{ "QWTC3" in infoList ?infoList.QWTC3 :0 }}
            </div>
          </div>
        </div>
        <div class="shadow shadow2 float4">
          <div class="flex">
            <div class="column1">B04-SP</div>
            <div
              class="column2"
              @click="
                toIpt(
                  infoList.ZQTSP_B1,
                  'B04-SP',
                  'ZQTSP_B1',
				  infoList.ZQTSP_B1_node_string
                )
              "
            >
              {{ "ZQTSP_B1" in infoList ?infoList.ZQTSP_B1 :0 }}
            </div>
          </div>
          <div class="flex">
            <div class="column1">B04-PV</div>
            <div
              class="column3"
              @click="toCompon(2, 'QWSJPV4', infoList.QWSJPV4_node_string, 'QWSJPV4VV1')"
              @dblclick="
                Cclick(
                  infoList.QWSJPV4,
                  'QWSJPV4',
				  infoList.QWSJPV4_node_string,
                  'QWSJPV4VV1'
                )
              "
            >
              {{ "QWSJPV4" in infoList ?infoList.QWSJPV4 :0 }}
            </div>
          </div>
          <div class="flex">
            <div class="column1">B04-TC</div>
            <div
              class="column2"
              @click="
                toIpt(infoList.QWTC4, 'B04-TC', 'QWTC4', infoList.QWTC4_node_string)
              "
            >
              {{ "QWTC4" in infoList ?infoList.QWTC4 :0 }}
            </div>
          </div>
        </div>
      </div>
      <div class="four">
        <div class="bg2">
          <div>
            <div
              class="buttonx float1"
              :style="{
                background:
                  infoList.XK0I && !infoList.XK0I.SP
                    ? '#2AFC30'
                    : 'red',
              }"
              @click="
                infoList.XK0I
                  ? toDetail(
                      1,
                      'XK0I',
					  infoList.XK0I_node_string,
                      '',
                      '一级左主汽温度调节'
                    )
                  : ''
              "
            >
              X
            </div>
            <div class="flex">
              <div
                class="buttonr float2"
                :style="{
                  background:
                    infoList.QK08 && !infoList.QK08.TS
                      ? '#2AFC30'
                      : 'red',
                }"
                @click="
                  infoList.QK08
                    ? toDetail(2, 'QK08', infoList.QK08_node_string, '', 'QK08设定值')
                    : ''
                "
              >
                K
              </div>
              <div class="button float3">
                <div
                  class="button_word"
                  @click="
                    toIpt(infoList.QK08SEL, '按钮', 'QK08SEL', infoList.QK08SEL_node_string)
                  "
                >
                  {{ "QK08SEL" in infoList ?infoList.QK08SEL ? "投用" : "切除" :0 }}
                </div>
              </div>
            </div>
          </div>
          <div class="float4">
            <div
              class="buttonx float1"
              :style="{
                background:
                  infoList.XK0J && !infoList.XK0J.SP
                    ? '#2AFC30'
                    : 'red',
              }"
              @click="
                infoList.XK0J
                  ? toDetail(
                      1,
                      'XK0J',
                      infoList.XK0J_node_string,
                      '',
                      '一级右主汽温度调节'
                    )
                  : ''
              "
            >
              X
            </div>
            <div class="flex">
              <div
                class="buttonr float2"
                :style="{
                  background:
                    infoList.QK09 && !infoList.QK09.TS
                      ? '#2AFC30'
                      : 'red',
                }"
                @click="
                  infoList.QK09
                    ? toDetail(2, 'QK09',infoList.QK09_node_string, '', 'QK09设定值')
                    : ''
                "
              >
                K
              </div>
              <div class="button float3">
                <div
                  class="button_word"
                  @click="
                    toIpt(infoList.QK09SEL, '按钮', 'QK09SEL', infoList.QK09SEL_node_string)
                  "
                >
                  {{ "QK09SEL" in infoList ?infoList.QK09SEL ? "投用" : "切除" :0 }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bg2 float5">
          <div>
            <div
              class="buttonx float1"
              :style="{
                background:
                  infoList.XK07 && !infoList.XK07.SP
                    ? '#2AFC30'
                    : 'red',
              }"
              @click="
                infoList.XK07
                  ? toDetail(
                      1,
                      'XK07',
                      infoList.XK07_node_string,
                      '',
                      '二级左主汽温度调节'
                    )
                  : ''
              "
            >
              X
            </div>
            <div class="flex">
              <div
                class="buttonr float2"
                :style="{
                  background:
                    infoList.QK05 && !infoList.QK05.TS
                      ? '#2AFC30'
                      : 'red',
                }"
                @click="
                  infoList.QK05
                    ? toDetail(2, 'QK05', infoList.QK05_node_string, '', 'QK05设定值')
                    : ''
                "
              >
                K
              </div>
              <div class="button float3">
                <div
                  class="button_word"
                  @click="
                    toIpt(infoList.QK02SEL, '按钮', 'QK02SEL', infoList.QK02SEL_node_string)
                  "
                >
                  {{ "QK02SEL" in infoList ?infoList.QK02SEL ? "投用" : "切除" :0 }}
                </div>
              </div>
            </div>
          </div>
          <div class="float4">
            <div
              class="buttonx float1"
              :style="{
                background:
                  infoList.XK08 && !infoList.XK08.SP
                    ? '#2AFC30'
                    : 'red',
              }"
              @click="
                infoList.XK08
                  ? toDetail(
                      1,
                      'XK08',
                      infoList.XK08_node_string,
                      '',
                      '二级右主汽温度调节'
                    )
                  : ''
              "
            >
              X
            </div>
            <div class="flex">
              <div
                class="buttonr float2"
                :style="{
                  background:
                    infoList.QK03 && !infoList.QK03.TS
                      ? '#2AFC30'
                      : 'red',
                }"
                @click="
                  infoList.QK03
                    ? toDetail(2, 'QK03', infoList.QK03_node_string, '', 'QK03设定值')
                    : ''
                "
              >
                K
              </div>
              <div class="button float3">
                <div
                  class="button_word"
                  @click="
                    toIpt(infoList.QK03SEL, '按钮', 'QK03SEL', infoList.QK03SEL_node_string)
                  "
                >
                  {{ "QK03SEL" in infoList ?infoList.QK03SEL ? "投用" : "切除" :0 }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="five">
        <div class="shadow shadow3 float1 flex">
          <div class="column1">B01-A01</div>
          <div
            class="column3"
            @click="
              toCompon(2, 'ZQAO1_B', infoList.ZQAO1_B_node_string, 'ZQAO1_BVV1', '1#减温水阀位输出')
            "
            @dblclick="
              Cclick(infoList.ZQAO1_B, 'ZQAO1_B', infoList.ZQAO1_B_node_string, 'ZQAO1_BVV1')
            "
          >
            {{ "ZQAO1_B" in infoList ?infoList.ZQAO1_B :0 }}
          </div>
        </div>
        <div class="shadow shadow3 float2 flex">
          <div class="column1">B02-A02</div>
          <div
            class="column3"
            @click="
              toCompon(2, 'ZQAO2_B', infoList.ZQAO2_B_node_string, 'ZQAO2_BVV1', '2#减温水阀位输出')
            "
            @dblclick="
              Cclick(infoList.ZQAO2_B, 'ZQAO2_B', infoList.ZQAO2_B_node_string, 'ZQAO2_BVV1')
            "
          >
            {{ "ZQAO2_B" in infoList ?infoList.ZQAO2_B :0 }}
          </div>
        </div>
        <div class="shadow shadow3 float3 flex">
          <div class="column1">B03-A03</div>
          <div
            class="column3"
            @click="
              toCompon(2, 'ZQAO3_B', infoList.ZQAO3_B_node_string, 'ZQAO3_BVV1', '3#减温水阀位输出')
            "
            @dblclick="
              Cclick(infoList.ZQAO3_B, 'ZQAO3_B', infoList.ZQAO3_B_node_string, 'ZQAO3_BVV1')
            "
          >
            {{ "ZQAO3_B" in infoList ?infoList.ZQAO3_B :0 }}
          </div>
        </div>
        <div class="shadow shadow3 float4 flex">
          <div class="column1">B04-A04</div>
          <div
            class="column3"
            @click="
              toCompon(2, 'ZQAO4_B', infoList.ZQAO4_B_node_string, 'ZQAO4_BVV1', '4#减温水阀位输出')
            "
            @dblclick="
              Cclick(infoList.ZQAO4_B, 'ZQAO4_B', infoList.ZQAO4_B_node_string, 'ZQAO4_BVV1')
            "
          >
            {{ "ZQAO4_B" in infoList ?infoList.ZQAO4_B :0 }}
          </div>
        </div>
      </div>
    </div>
    <inputVal ref="inputVal"></inputVal>
    <Historical
      v-if="isHshow"
      @sendStatus="isHshow = false"
      :historyname="historyname"
      :node="node"
      :Lkname="Lkname"
      :chName="chName"
      :infoList="infoList"
    ></Historical>
    <Firstcontrol
      :infoList="infoList"
      :isIndex="isIndex"
      :historyname="Firstcontrolname"
      :titname="Firstcontroltitname"
      :node="Firstcontrolnode"
      v-if="isFshow"
      @sendStatus="isFshow = false"
    >
    </Firstcontrol>
    <Rsf
      v-if="isRshow"
      @sendStatus="isRshow = false"
      :titname="Rsftitname"
      :infoList="infoList"
      :historyname="Rsfname"
      :node="Rsfnode"
    ></Rsf>
    <ParameterYh
      v-if="isComShow"
      :infoList="infoList"
      @sendStatus="isshowfase"
    ></ParameterYh>
    <Manual
      :key="isIndex"
      v-if="isMshow"
      @sendStatus="isMshow = false"
      :historyname="Manualname"
      :node="Manualnode"
      :Lkname="ManualAname"
      :titname="Manualtitname"
      :infoList="infoList"
    >
    </Manual>
  </div>
</template>

<script>
import inputVal from "@/components/inputVal.vue"; //输入框组件
import Historical from "@/components/Historicaljrl.vue"; //历史趋势
import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
import Rsf from "@/components/RSF.vue"; //阮伺服组件
import ParameterYh from "@/components/ParameterYh.vue"; //参数YH组件
import Manual from "@/components/Manual1.vue"; //手操器组件
export default {
  name: "CfbParameter3",
  props: {
    infoList: {
      type: Object,
      default: () => {
        return {}; // 默认值
      },
    },
  },
  components: {
    inputVal,
    Historical,
    Firstcontrol,
    Rsf,
    ParameterYh,
    Manual,
  },
  data: () => {
    return {
      chName: "",
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      isComShow: false,
      isHshow: false,
      isMshow: false,
      isRshow: false,
      historyname: "",
      node: "",
      Lkname: "",
      isIndex: "",
      Firstcontrolname: "",
      Firstcontroltitname: "",
      Firstcontrolnode: "",
      isFshow: false,
      Rsfname: "",
      Rsfnode: "",
      Rsftitname: "",
      projectData: "",
      grouptime: null,
      spotArr: [],
      authInfo: [],
      UserInfo: [],
    };
  },
  watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
        console.log(this.infoList);
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  created() {
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
    this.authInfo = JSON.parse(localStorage.getItem("autharr"));
    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
  },
  methods: {
    // 打开先控多窗口
    toDetail(key, name, nodename, Aname, titname) {
      if (!this.authInfo[1]) {
        let str = this.authInfo[0];
        // 判断是否为调试还是查看权限
        if (str.charAt(str.length - 1) == "r") {
          this.$message.error("暂无调试权限");
        }
      } else {
        let pathname = "";
        switch (key) {
          case 1:
            pathname = "firstcontolJRL";
            break;
          case 2:
            pathname = "Cfbkfirstcontol";
            break;
          case 3:
            pathname = "CfbRfirstcontol";
            break;
        }
        const newurl = this.$router.resolve({
          name: pathname,
          query: {
            Firstcontrolname: name,
            Firstcontrolnode: nodename,
            Firstcontroltitname: titname,
          },
        });
        window.open(newurl.href, "_blank");
      }
    },
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }

        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
    isshowfase() {
      this.isComShow = false;
    },
    closeCompon() {
      this.$emit("sendStatus", "Parameter02", false);
    },
    isClose(val, val1) {
      switch (val) {
        case "Historical":
          return (this.isHshow = val1);
      }
    },
    toIpt(data, name, historyname, node) {
      this.$refs.inputVal.open(data, name, historyname, node);
    },
    toCompon(key, name, name2, name3, name4, titname) {
      this.isIndex = key;
      this.historyname = name;
      this.node = name2;
      this.Lkname = name3;
      clearTimeout(this.grouptime);
      this.grouptime = setTimeout(() => {
        switch (key) {
          case 0:
            console.log(1);
            return (
              (this.isMshow = true),
              (this.Manualname = name),
              (this.Manualnode = name2),
              (this.ManualAname = name3),
              (this.Manualtitname = titname)
            );
          case 1:
            return (
              (this.isFshow = true),
              (this.Firstcontrolname = name),
              (this.Firstcontrolnode = name2),
              (this.Firstcontroltitname = titname)
            );
          case 2:
            return (this.isHshow = true), (this.chName = name4);
          case 4:
            return (
              (this.isRshow = true),
              (this.Rsfname = name),
              (this.Rsfnode = name2),
              (this.Rsftitname = titname)
            );
        }
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
#CfbParameter2 {
  width: 53vw;
  height: 67vh;
  background-image: url("~@/assets/images/anquanshezhiditu.png");
  background-size: 100% 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -33.5vh 0 0 -26.5vw;
  z-index: 999;

  .title-hang {
    width: 53vw;
    height: 5vh;
    font-family: MicrosoftYaHei;
    font-size: 2vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vh;
    letter-spacing: 0vh;
    margin-left: 15vw;
  }

  .title {
    width: 19vw;
    height: 4vh;
    font-family: MicrosoftYaHei;
    font-size: 2vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 4vh;
    letter-spacing: 0vh;
    color: #0ef7ff;
    margin-top: 1vh;
    text-align: center;
    margin-left: 1.5vw;
  }

  .icon {
    width: 1.8vw;
    height: 3.8vh;
    background-image: url("~@/assets/images/RqBoiler/icon_close1.png");
    background-size: 100% 100%;
    margin-left: 15vw;
    margin-top: 1.8vh;
    cursor: pointer;
  }

  .main {
    width: 53vw;
    height: 2vh;
    margin-top: 3vh;

    .main_title {
      font-family: PingFang-SC-Regular;
      font-size: 3vh;
      font-weight: normal;
      font-stretch: normal;
      line-height: 2vh;
      letter-spacing: 1vh;
      color: #d5fffe;
      text-align: center;
    }
  }

  .context {
    width: 39vw;
    height: 39vh;
    margin-top: 7vh;
    margin-left: 7vw;
    font-family: PingFang-SC-Regular;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0vw;

    .shadow {
      width: 7vw;
      background-color: #001b2860;
      box-shadow: 0vw 0vw 0vw 0vw #15384c;
      border-radius: 1px;
      border: solid 1px #236f8d;
      font-size: 1.8vh;
      line-height: 2.8vh;

      .column1 {
        width: 4vw;
        color: #8aeaff;
        margin-left: 0.3vw;
      }

      .column2 {
        width: 2vw;
        color: #00ffb4;
        text-align: right;
		cursor: pointer;
      }

      .column3 {
        width: 2vw;
        color: #00e4ff;
        text-align: right;
		cursor: pointer;
      }
    }

    .buttonx {
      width: 1.3vw;
      height: 2.2vh;
      border: 2px solid rgb(217, 243, 145);
      text-align: center;
      line-height: 2vh;
      background-color: #00e4ff;
      color: white;
    }

    .buttonr {
      width: 1.3vw;
      height: 2.2vh;
      border: 2px solid rgb(217, 243, 145);
      text-align: center;
      line-height: 2vh;
      background-color: red;
      color: white;
	  cursor: pointer;
    }

    .button {
      width: 3vw;
      height: 2vh;
      background-color: #22fff7;
      border-radius: 0vh;
      font-family: PingFang-SC-Regular;
      font-size: 1vh;
      font-weight: normal;
      font-stretch: normal;
      line-height: 2vh;
      letter-spacing: 0vh;

      .button_word {
        width: 3vw;
        text-align: center;
        color: #0a4c62;
      }
    }

    .one {
      .shadow1 {
        height: 6vh;
        margin-top: 0vh;
        margin-left: 0vw;
      }

      .shadow1-words {
        color: #00e4ff;
        margin-top: 4.2vh;
        margin-left: 2.8vw;
      }
    }

    .two {
      .bg1 {
        width: 9vw;
        height: 15vh;
        background-image: url("~@/assets/images/CfbBoiler/para021(1).png");
        background-size: 100% 100%;
        margin-top: 1vh;

        .float1 {
          margin-left: 1vw;
          margin-top: -0.8vh;
        }

        .float2 {
          margin-left: 0.3vw;
          margin-top: 1.5vh;
        }

        .float3 {
          margin-left: 1vw;
          margin-top: 6vh;
        }

        .float4 {
          margin-left: 0.3vw;
          margin-top: 6.2vh;
        }

        .float5 {
          margin-left: 6.5vw;
          margin-top: 16vh;
        }
      }
    }

    .three {
      .shadow2 {
        height: 8vh;
        margin-top: 0vh;
        margin-left: 0vw;
      }

      .float2 {
        margin-top: 0.6vh;
      }

      .float3 {
        margin-top: 4.5vh;
      }

      .float4 {
        margin-top: 0.6vh;
      }
    }

    .four {
      .bg2 {
        width: 9vw;
        height: 15vh;
        background-image: url("~@/assets/images/CfbBoiler/para021(2).png");
        background-size: 100% 100%;
        padding: 0.1vw;

        .float1 {
          margin-top: -0.5vh;
          margin-left: 1.2vw;
        }

        .float2 {
          margin-top: 2vh;
          margin-left: 1.2vw;
        }

        .float3 {
          margin-top: 0vh;
          margin-left: 0.2vw;
        }

        .float4 {
          margin-top: 3vh;
        }
      }

      .float5 {
        margin-top: 5.8vh;
      }
    }

    .five {
      .shadow3 {
        height: 3vh;
      }

      .float1 {
        margin-top: 0.5vh;
        margin-left: 0vw;
      }

      .float2 {
        margin-top: 5vh;
        margin-left: 0vw;
      }

      .float3 {
        margin-top: 9.5vh;
        margin-left: 0vw;
      }

      .float4 {
        margin-top: 5.5vh;
        margin-left: 0vw;
      }
    }
  }
}
</style>
