<template>
  <div class="total">
    <div class="maskbok" v-if="maskbok"></div>
    <!-- 抬头标题 -->
    <div class="title-hang">
      <div class="fl-t">
        <dv-decoration-1 style="width: 14vw; height: 3vh; margin-top: 1vh" />
      </div>
      <!-- <div class="zhuangshizb fl-t"></div> -->
      <div class="zhuangshizj fl-t">
        {{ projectData.project }}
        <!-- _CFB优化控制系统 -->
      </div>
      <div class="fr-t">
        <div class="fl-t">
          <dv-decoration-1 style="width: 14vw; height: 3vh; margin-top: 1vh" />
        </div>
      </div>
    </div>
    <!-- 主要内容 -->
    <div class="part1 flex">
      <div class="float1">
        <div class="words">锅炉负荷</div>
        <div
          class="num1"
          @click="
            toCompon(
              2,
              'BYMNL59',
              infoList.BYMNL59_node_string,
              'BYMNL59VV1',
              '主蒸汽流量'
            )
          "
          @dblclick="
            Cclick(
              infoList.BYMNL59,
              'BYMNL59',
              infoList.BYMNL59_node_string,
              'BYMNL59VV1'
            )
          "
        >
          {{ "BYMNL59" in infoList ? infoList.BYMNL59 : 0 }}&nbsp;t/h
        </div>
      </div>
      <div class="float2">
        <div class="words">汽压控制点</div>
        <div
          class="num2"
          @click="
            toIpt(
              infoList.XTPR,
              '汽压控制点',
              'XTPR',
              infoList.XTPR_node_string
            )
          "
        >
          {{ "XTPR" in infoList ? infoList.XTPR : 0 }}&nbsp;MPa
        </div>
      </div>
      <div class="float3">
        <div class="words">汽温控制点</div>
        <div
          class="num2"
          @click="
            toIpt(
              infoList.ZQTSP_B1,
              '汽温控制点',
              'ZQTSP_B1',
              infoList.ZQTSP_B1_node_string
            )
          "
        >
          {{ "ZQTSP_B1" in infoList ? infoList.ZQTSP_B1 : 0 }}&nbsp;℃
        </div>
      </div>
      <div class="float4">
        <div class="words">水位控制点</div>
        <div
          class="num2"
          @click="
            toIpt(
              infoList.SWSP_B1,
              '水位控制点',
              'SWSP_B1',
              infoList.SWSP_B1_node_string
            )
          "
        >
          {{ "SWSP_B1" in infoList ? infoList.SWSP_B1 : 0 }}&nbsp;mmm
        </div>
      </div>
    </div>
    <div class="part2 flex">
      <div class="left">
        <div class="block1 flex">
          <div class="float1">
            <div
              class="num1"
              @click="
                toCompon(
                  2,
                  'FT102D_B',
                  infoList.FT102D_B_node_string,
                  'FT102D_BVV1',
                  '1#主蒸汽流量'
                )
              "
              @dblclick="
                Cclick(
                  infoList.FT102D_B,
                  'FT102D_B',
                  infoList.FT102D_B_node_string,
                  'FT102D_BVV1'
                )
              "
            >
              {{ "FT102D_B" in infoList ? infoList.FT102D_B : 0 }}&nbsp;t/h
            </div>
          </div>
          <div class="float2">
            <div
              class="num1"
              @click="
                toCompon(
                  2,
                  'BYMNL39',
                  infoList.BYMNL39_node_string,
                  'BYMNL39VV1',
                  '主蒸汽压力平均值'
                )
              "
              @dblclick="
                Cclick(
                  infoList.BYMNL39,
                  'BYMNL39',
                  infoList.BYMNL39_node_string,
                  'BYMNL39VV1'
                )
              "
            >
              {{ "BYMNL39" in infoList ? infoList.BYMNL39 : 0 }}&nbsp;MPa
            </div>
          </div>
          <div class="float3">
            <div
              class="num1"
              @click="
                toCompon(
                  2,
                  'PT103D_B',
                  infoList.PT103D_B_node_string,
                  'PT103D_BVV1',
                  '一级主蒸汽压力'
                )
              "
              @dblclick="
                Cclick(
                  infoList.PT103D_B,
                  'PT103D_B',
                  infoList.PT103D_B_node_string,
                  'PT103D_BVV1'
                )
              "
            >
              {{ "PT103D_B" in infoList ? infoList.PT103D_B : 0 }}&nbsp;MPa
            </div>
          </div>
          <div class="float4">
            <div
              class="num1"
              @click="
                toCompon(
                  2,
                  'TE165_B',
                  infoList.TE165_B_node_string,
                  'TE165_BVV1',
                  '二级甲减温器蒸汽出温度'
                )
              "
              @dblclick="
                Cclick(
                  infoList.TE165_B,
                  'TE165_B',
                  infoList.TE165_B_node_string,
                  'TE165_BVV1'
                )
              "
            >
              {{ "TE165_B" in infoList ? infoList.TE165_B : 0 }}&nbsp;℃
            </div>
          </div>
          <div class="float5">
            <div
              class="num1"
              @click="
                toCompon(
                  2,
                  'TE166_B',
                  infoList.TE166_B_node_string,
                  'TE166_BVV1',
                  '二级乙减温器蒸汽出温度'
                )
              "
              @dblclick="
                Cclick(
                  infoList.TE166_B,
                  'TE166_B',
                  infoList.TE166_B_node_string,
                  'TE166_BVV1'
                )
              "
            >
              {{ "TE166_B" in infoList ? infoList.TE166_B : 0 }}&nbsp;℃
            </div>
          </div>
        </div>
        <div class="block2 flex">
          <div class="device" @click="toCompon(5)">煤斗</div>
          <div class="water_level_ball flex">
            <div class="water_level">
              <div class="top flex">
                <!-- <div class="words">水位1</div> -->
                <div
                  class="words"
                  :style="{
                    background:
                      'SWHSEL' in infoList
                        ? infoList.SWHSEL == 1
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    toIpt(
                      '1',
                      '水位1',
                      'SWHSEL',
                      infoList.SWHSEL_node_string,
                      'true'
                    )
                  "
                >
                  水位1
                </div>
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'LT101D_B',
                        infoList.LT101D_B_node_string,
                        'LT101D_BVV1',
                        '汽包水位1'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.LT101D_B,
                        'LT101D_B',
                        infoList.LT101D_B_node_string,
                        'LT101D_BVV1'
                      )
                    "
                  >
                    {{ "LT101D_B" in infoList ? infoList.LT101D_B : 0 }}&nbsp;mm
                  </div>
                </div>
              </div>
              <div class="top flex">
                <div
                  class="words"
                  :style="{
                    background:
                      'SWHSEL' in infoList
                        ? infoList.SWHSEL == 2
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    toIpt(
                      '2',
                      '水位2',
                      'SWHSEL',
                      infoList.SWHSEL_node_string,
                      'true'
                    )
                  "
                >
                  水位2
                </div>
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'LT102D_B',
                        infoList.LT102D_B_node_string,
                        'LT102D_BVV1',
                        '汽包水位2'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.LT102D_B,
                        'LT102D_B',
                        infoList.LT102D_B_node_string,
                        'LT102D_BVV1'
                      )
                    "
                  >
                    {{ "LT102D_B" in infoList ? infoList.LT102D_B : 0 }}&nbsp;mm
                  </div>
                </div>
              </div>
              <div class="top flex">
                <div
                  class="words"
                  :style="{
                    background:
                      'SWHSEL' in infoList
                        ? infoList.SWHSEL == 4
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    toIpt(
                      '4',
                      '水位3',
                      'SWHSEL',
                      infoList.SWHSEL_node_string,
                      'true'
                    )
                  "
                >
                  水位3
                </div>
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'LT103D_B',
                        infoList.LT103D_B_node_string,
                        'LT103D_BVV1',
                        '汽包水位3'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.LT103D_B,
                        'LT103D_B',
                        infoList.LT103D_B_node_string,
                        'LT103D_BVV1'
                      )
                    "
                  >
                    {{ "LT103D_B" in infoList ? infoList.LT103D_B : 0 }}&nbsp;mm
                  </div>
                </div>
              </div>
              <div class="top flex">
                <div
                  class="words"
                  :style="{
                    background:
                      'SWHSEL' in infoList
                        ? infoList.SWHSEL == 8
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    toIpt(
                      '8',
                      '水位4',
                      'SWHSEL',
                      infoList.SWHSEL_node_string,
                      'true'
                    )
                  "
                >
                  水位4
                </div>
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'LT104D_B',
                        infoList.LT104D_B_node_string,
                        'LT104D_BVV1',
                        '汽包水位4'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.LT104D_B,
                        'LT104D_B',
                        infoList.LT104D_B_node_string,
                        'LT104D_BVV1'
                      )
                    "
                  >
                    {{ "LT104D_B" in infoList ? infoList.LT104D_B : 0 }}&nbsp;mm
                  </div>
                </div>
              </div>
            </div>
            <div class="ball" @click="toCompon(11)">
              <dv-water-level-pond
                :config="config"
                style="width: 110px; height: 110px"
                @click.stop="
                  toCompon(
                    2,
                    'SWSJPV',
                    infoList.SWSJPV_node_string,
                    'SWSJPVVV1'
                  )
                "
              />
            </div>
            <div class="location">
              <div
                class="num1"
                @click.stop="
                  toCompon(
                    2,
                    'SWSJPV',
                    infoList.SWSJPV_node_string,
                    'SWSJPVVV1',
                    '汽包水位均值'
                  )
                "
                @dblclick.stop="
                  Cclick(
                    infoList.SWSJPV,
                    'SWSJPV',
                    infoList.SWSJPV_node_string,
                    'SWSJPVVV1'
                  )
                "
              >
                {{ "SWSJPV" in infoList ? infoList.SWSJPV : 0 }}&nbsp;mm
              </div>
              <!-- <div class="num1"
						        @click="toCompon(2,'PT102D1_B',infoList.PT102D1_B_node_string,'PT102D1_BVV1')"
						        @dblclick="Cclick(infoList.PT102D1_B,'PT102D1_B',infoList.PT102D1_B_node_string,'PT102D1_BVV1')">
						            {{infoList.PT102D1_B}}&nbsp;MPa
						    </div> -->
            </div>
          </div>
          <div class="location1">
            <div class="flex">
              <div
                class="num1"
                @click="
                  toCompon(
                    2,
                    'TE136_D_B',
                    infoList.TE136_D_B_node_string,
                    'TE136_D_BVV1',
                    '二级甲入口蒸汽温度（左)'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.TE136_D_B,
                    'TE136_D_B',
                    infoList.TE136_D_B_node_string,
                    'TE136_D_BVV1'
                  )
                "
              >
                {{ "TE136_D_B" in infoList ? infoList.TE136_D_B : 0 }}&nbsp;℃
              </div>
            </div>
            <div class="flex">
              <div
                class="num1"
                @click="
                  toCompon(
                    2,
                    'TE137_D_B',
                    infoList.TE137_D_B_node_string,
                    'TE137_D_BVV1',
                    '二级乙入口蒸汽温度（右)'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.TE137_D_B,
                    'TE137_D_B',
                    infoList.TE137_D_B_node_string,
                    'TE137_D_BVV1'
                  )
                "
              >
                {{ "TE137_D_B" in infoList ? infoList.TE137_D_B : 0 }}&nbsp;℃
              </div>
            </div>
          </div>
          <div class="location2">
            <div class="flex">
              <div
                class="num1"
                @click="
                  toCompon(
                    2,
                    'TE138_D_B',
                    infoList.TE138_D_B_node_string,
                    'TE138_D_BVV1',
                    '一级甲入口蒸汽温度（左)'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.TE138_D_B,
                    'TE138_D_B',
                    infoList.TE138_D_B_node_string,
                    'TE138_D_BVV1'
                  )
                "
              >
                {{ "TE138_D_B" in infoList ? infoList.TE138_D_B : 0 }}&nbsp;℃
              </div>
            </div>
            <div class="flex">
              <div
                class="num1"
                @click="
                  toCompon(
                    2,
                    'TE139_D_B',
                    infoList.TE139_D_B_node_string,
                    'TE139_D_BVV1',
                    '一级乙入口蒸汽温度（右)'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.TE139_D_B,
                    'TE139_D_B',
                    infoList.TE139_D_B_node_string,
                    'TE139_D_BVV1'
                  )
                "
              >
                {{ "TE139_D_B" in infoList ? infoList.TE139_D_B : 0 }}&nbsp;℃
              </div>
            </div>
          </div>
        </div>
        <div class="block3 flex">
          <div class="zy" @click="toCompon(10)"></div>
          <div class="one flex">
            <div class="valve"></div>
            <div class="valve float1"></div>
            <div class="valve float2"></div>
            <div class="valve float3"></div>
            <div class="valve float4"></div>
            <div class="valve float5"></div>
          </div>
          <div class="two">
            <div class="location1">
              <div>
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'KF_GM6_B',
                        infoList.KF_GM6_B_node_string,
                        'KF_GM6_BVV1',
                        '6#给煤机反馈'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.KF_GM6_B,
                        'KF_GM6_B',
                        infoList.KF_GM6_B_node_string,
                        'KF_GM6_BVV1'
                      )
                    "
                  >
                    {{ "KF_GM6_B" in infoList ? infoList.KF_GM6_B : 0 }}&nbsp;%
                  </div>
                </div>
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'MLZ6',
                        infoList.MLZ6_node_string,
                        'MLZ6VV1',
                        '6#给煤量选择后的值'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.MLZ6,
                        'MLZ6',
                        infoList.MLZ6_node_string,
                        'MLZ6VV1'
                      )
                    "
                  >
                    {{ "MLZ6" in infoList ? infoList.MLZ6 : 0 }}&nbsp;t/h
                  </div>
                </div>
              </div>
              <div class="float2">
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'KF_GM5_B',
                        infoList.KF_GM5_B_node_string,
                        'KF_GM5_BVV1',
                        '5#给煤机反馈'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.KF_GM5_B,
                        'KF_GM5_B',
                        infoList.KF_GM5_B_node_string,
                        'KF_GM5_BVV1'
                      )
                    "
                  >
                    {{ "KF_GM5_B" in infoList ? infoList.KF_GM5_B : 0 }}&nbsp;%
                  </div>
                </div>
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'MLZ5',
                        infoList.MLZ5_node_string,
                        'MLZ5VV1',
                        '3#给煤量选择后的值'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.MLZ5,
                        'MLZ5',
                        infoList.MLZ5_node_string,
                        'MLZ5VV1'
                      )
                    "
                  >
                    {{ "MLZ5" in infoList ? infoList.MLZ5 : 0 }}&nbsp;t/h
                  </div>
                </div>
              </div>
              <div class="float3">
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'KF_GM4_B',
                        infoList.KF_GM4_B_node_string,
                        'KF_GM4_BVV1',
                        '4#给煤机反馈'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.KF_GM4_B,
                        'KF_GM4_B',
                        infoList.KF_GM4_B_node_string,
                        'KF_GM4_BVV1'
                      )
                    "
                  >
                    {{ "KF_GM4_B" in infoList ? infoList.KF_GM4_B : 0 }}&nbsp;%
                  </div>
                </div>
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'MLZ4',
                        infoList.MLZ4_node_string,
                        'MLZ4VV1',
                        '4#给煤量选择后的值'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.MLZ4,
                        'MLZ4',
                        infoList.MLZ4_node_string,
                        'MLZ4VV1'
                      )
                    "
                  >
                    {{ "MLZ4" in infoList ? infoList.MLZ4 : 0 }}&nbsp;t/h
                  </div>
                </div>
              </div>
              <div class="float4">
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'KF_GM3_B',
                        infoList.KF_GM3_B_node_string,
                        'KF_GM3_BVV1',
                        '3#给煤机反馈'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.KF_GM3_B,
                        'KF_GM3_B',
                        infoList.KF_GM3_B_node_string,
                        'KF_GM3_BVV1'
                      )
                    "
                  >
                    {{ "KF_GM3_B" in infoList ? infoList.KF_GM3_B : 0 }}&nbsp;%
                  </div>
                </div>
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'MLZ3',
                        infoList.MLZ3_node_string,
                        'MLZ3VV1',
                        '3#给煤量选择后的值'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.MLZ3,
                        'MLZ3',
                        infoList.MLZ3_node_string,
                        'MLZ3VV1'
                      )
                    "
                  >
                    {{ "MLZ3" in infoList ? infoList.MLZ3 : 0 }}&nbsp;t/h
                  </div>
                </div>
              </div>
              <div class="float5">
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'KF_GM2_B',
                        infoList.KF_GM2_B_node_string,
                        'KF_GM2_BVV1',
                        '2#给煤机反馈'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.KF_GM2_B,
                        'KF_GM2_B',
                        infoList.KF_GM2_B_node_string,
                        'KF_GM2_BVV1'
                      )
                    "
                  >
                    {{ "KF_GM2_B" in infoList ? infoList.KF_GM2_B : 0 }}&nbsp;%
                  </div>
                </div>
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'MLZ2',
                        infoList.MLZ2_node_string,
                        'MLZ2VV1',
                        '2#给煤量选择后的值'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.MLZ2,
                        'MLZ2',
                        infoList.MLZ2_node_string,
                        'MLZ2VV1'
                      )
                    "
                  >
                    {{ "MLZ2" in infoList ? infoList.MLZ2 : 0 }}&nbsp;t/h
                  </div>
                </div>
              </div>
              <div class="float6">
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'KF_GM1_B',
                        infoList.KF_GM1_B_node_string,
                        'KF_GM1_BVV1',
                        '1#给煤机反馈'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.KF_GM1_B,
                        'KF_GM1_B',
                        infoList.KF_GM1_B_node_string,
                        'KF_GM1_BVV1'
                      )
                    "
                  >
                    {{ "KF_GM1_B" in infoList ? infoList.KF_GM1_B : 0 }}&nbsp;%
                  </div>
                </div>
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'MLZ1',
                        infoList.MLZ1_node_string,
                        'MLZ1VV1',
                        '1#给煤量选择后的值'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.MLZ1,
                        'MLZ1',
                        infoList.MLZ1_node_string,
                        'MLZ1VV1'
                      )
                    "
                  >
                    {{ "MLZ1" in infoList ? infoList.MLZ1 : 0 }}&nbsp;t/h
                  </div>
                </div>
              </div>
            </div>
            <div class="location2">
              <div class="words">实际给煤量</div>
              <div class="flex">
                <div
                  class="num1"
                  @click="
                    toCompon(
                      2,
                      'ZGML',
                      infoList.ZGML_node_string,
                      'ZGMLVV1',
                      '总给煤量'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.ZGML,
                      'ZGML',
                      infoList.ZGML_node_string,
                      'ZGMLVV1'
                    )
                  "
                >
                  {{ "ZGML" in infoList ? infoList.ZGML : 0 }}&nbsp;t/h
                </div>
              </div>
              <div class="float1 flex">
                <div
                  class="buttonx"
                  :style="{
                    background:
                      'XK0O' in infoList
                        ? infoList.XK0O && !infoList.XK0O.RM
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    infoList.XK0O
                      ? toDetail(
                          1,
                          'XK0O',
                          infoList.XK0O_node_string,
                          '',
                          '主汽压力广义预测调节先控'
                        )
                      : ''
                  "
                >
                  X
                </div>
                <div
                  class="buttonx"
                  :style="{
                    background:
                      'XK0P' in infoList
                        ? infoList.XK0P && !infoList.XK0P.RM
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    infoList.XK0P
                      ? toDetail(
                          1,
                          'XK0P',
                          infoList.XK0P_node_string,
                          '',
                          '广义预测流量偏差调节先控'
                        )
                      : ''
                  "
                >
                  X
                </div>
              </div>
              <div class="flex">
                <div
                  class="buttonr"
                  :style="{
                    background:
                      'QK07' in infoList
                        ? infoList.QK07 && !infoList.QK07.TS
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    infoList.QK07
                      ? toDetail(
                          2,
                          'QK07',
                          infoList.QK07_node_string,
                          '',
                          '主汽流量前馈'
                        )
                      : ''
                  "
                >
                  K
                </div>
                <div
                  class="buttonx"
                  :style="{
                    background:
                      'XK03' in infoList
                        ? infoList.XK03 && !infoList.XK03.RM
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    infoList.XK03
                      ? toDetail(
                          1,
                          'XK03',
                          infoList.XK03_node_string,
                          '',
                          '主汽压力调节'
                        )
                      : ''
                  "
                >
                  X
                </div>
                <div
                  class="buttonx"
                  :style="{
                    background:
                      'XK04' in infoList
                        ? infoList.XK04 && infoList.XK04.RM == 1
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    infoList.XK04
                      ? toDetail(
                          1,
                          'XK04',
                          infoList.XK04_node_string,
                          '',
                          '床温调节'
                        )
                      : ''
                  "
                >
                  X
                </div>
                <div
                  class="buttonx"
                  :style="{
                    background:
                      'XK02' in infoList
                        ? infoList.XK02 && infoList.XK02.RM == 1
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    infoList.XK02
                      ? toDetail(
                          1,
                          'XK02',
                          infoList.XK02_node_string,
                          '',
                          '给煤流量调节'
                        )
                      : ''
                  "
                >
                  X
                </div>
                <div
                  class="buttonx"
                  :style="{
                    background:
                      'MAN1' ||
                      'MAN2' ||
                      'MAN3' ||
                      'MAN4' ||
                      'MAN23' ||
                      'MAN24' in infoList
                        ? infoList.MAN1.RM == 1 ||
                          infoList.MAN2.RM == 1 ||
                          infoList.MAN3.RM == 1 ||
                          infoList.MAN4.RM == 1 ||
                          infoList.MAN23.RM == 1 ||
                          infoList.MAN24.RM == 1
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="toCompon(0, 'CFB_MANGM', 6)"
                >
                  A
                </div>
              </div>
            </div>
          </div>
          <div class="three">
            <div class="location1">
              <div class="float1 flex">
                <div>
                  <div class="shadow flex">
                    <div
                      class="num1"
                      @click="
                        toCompon(
                          2,
                          'PT124D_B',
                          infoList.PT124D_B_node_string,
                          'PT124D_BVV1',
                          '炉膛负压1'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.PT124D_B,
                          'PT124D_B',
                          infoList.PT124D_B_node_string,
                          'PT124D_BVV1'
                        )
                      "
                    >
                      {{
                        "PT124D_B" in infoList ? infoList.PT124D_B : 0
                      }}&nbsp;Pa
                    </div>
                  </div>
                  <!-- <div
                    class="words"
                    @click="
                      chanereast(
                        infoList[node].SELYFVV1 && infoList[node].SELYFVV1 == 1
                          ? '254'
                          : '1',
                        '是否改变自整定功能的选择',
                        'SELYFVV1',
                        node
                      )
                    "
                  >
                    负压1
                  </div> -->
                  <div class="words">负压1</div>
                </div>
                <div>
                  <div class="flex">
                    <div
                      class="num4"
                      @click="
                        toIpt(
                          infoList.FYSP_B1,
                          '汽压控制点',
                          'FYSP_B1',
                          infoList.FYSP_B1_node_string
                        )
                      "
                    >
                      {{ "FYSP_B1" in infoList ? infoList.FYSP_B1 : 0 }}&nbsp;Pa
                    </div>
                  </div>
                  <div class="flex">
                    <div
                      class="num6"
                      @click="
                        toCompon(
                          2,
                          'LTPJZ',
                          infoList.LTPJZ_node_string,
                          'LTPJZVV1',
                          '炉膛负压均值'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.LTPJZ,
                          'LTPJZ',
                          infoList.LTPJZ_node_string,
                          'LTPJZVV1'
                        )
                      "
                    >
                      {{ "LTPJZ" in infoList ? infoList.LTPJZ : 0 }}&nbsp;Pa
                    </div>
                  </div>
                </div>
                <div>
                  <div class="shadow flex">
                    <div
                      class="num1"
                      @click="
                        toCompon(
                          2,
                          'PT125D_B',
                          infoList.PT125D_B_node_string,
                          'PT125D_BVV1',
                          '炉膛负压2'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.PT125D_B,
                          'PT125D_B',
                          infoList.PT125D_B_node_string,
                          'PT125D_BVV1'
                        )
                      "
                    >
                      {{
                        "PT125D_B" in infoList ? infoList.PT125D_B : 0
                      }}&nbsp;Pa
                    </div>
                  </div>
                  <!-- <div
                    class="words"
                    @click="
                      chanereast(
                        infoList[node].SELYFVV1 && infoList[node].SELYFVV1 == 1
                          ? '254'
                          : '1',
                        '是否改变自整定功能的选择',
                        'SELYFVV1',
                        node
                      )
                    "
                  >
                    负压2
                  </div> -->
                  <div class="words">负压2</div>
                </div>
              </div>
              <div class="flex">
                <div>
                  <div class="shadow flex">
                    <div
                      class="num1"
                      @click="
                        toCompon(
                          2,
                          'PT126D_B',
                          infoList.PT126D_B_node_string,
                          'PT126D_BVV1',
                          '炉膛负压3'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.PT126D_B,
                          'PT126D_B',
                          infoList.PT126D_B_node_string,
                          'PT126D_BVV1'
                        )
                      "
                    >
                      {{
                        "PT126D_B" in infoList ? infoList.PT126D_B : 0
                      }}&nbsp;Pa
                    </div>
                  </div>
                  <!-- <div
                    class="words"
                    @click="
                      chanereast(
                        infoList[node].SELYFVV1 && infoList[node].SELYFVV1 == 1
                          ? '254'
                          : '1',
                        '是否改变自整定功能的选择',
                        'SELYFVV1',
                        node
                      )
                    "
                  >
                    负压3
                  </div> -->
                  <div class="words">负压3</div>
                </div>
                <div class="float2">
                  <div class="shadow flex">
                    <div
                      class="num1"
                      @click="
                        toCompon(
                          2,
                          'PT127D_B',
                          infoList.PT127D_B_node_string,
                          'PT127D_BVV1',
                          '炉膛负压4'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.PT127D_B,
                          'PT127D_B',
                          infoList.PT127D_B_node_string,
                          'PT127D_BVV1'
                        )
                      "
                    >
                      {{
                        "PT127D_B" in infoList ? infoList.PT127D_B : 0
                      }}&nbsp;Pa
                    </div>
                  </div>
                  <!-- <div
                    class="words"
                    @click="
                      chanereast(
                        infoList[node].SELYFVV1 && infoList[node].SELYFVV1 == 1
                          ? '254'
                          : '1',
                        '是否改变自整定功能的选择',
                        'SELYFVV1',
                        node
                      )
                    "
                  >
                    负压4
                  </div> -->
                  <div class="words">负压4</div>
                </div>
              </div>
            </div>
            <div class="location2 flex">
              <div class="left">
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'TE133D_B',
                        infoList.TE133D_B_node_string,
                        'TE133D_BVV1',
                        '炉床上部温度'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.TE133D_B,
                        'TE133D_B',
                        infoList.TE133D_B_node_string,
                        'TE133D_BVV1'
                      )
                    "
                  >
                    {{ "TE133D_B" in infoList ? infoList.TE133D_B : 0 }}&nbsp;℃
                  </div>
                </div>
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'TE132D_B',
                        infoList.TE132D_B_node_string,
                        'TE132D_BVV1',
                        '炉床上部温度'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.TE132D_B,
                        'TE132D_B',
                        infoList.TE132D_B_node_string,
                        'TE132D_BVV1'
                      )
                    "
                  >
                    {{ "TE132D_B" in infoList ? infoList.TE132D_B : 0 }}&nbsp;℃
                  </div>
                </div>
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'TE129D_B',
                        infoList.TE129D_B_node_string,
                        'TE129D_BVV1',
                        '炉床上部温度'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.TE129D_B,
                        'TE129D_B',
                        infoList.TE129D_B_node_string,
                        'TE129D_BVV1'
                      )
                    "
                  >
                    {{ "TE129D_B" in infoList ? infoList.TE129D_B : 0 }}&nbsp;℃
                  </div>
                </div>
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'TE128D_B',
                        infoList.TE128D_B_node_string,
                        'TE128D_BVV1',
                        '炉床上部温度'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.TE128D_B,
                        'TE128D_B',
                        infoList.TE128D_B_node_string,
                        'TE128D_BVV1'
                      )
                    "
                  >
                    {{ "TE128D_B" in infoList ? infoList.TE128D_B : 0 }}&nbsp;℃
                  </div>
                </div>
              </div>
              <div class="right">
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'TE125D_B',
                        infoList.TE125D_B_node_string,
                        'TE125D_BVV1',
                        '炉床上部温度'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.TE125D_B,
                        'TE125D_B',
                        infoList.TE125D_B_node_string,
                        'TE125D_BVV1'
                      )
                    "
                  >
                    {{ "TE125D_B" in infoList ? infoList.TE125D_B : 0 }}&nbsp;℃
                  </div>
                </div>
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'TE124D_B',
                        infoList.TE124D_B_node_string,
                        'TE124D_BVV1',
                        '炉床上部温度'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.TE124D_B,
                        'TE124D_B',
                        infoList.TE124D_B_node_string,
                        'TE124D_BVV1'
                      )
                    "
                  >
                    {{ "TE124D_B" in infoList ? infoList.TE124D_B : 0 }}&nbsp;℃
                  </div>
                </div>
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'TE123D_B',
                        infoList.TE123D_B_node_string,
                        'TE123D_BVV1',
                        '炉床上部温度'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.TE123D_B,
                        'TE123D_B',
                        infoList.TE123D_B_node_string,
                        'TE123D_BVV1'
                      )
                    "
                  >
                    {{ "TE123D_B" in infoList ? infoList.TE123D_B : 0 }}&nbsp;℃
                  </div>
                </div>
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'TE122D_B',
                        infoList.TE122D_B_node_string,
                        'TE122D_BVV1',
                        '炉床上部温度'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.TE122D_B,
                        'TE122D_B',
                        infoList.TE122D_B_node_string,
                        'TE122D_BVV1'
                      )
                    "
                  >
                    {{ "TE122D_B" in infoList ? infoList.TE122D_B : 0 }}&nbsp;℃
                  </div>
                </div>
              </div>
            </div>
            <div class="location3">
              <div class="float1 flex">
                <div
                  class="num3"
                  @click="
                    toCompon(
                      2,
                      'ZJAV1',
                      infoList.ZJAV1_node_string,
                      'ZJAV1VV1',
                      '床温变化显示'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.ZJAV1,
                      'ZJAV1',
                      infoList.ZJAV1_node_string,
                      'ZJAV1VV1'
                    )
                  "
                >
                  {{ "ZJAV1" in infoList ? infoList.ZJAV1 : 0 }}&nbsp;℃/min
                </div>
              </div>
              <div class="flex">
                <div class="flex">
                  <div
                    class="num4"
                    @click="
                      toIpt(
                        infoList.CWSP_B1,
                        '汽压控制点',
                        'CWSP_B1',
                        infoList.CWSP_B1_node_string
                      )
                    "
                  >
                    {{ "CWSP_B1" in infoList ? infoList.CWSP_B1 : 0 }}&nbsp;℃
                  </div>
                </div>
                <div class="float6 flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'CWSP_SJ',
                        infoList.CWSP_SJ_node_string,
                        'CWSP_SJVV1',
                        '床温实际控制点'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.CWSP_SJ,
                        'CWSP_SJ',
                        infoList.CWSP_SJ_node_string,
                        'CWSP_SJVV1'
                      )
                    "
                  >
                    {{ "CWSP_SJ" in infoList ? infoList.CWSP_SJ : 0 }}&nbsp;℃
                  </div>
                </div>
              </div>
              <div class="float2 flex">
                <div
                  class="num1"
                  @click="
                    toCompon(
                      2,
                      'MAXCW',
                      infoList.MAXCW_node_string,
                      'MAXCWVV1',
                      '床温最大值'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.MAXCW,
                      'MAXCW',
                      infoList.MAXCW_node_string,
                      'MAXCWVV1'
                    )
                  "
                >
                  {{ "MAXCW" in infoList ? infoList.MAXCW : 0 }}&nbsp;℃
                </div>
              </div>
              <div class="flex">
                <div class="float3 flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'CWJZ_Z',
                        infoList.CWJZ_Z_node_string,
                        'CWJZ_ZVV1',
                        '左侧床温均值'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.CWJZ_Z,
                        'CWJZ_Z',
                        infoList.CWJZ_Z_node_string,
                        'CWJZ_ZVV1'
                      )
                    "
                  >
                    {{ "CWJZ_Z" in infoList ? infoList.CWJZ_Z : 0 }}&nbsp;℃
                  </div>
                </div>
                <div class="float4 flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'CWJZ',
                        infoList.CWJZ_node_string,
                        'CWJZVV1',
                        '床温均值'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.CWJZ,
                        'CWJZ',
                        infoList.CWJZ_node_string,
                        'CWJZVV1'
                      )
                    "
                  >
                    {{ "CWJZ" in infoList ? infoList.CWJZ : 0 }}&nbsp;℃
                  </div>
                </div>
                <div class="float5 flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'CWJZ_Y',
                        infoList.CWJZ_Y_node_string,
                        'CWJZ_YVV1',
                        '右侧床温均值'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.CWJZ_Y,
                        'CWJZ_Y',
                        infoList.CWJZ_Y_node_string,
                        'CWJZ_YVV1'
                      )
                    "
                  >
                    {{ "CWJZ_Y" in infoList ? infoList.CWJZ_Y : 0 }}&nbsp;℃
                  </div>
                </div>
              </div>
            </div>
            <div class="location7 flex">
              <div class="left">
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'TE110D_B',
                        infoList.TE110D_B_node_string,
                        'TE110D_BVV1',
                        '炉床温度5-左侧'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.TE110D_B,
                        'TE110D_B',
                        infoList.TE110D_B_node_string,
                        'TE110D_BVV1'
                      )
                    "
                  >
                    {{ "TE110D_B" in infoList ? infoList.TE110D_B : 0 }}&nbsp;℃
                  </div>
                </div>
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'TE111D_B',
                        infoList.TE111D_B_node_string,
                        'TE111D_BVV1',
                        '炉床温度6-右侧'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.TE111D_B,
                        'TE111D_B',
                        infoList.TE111D_B_node_string,
                        'TE111D_BVV1'
                      )
                    "
                  >
                    {{ "TE111D_B" in infoList ? infoList.TE111D_B : 0 }}&nbsp;℃
                  </div>
                </div>
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'TE112D_B',
                        infoList.TE112D_B_node_string,
                        'TE112D_BVV1',
                        '炉床温度7-左侧'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.TE112D_B,
                        'TE112D_B',
                        infoList.TE112D_B_node_string,
                        'TE112D_BVV1'
                      )
                    "
                  >
                    {{ "TE112D_B" in infoList ? infoList.TE112D_B : 0 }}&nbsp;℃
                  </div>
                </div>
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'TE113D_B',
                        infoList.TE113D_B_node_string,
                        'TE113D_BVV1',
                        '炉床温度8-右侧'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.TE113D_B,
                        'TE113D_B',
                        infoList.TE113D_B_node_string,
                        'TE113D_BVV1'
                      )
                    "
                  >
                    {{ "TE113D_B" in infoList ? infoList.TE113D_B : 0 }}&nbsp;℃
                  </div>
                </div>
              </div>
              <div class="left">
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'TE116D_B',
                        infoList.TE116D_B_node_string,
                        'TE116D_BVV1',
                        '炉床温度11-左侧'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.TE116D_B,
                        'TE116D_B',
                        infoList.TE116D_B_node_string,
                        'TE116D_BVV1'
                      )
                    "
                  >
                    {{ "TE116D_B" in infoList ? infoList.TE116D_B : 0 }}&nbsp;℃
                  </div>
                </div>
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'TE117D_B',
                        infoList.TE117D_B_node_string,
                        'TE117D_BVV1',
                        '炉床温度12-右侧'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.TE117D_B,
                        'TE117D_B',
                        infoList.TE117D_B_node_string,
                        'TE117D_BVV1'
                      )
                    "
                  >
                    {{ "TE117D_B" in infoList ? infoList.TE117D_B : 0 }}&nbsp;℃
                  </div>
                </div>
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'TE118D_B',
                        infoList.TE118D_B_node_string,
                        'TE118D_BVV1',
                        '炉床温度13-左侧'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.TE118D_B,
                        'TE118D_B',
                        infoList.TE118D_B_node_string,
                        'TE118D_BVV1'
                      )
                    "
                  >
                    {{ "TE118D_B" in infoList ? infoList.TE118D_B : 0 }}&nbsp;℃
                  </div>
                </div>
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'TE120D_B',
                        infoList.TE120D_B_node_string,
                        'TE120D_BVV1',
                        '炉床温度15-左侧'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.TE120D_B,
                        'TE120D_B',
                        infoList.TE120D_B_node_string,
                        'TE120D_BVV1'
                      )
                    "
                  >
                    {{ "TE120D_B" in infoList ? infoList.TE120D_B : 0 }}&nbsp;℃
                  </div>
                </div>
              </div>
              <div class="right">
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'TE106D_B',
                        infoList.TE106D_B_node_string,
                        'TE106D_BVV1',
                        '炉床温度1-左侧'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.TE106D_B,
                        'TE106D_B',
                        infoList.TE106D_B_node_string,
                        'TE106D_BVV1'
                      )
                    "
                  >
                    {{ "TE106D_B" in infoList ? infoList.TE106D_B : 0 }}&nbsp;℃
                  </div>
                </div>
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'TE107D_B',
                        infoList.TE107D_B_node_string,
                        'TE107D_BVV1',
                        '炉床温度2-右侧'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.TE107D_B,
                        'TE107D_B',
                        infoList.TE107D_B_node_string,
                        'TE107D_BVV1'
                      )
                    "
                  >
                    {{ "TE107D_B" in infoList ? infoList.TE107D_B : 0 }}&nbsp;℃
                  </div>
                </div>
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'TE108D_B',
                        infoList.TE108D_B_node_string,
                        'TE108D_BVV1',
                        '炉床温度3-左侧'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.TE108D_B,
                        'TE108D_B',
                        infoList.TE108D_B_node_string,
                        'TE108D_BVV1'
                      )
                    "
                  >
                    {{ "TE108D_B" in infoList ? infoList.TE108D_B : 0 }}&nbsp;℃
                  </div>
                </div>
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'TE109D_B',
                        infoList.TE109D_B_node_string,
                        'TE109D_BVV1',
                        '炉床温度4-右侧'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.TE109D_B,
                        'TE109D_B',
                        infoList.TE109D_B_node_string,
                        'TE109D_BVV1'
                      )
                    "
                  >
                    {{ "TE109D_B" in infoList ? infoList.TE109D_B : 0 }}&nbsp;℃
                  </div>
                </div>
              </div>
              <div class="right">
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'TE114D_B',
                        infoList.TE114D_B_node_string,
                        'TE114D_BVV1',
                        '炉床温度9-左侧'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.TE114D_B,
                        'TE114D_B',
                        infoList.TE114D_B_node_string,
                        'TE114D_BVV1'
                      )
                    "
                  >
                    {{ "TE114D_B" in infoList ? infoList.TE114D_B : 0 }}&nbsp;℃
                  </div>
                </div>
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'TE115D_B',
                        infoList.TE115D_B_node_string,
                        'TE115D_BVV1',
                        '炉床温度10-右侧'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.TE115D_B,
                        'TE115D_B',
                        infoList.TE115D_B_node_string,
                        'TE115D_BVV1'
                      )
                    "
                  >
                    {{ "TE115D_B" in infoList ? infoList.TE115D_B : 0 }}&nbsp;℃
                  </div>
                </div>
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'TE111D_B',
                        infoList.TE111D_B_node_string,
                        'TE111D_BVV1',
                        '炉床温度6-右侧'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.TE111D_B,
                        'TE111D_B',
                        infoList.TE111D_B_node_string,
                        'TE111D_BVV1'
                      )
                    "
                  >
                    {{ "TE111D_B" in infoList ? infoList.TE111D_B : 0 }}&nbsp;℃
                  </div>
                </div>
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'TE113D_B',
                        infoList.TE113D_B_node_string,
                        'TE113D_BVV1',
                        '炉床温度8-右侧'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.TE113D_B,
                        'TE113D_B',
                        infoList.TE113D_B_node_string,
                        'TE113D_BVV1'
                      )
                    "
                  >
                    {{ "TE113D_B" in infoList ? infoList.TE113D_B : 0 }}&nbsp;℃
                  </div>
                </div>
              </div>
            </div>
            <div class="location4">
              <div class="flex">
                <div
                  class="num4"
                  @click="
                    toIpt(
                      infoList.LCSP_B1,
                      '汽压控制点',
                      'LCSP_B1',
                      infoList.LCSP_B1_node_string
                    )
                  "
                >
                  {{ "" in infoList ? infoList.LCSP_B1 : 0 }}
                </div>
                <div class="num5">KPa</div>
              </div>
            </div>
            <div class="location5 flex">
              <div class="left flex">
                <div
                  class="num1"
                  @click="
                    toCompon(
                      2,
                      'DPT101D_B',
                      infoList.DPT101D_B_node_string,
                      'DPT101D_BVV1',
                      '左侧料层差压'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.DPT101D_B,
                      'DPT101D_B',
                      infoList.DPT101D_B_node_string,
                      'DPT101D_BVV1'
                    )
                  "
                >
                  {{ "DPT101D_B" in infoList ? infoList.DPT101D_B : 0 }}
                </div>
                <div class="num3">KPa</div>
              </div>
              <div class="right flex">
                <div
                  class="num1"
                  @click="
                    toCompon(
                      2,
                      'DPT102D_B',
                      infoList.DPT102D_B_node_string,
                      'DPT102D_BVV1',
                      '右侧料层差压'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.DPT102D_B,
                      'DPT102D_B',
                      infoList.DPT102D_B_node_string,
                      'DPT102D_BVV1'
                    )
                  "
                >
                  {{ "DPT102D_B" in infoList ? infoList.DPT102D_B : 0 }}
                </div>
                <div class="num3">KPa</div>
              </div>
            </div>
            <div class="location6 flex">
              <div>
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'TLZQ1_B',
                        infoList.TLZQ1_B_node_string,
                        'TLZQ1_BVV1',
                        '冷渣器水温'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.TLZQ1_B,
                        'TLZQ1_B',
                        infoList.TLZQ1_B_node_string,
                        'TLZQ1_BVV1'
                      )
                    "
                  >
                    {{ "TLZQ1_B" in infoList ? infoList.TLZQ1_B : 0 }}
                  </div>
                  <div class="num3">℃</div>
                </div>
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'KF_CZ1_B',
                        infoList.KF_CZ1_B_node_string,
                        'KF_CZ1_BVV1',
                        '1#除渣机调节反馈'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.KF_CZ1_B,
                        'KF_CZ1_B',
                        infoList.KF_CZ1_B_node_string,
                        'KF_CZ1_BVV1'
                      )
                    "
                  >
                    {{ "KF_CZ1_B" in infoList ? infoList.KF_CZ1_B : 0 }}
                  </div>
                  <div class="num3">%</div>
                </div>
              </div>
              <div class="float1">
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'TLZQ2_B',
                        infoList.TLZQ2_B_node_string,
                        'TLZQ2_BVV1',
                        '冷渣器水温'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.TLZQ2_B,
                        'TLZQ2_B',
                        infoList.TLZQ2_B_node_string,
                        'TLZQ2_BVV1'
                      )
                    "
                  >
                    {{ "TLZQ2_B" in infoList ? infoList.TLZQ2_B : 0 }}
                  </div>
                  <div class="num3">℃</div>
                </div>
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'KF_CZ2_B',
                        infoList.KF_CZ2_B_node_string,
                        'KF_CZ2_BVV1',
                        '2#除渣机调节反馈'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.KF_CZ2_B,
                        'KF_CZ2_B',
                        infoList.KF_CZ2_B_node_string,
                        'KF_CZ2_BVV1'
                      )
                    "
                  >
                    {{ "KF_CZ2_B" in infoList ? infoList.KF_CZ2_B : 0 }}
                  </div>
                  <div class="num3">%</div>
                </div>
              </div>
              <div class="float1">
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'TLZQ3_B',
                        infoList.TLZQ3_B_node_string,
                        'TLZQ3_BVV1',
                        '右侧回料器1#调节风门'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.TLZQ3_B,
                        'TLZQ3_B',
                        infoList.TLZQ3_B_node_string,
                        'TLZQ3_BVV1'
                      )
                    "
                  >
                    {{ "TLZQ3_B" in infoList ? infoList.TLZQ3_B : 0 }}
                  </div>
                  <div class="num3">℃</div>
                </div>
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'KF_CZ3_B',
                        infoList.KF_CZ3_B_node_string,
                        'KF_CZ3_BVV1',
                        '3#除渣反馈'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.KF_CZ3_B,
                        'KF_CZ3_B',
                        infoList.KF_CZ3_B_node_string,
                        'KF_CZ3_BVV1'
                      )
                    "
                  >
                    {{ "KF_CZ3_B" in infoList ? infoList.KF_CZ3_B : 0 }}
                  </div>
                  <div class="num3">%</div>
                </div>
              </div>
              <div class="float1">
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'TLZQ4_B',
                        infoList.TLZQ4_B_node_string,
                        'TLZQ4_BVV1',
                        '右侧回料器2#调节风门'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.TLZQ4_B,
                        'TLZQ4_B',
                        infoList.TLZQ4_B_node_string,
                        'TLZQ4_BVV1'
                      )
                    "
                  >
                    {{ "TLZQ4_B" in infoList ? infoList.TLZQ4_B : 0 }}
                  </div>
                  <div class="num3">℃</div>
                </div>
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'KF_CZ4_B',
                        infoList.KF_CZ4_B_node_string,
                        'KF_CZ4_BVV1',
                        '4#除渣反馈'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.KF_CZ4_B,
                        'KF_CZ4_B',
                        infoList.KF_CZ4_B_node_string,
                        'KF_CZ4_BVV1'
                      )
                    "
                  >
                    {{ "KF_CZ4_B" in infoList ? infoList.KF_CZ4_B : 0 }}
                  </div>
                  <div class="num3">%</div>
                </div>
              </div>
            </div>
          </div>
          <div class="four">
            <div class="location1">
              <div class="flex">
                <div class="words">TC</div>
                <div
                  class="num2"
                  @click="
                    toIpt(
                      infoList.ECFPKP_TC,
                      'TC',
                      'ECFPKP_TC',
                      infoList.ECFPKP_TC_node_string
                    )
                  "
                >
                  {{ "ECFPKP_TC" in infoList ? infoList.ECFPKP_TC : 0 }}
                </div>
              </div>
              <div class="words">二次风压</div>
              <div class="flex">
                <div
                  class="num4"
                  @click="
                    toIpt(
                      infoList.BCSBY1SP_B,
                      '二次风压',
                      'BCSBY1SP_B',
                      infoList.BCSBY1SP_B_node_string
                    )
                  "
                >
                  {{ "BCSBY1SP_B" in infoList ? infoList.BCSBY1SP_B : 0 }}
                </div>
                <div class="num5">KPa</div>
              </div>
              <div
                class="buttonr"
                :style="{
                  background:
                    'RSF0G' in infoList
                      ? !infoList.RSF0G.TS
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.RSF0G
                    ? toDetail(
                        3,
                        'RSF0G',
                        infoList.RSF0G_node_string,
                        '',
                        '软伺服'
                      )
                    : ''
                "
              >
                R
              </div>
              <div
                class="buttonx float1"
                :style="{
                  background:
                    'MAN31' in infoList
                      ? infoList.MAN31.RM == 1 || infoList.MAN32.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="toCompon(0, 'CFB_MANECFY', 2)"
              >
                A
              </div>
            </div>
            <div class="location2">
              <div class="flex">
                <div
                  class="buttonx"
                  :style="{
                    background:
                      'XK0C' in infoList
                        ? infoList.XK0C && infoList.XK0C.RM == 1
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    infoList.XK0C
                      ? toDetail(
                          1,
                          'XK0C',
                          infoList.XK0C_node_string,
                          '',
                          '料层调节'
                        )
                      : ''
                  "
                >
                  X
                </div>
                <div
                  class="buttonx float1"
                  :style="{
                    background:
                      'MAN13' || 'MAN14' || 'MAN28' || 'MAN29' in infoList
                        ? infoList.MAN13.RM == 1 ||
                          infoList.MAN14.RM == 1 ||
                          infoList.MAN28.RM == 1 ||
                          infoList.MAN29.RM == 1
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="toCompon(0, 'CFB_MANCZ', 4)"
                >
                  A
                </div>
              </div>
              <div class="words" @click="toCompon(4)" style="cursor: pointer">
                冷渣机
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="middle">
        <div class="location1">
          <div class="flex">
            <div
              class="num1"
              @click="
                toCompon(
                  2,
                  'TE140_D_B',
                  infoList.TE140_D_B_node_string,
                  'TE140_D_BVV1',
                  '高过再热入口蒸汽温度（左)'
                )
              "
              @dblclick="
                Cclick(
                  infoList.TE140_D_B,
                  'TE140_D_B',
                  infoList.TE140_D_B_node_string,
                  'TE140_D_BVV1'
                )
              "
            >
              {{ "TE140_D_B" in infoList ? infoList.TE140_D_B : 0 }}&nbsp;℃
            </div>
          </div>
          <div class="flex">
            <div
              class="num1"
              @click="
                toCompon(
                  2,
                  'TE141_D_B',
                  infoList.TE141_D_B_node_string,
                  'TE141_D_BVV1',
                  '高过再热入口蒸汽温度（右)'
                )
              "
              @dblclick="
                Cclick(
                  infoList.TE141_D_B,
                  'TE141_D_B',
                  infoList.TE141_D_B_node_string,
                  'TE141_D_BVV1'
                )
              "
            >
              {{ "TE141_D_B" in infoList ? infoList.TE141_D_B : 0 }}&nbsp;℃
            </div>
          </div>
        </div>
        <div class="location2">
          <div class="flex">
            <div
              class="buttonr"
              :style="{
                background:
                  'QK05' in infoList
                    ? infoList.QK05 && !infoList.QK05.TS
                      ? '#2AFC30'
                      : 'red'
                    : 'red',
              }"
              @click="
                infoList.QK05
                  ? toDetail(
                      2,
                      'QK05',
                      infoList.QK05_node_string,
                      '',
                      '二级左再热前馈'
                    )
                  : ''
              "
            >
              K
            </div>
            <div
              class="buttonx right"
              :style="{
                background:
                  'XK0A' in infoList
                    ? infoList.XK0A && infoList.XK0A.RM == 1
                      ? '#2AFC30'
                      : 'red'
                    : 'red',
              }"
              @click="
                infoList.XK0A
                  ? toDetail(
                      1,
                      'XK0A',
                      infoList.XK0A_node_string,
                      '',
                      '右1烟气挡板调节'
                    )
                  : ''
              "
            >
              X
            </div>
          </div>
          <div class="flex up">
            <div
              class="buttonr"
              :style="{
                background:
                  'QK06' in infoList
                    ? infoList.QK06 && !infoList.QK06.TS
                      ? '#2AFC30'
                      : 'red'
                    : 'red',
              }"
              @click="
                infoList.QK06
                  ? toDetail(
                      2,
                      'QK06',
                      infoList.QK06_node_string,
                      '',
                      '二级右再热前馈'
                    )
                  : ''
              "
            >
              K
            </div>
            <div
              class="buttonx right"
              :style="{
                background:
                  'XK0B' in infoList
                    ? infoList.XK0B && infoList.XK0B.RM == 1
                      ? '#2AFC30'
                      : 'red'
                    : 'red',
              }"
              @click="
                infoList.XK0B
                  ? toDetail(
                      1,
                      'XK0B',
                      infoList.XK0B_node_string,
                      '',
                      '右2烟气挡板调节'
                    )
                  : ''
              "
            >
              X
            </div>
          </div>
          <div class="flex up">
            <div
              class="buttonr"
              :style="{
                background:
                  'QK0A' in infoList
                    ? infoList.QK0A && !infoList.QK0A.TS
                      ? '#2AFC30'
                      : 'red'
                    : 'red',
              }"
              @click="
                infoList.QK0A
                  ? toDetail(
                      2,
                      'QK0A',
                      infoList.QK0A_node_string,
                      '',
                      'QK0A设定值'
                    )
                  : ''
              "
            >
              K
            </div>
            <div
              class="buttonx right"
              :style="{
                background:
                  'XK0K' in infoList
                    ? infoList.XK0K && infoList.XK0K.RM == 1
                      ? '#2AFC30'
                      : 'red'
                    : 'red',
              }"
              @click="
                infoList.XK0K
                  ? toDetail(
                      1,
                      'XK0K',
                      infoList.XK0K_node_string,
                      '',
                      '左1烟气挡板调节'
                    )
                  : ''
              "
            >
              X
            </div>
          </div>
          <div class="flex up">
            <div
              class="buttonr"
              :style="{
                background:
                  'QK0B' in infoList
                    ? infoList.QK0B && !infoList.QK0B.TS
                      ? '#2AFC30'
                      : 'red'
                    : 'red',
              }"
              @click="
                infoList.QK0B
                  ? toDetail(
                      2,
                      'QK0B',
                      infoList.QK0B_node_string,
                      '',
                      'QK0B设定值'
                    )
                  : ''
              "
            >
              K
            </div>
            <div
              class="buttonx right"
              :style="{
                background:
                  'XK0L' in infoList
                    ? infoList.XK0L && infoList.XK0L.RM == 1
                      ? '#2AFC30'
                      : 'red'
                    : 'red',
              }"
              @click="
                infoList.XK0L
                  ? toDetail(
                      1,
                      'XK0L',
                      infoList.XK0L_node_string,
                      '',
                      '左1烟气挡板调节'
                    )
                  : ''
              "
            >
              X
            </div>
          </div>
        </div>
        <div class="location3 flex">
          <div>
            <div class="words" @click="toCompon(3)" style="cursor: pointer">
              再热汽温
            </div>
            <div class="flex">
              <div
                class="num4"
                @click="
                  toIpt(
                    infoList.ZQTSP_B2,
                    '再热汽温',
                    'ZQTSP_B2',
                    infoList.ZQTSP_B2_node_string
                  )
                "
              >
                {{ "ZQTSP_B2" in infoList ? infoList.ZQTSP_B2 : 0 }}&nbsp;℃
              </div>
            </div>
          </div>
          <div
            class="buttonx float1"
            :style="{
              background:
                'MAN33' || 'MAN34' || 'MAN35' || 'MAN36' in infoList
                  ? infoList.MAN33.RM == 1 ||
                    infoList.MAN34.RM == 1 ||
                    infoList.MAN35.RM == 1 ||
                    infoList.MAN36.RM == 1
                    ? '#2AFC30'
                    : 'red'
                  : 'red',
            }"
            @click="toCompon(0, 'CFB_MANQWZ', 4)"
          >
            A
          </div>
        </div>
        <div class="location4">
          <div class="float1">
            <div class="flex">
              <div
                class="num1"
                @click="
                  toCompon(
                    2,
                    'TE142_D_B',
                    infoList.TE142_D_B_node_string,
                    'TE142_D_BVV1',
                    '低过再热入口蒸汽温度（左)'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.TE142_D_B,
                    'TE142_D_B',
                    infoList.TE142_D_B_node_string,
                    'TE142_D_BVV1'
                  )
                "
              >
                {{ "TE142_D_B" in infoList ? infoList.TE142_D_B : 0 }}&nbsp;℃
              </div>
            </div>
            <div class="flex">
              <div
                class="num6"
                @click="
                  toIpt(
                    infoList.C_O2,
                    '低温过热器',
                    'C_O2',
                    infoList.C_O2_node_string
                  )
                "
              >
                {{ "C_O2" in infoList ? infoList.C_O2 : 0 }}&nbsp;%
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="flex">
              <div
                class="num1"
                @click="
                  toCompon(
                    2,
                    'AT101AD_B',
                    infoList.AT101AD_B_node_string,
                    'AT101AD_BVV1',
                    '烟气含氧量'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.AT101AD_B,
                    'AT101AD_B',
                    infoList.AT101AD_B_node_string,
                    'AT101AD_BVV1'
                  )
                "
              >
                {{ "AT101AD_B" in infoList ? infoList.AT101AD_B : 0 }}&nbsp;%
              </div>
            </div>
            <div class="float2 flex">
              <div
                class="num1"
                @click="
                  toCompon(
                    2,
                    'AT101BD_B',
                    infoList.AT101BD_B_node_string,
                    'AT101BD_BVV1',
                    '烟气含氧量'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.AT101BD_B,
                    'AT101BD_B',
                    infoList.AT101BD_B_node_string,
                    'AT101BD_BVV1'
                  )
                "
              >
                {{ "AT101BD_B" in infoList ? infoList.AT101BD_B : 0 }}&nbsp;%
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="button1">
              <div
                class="word1"
                :style="{
                  background:
                    'SEL_O2' in infoList
                      ? infoList.SEL_O2 == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  toIpt(
                    '1',
                    '氧量左',
                    'SEL_O2',
                    infoList.SEL_O2_node_string,
                    'true'
                  )
                "
              >
                氧量左
              </div>
            </div>
            <div class="button1 float3">
              <div
                class="word1"
                :style="{
                  background:
                    'SEL_O2' in infoList
                      ? infoList.SEL_O2 == 2
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  toIpt(
                    '2',
                    '氧量右',
                    'SEL_O2',
                    infoList.SEL_O2_node_string,
                    'true'
                  )
                "
              >
                氧量右
              </div>
            </div>
          </div>
          <div class="float4 flex">
            <div class="flex">
              <div
                class="num1"
                @click="
                  toCompon(
                    2,
                    'TE154_D_B',
                    infoList.TE154_D_B_node_string,
                    'TE154_D_BVV1',
                    '省煤器后烟气温度'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.TE154_D_B,
                    'TE154_D_B',
                    infoList.TE154_D_B_node_string,
                    'TE154_D_BVV1'
                  )
                "
              >
                {{ "TE154_D_B" in infoList ? infoList.TE154_D_B : 0 }}&nbsp;℃
              </div>
            </div>
            <div class="float2 flex">
              <div
                class="num1"
                @click="
                  toCompon(
                    2,
                    'PT148_D_B',
                    infoList.PT148_D_B_node_string,
                    'PT148_D_BVV1',
                    '省煤器后烟气负压'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.PT148_D_B,
                    'PT148_D_B',
                    infoList.PT148_D_B_node_string,
                    'PT148_D_BVV1'
                  )
                "
              >
                {{ "PT148_D_B" in infoList ? infoList.PT148_D_B : 0 }}&nbsp;℃
              </div>
            </div>
          </div>
        </div>
        <div class="location5">
          <div class="flex">
            <div class="words">目标值</div>
            <div class="flex">
              <div
                class="num1"
                @click="
                  toCompon(
                    2,
                    'FGFSP_B2',
                    infoList.FGFSP_B2_node_string,
                    'FGFSP_B2VV1',
                    '风量目标值'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.FGFSP_B2,
                    'FGFSP_B2',
                    infoList.FGFSP_B2_node_string,
                    'FGFSP_B2VV1'
                  )
                "
              >
                {{ "FGFSP_B2" in infoList ? infoList.FGFSP_B2 : 0 }}&nbsp;m3/h
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="words">测量值</div>
            <div class="flex">
              <div
                class="num1"
                @click="
                  toCompon(
                    2,
                    'ECFL',
                    infoList.ECFL_node_string,
                    'ECFLVV1',
                    '二次风量'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.ECFL,
                    'ECFL',
                    infoList.ECFL_node_string,
                    'ECFLVV1'
                  )
                "
              >
                {{ "ECFL" in infoList ? infoList.ECFL : 0 }}&nbsp;m3/h
              </div>
            </div>
          </div>
        </div>
        <div class="location6">
          <div class="flex">
            <div class="words">目标值</div>
            <div class="flex">
              <div
                class="num1"
                @click="
                  toCompon(
                    2,
                    'FGFSP_B1',
                    infoList.FGFSP_B1_node_string,
                    'FGFSP_B1VV1',
                    '风量目标值'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.FGFSP_B1,
                    'FGFSP_B1',
                    infoList.FGFSP_B1_node_string,
                    'FGFSP_B1VV1'
                  )
                "
              >
                {{ "FGFSP_B1" in infoList ? infoList.FGFSP_B1 : 0 }}&nbsp;m3/h
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="words">测量值</div>
            <div class="flex">
              <div
                class="num1"
                @click="
                  toCompon(
                    2,
                    'JSFL',
                    infoList.JSFL_node_string,
                    'JSFLVV1',
                    '一次风量'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.JSFL,
                    'JSFL',
                    infoList.JSFL_node_string,
                    'JSFLVV1'
                  )
                "
              >
                {{ "JSFL" in infoList ? infoList.JSFL : 0 }}&nbsp;m3/h
              </div>
            </div>
          </div>
        </div>
        <div class="location7 flex">
          <div class="flex">
            <div
              class="num1"
              @click="
                toCompon(
                  2,
                  'TE158_D_B',
                  infoList.TE158_D_B_node_string,
                  'TE158_D_BVV1',
                  '左侧排烟温度'
                )
              "
              @dblclick="
                Cclick(
                  infoList.TE158_D_B,
                  'TE158_D_B',
                  infoList.TE158_D_B_node_string,
                  'TE158_D_BVV1'
                )
              "
            >
              {{ "TE158_D_B" in infoList ? infoList.TE158_D_B : 0 }}&nbsp;℃
            </div>
          </div>
          <div class="float1 flex">
            <div
              class="num1"
              @click="
                toCompon(
                  2,
                  'TE159_D_B',
                  infoList.TE159_D_B_node_string,
                  'TE159_D_BVV1',
                  '右侧排烟温度'
                )
              "
              @dblclick="
                Cclick(
                  infoList.TE159_D_B,
                  'TE159_D_B',
                  infoList.TE159_D_B_node_string,
                  'TE159_D_BVV1'
                )
              "
            >
              {{ "TE159_D_B" in infoList ? infoList.TE159_D_B : 0 }}&nbsp;℃
            </div>
          </div>
        </div>
        <div class="location8 flex">
          <div class="flex">
            <div
              class="num4"
              @click="
                toCompon(
                  2,
                  'PT152_D_B',
                  infoList.PT152_D_B_node_string,
                  'PT152_D_BVV1',
                  '引风机入口压力'
                )
              "
              @dblclick="
                Cclick(
                  infoList.PT152_D_B,
                  'PT152_D_B',
                  infoList.PT152_D_B_node_string,
                  'PT152_D_BVV1'
                )
              "
            >
              {{ "PT152_D_B" in infoList ? infoList.PT152_D_B : 0 }}&nbsp;KPa
            </div>
          </div>
        </div>
      </div>
      <div class="rightt">
        <div class="one flex">
          <div class="tap">
            <div class="flex">
              <div class="flex">
                <div
                  class="num1"
                  @click="
                    toCompon(
                      2,
                      'TE165_B',
                      infoList.TE165_B_node_string,
                      'TE165_BVV1',
                      '二级甲减温器蒸汽出温度'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.TE165_B,
                      'TE165_B',
                      infoList.TE165_B_node_string,
                      'TE165_BVV1'
                    )
                  "
                >
                  {{ "TE165_B" in infoList ? infoList.TE165_B : 0 }}
                </div>
                <div class="num3">℃</div>
              </div>
              <div class="valve float1"></div>
              <div class="float2 flex">
                <div
                  class="num1"
                  @click="
                    toCompon(
                      2,
                      'KF_QW3_B',
                      infoList.KF_QW3_B_node_string,
                      'KF_QW3_BVV1',
                      '3#减温调节阀反馈'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.KF_QW3_B,
                      'KF_QW3_B',
                      infoList.KF_QW3_B_node_string,
                      'KF_QW3_BVV1'
                    )
                  "
                >
                  {{ "KF_QW3_B" in infoList ? infoList.KF_QW3_B : 0 }}
                </div>
                <div class="num3">%</div>
              </div>
            </div>
            <div class="line1 flex">
              <div class="flex">
                <div
                  class="num1"
                  @click="
                    toCompon(
                      2,
                      'TE166_B',
                      infoList.TE166_B_node_string,
                      'TE166_BVV1',
                      '二级乙减温器蒸汽出温度'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.TE166_B,
                      'TE166_B',
                      infoList.TE166_B_node_string,
                      'TE166_BVV1'
                    )
                  "
                >
                  {{ "TE166_B" in infoList ? infoList.TE166_B : 0 }}
                </div>
                <div class="num3">℃</div>
              </div>
              <div class="valve float1"></div>
              <div class="float2 flex">
                <div
                  class="num1"
                  @click="
                    toCompon(
                      2,
                      'KF_QW4_B',
                      infoList.KF_QW4_B_node_string,
                      'KF_QW4_BVV1',
                      '4#减温调节阀反馈'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.KF_QW4_B,
                      'KF_QW4_B',
                      infoList.KF_QW4_B_node_string,
                      'KF_QW4_BVV1'
                    )
                  "
                >
                  {{ "KF_QW4_B" in infoList ? infoList.KF_QW4_B : 0 }}
                </div>
                <div class="num3">%</div>
              </div>
            </div>
            <div class="line2 flex">
              <div class="flex">
                <div
                  class="num1"
                  @click="
                    toCompon(
                      2,
                      'TE163_B',
                      infoList.TE163_B_node_string,
                      'TE163_BVV1',
                      '一级甲减温器蒸汽出温度'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.TE163_B,
                      'TE163_B',
                      infoList.TE163_B_node_string,
                      'TE163_BVV1'
                    )
                  "
                >
                  {{ "TE163_B" in infoList ? infoList.TE163_B : 0 }}
                </div>
                <div class="num3">℃</div>
              </div>
              <div class="valve float3"></div>
              <div class="float2 flex">
                <div
                  class="num1"
                  @click="
                    toCompon(
                      2,
                      'KF_QW1_B',
                      infoList.KF_QW1_B_node_string,
                      'KF_QW1_BVV1',
                      '1#减温调节阀反馈'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.KF_QW1_B,
                      'KF_QW1_B',
                      infoList.KF_QW1_B_node_string,
                      'KF_QW1_BVV1'
                    )
                  "
                >
                  {{ "KF_QW1_B" in infoList ? infoList.KF_QW1_B : 0 }}
                </div>
                <div class="num3">%</div>
              </div>
            </div>
            <div class="line3 flex">
              <div class="flex">
                <div
                  class="num1"
                  @click="
                    toCompon(
                      2,
                      'TE164_B',
                      infoList.TE164_B_node_string,
                      'TE164_BVV1',
                      '一级乙减温器蒸汽出温度'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.TE164_B,
                      'TE164_B',
                      infoList.TE164_B_node_string,
                      'TE164_BVV1'
                    )
                  "
                >
                  {{ "TE164_B" in infoList ? infoList.TE164_B : 0 }}
                </div>
                <div class="num3">℃</div>
              </div>
              <div class="valve float4"></div>
              <div class="float2 flex">
                <div
                  class="num1"
                  @click="
                    toCompon(
                      2,
                      'KF_QW2_B',
                      infoList.KF_QW2_B_node_string,
                      'KF_QW2_BVV1',
                      '2#减温调节阀反馈'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.KF_QW2_B,
                      'KF_QW2_B',
                      infoList.KF_QW2_B_node_string,
                      'KF_QW2_BVV1'
                    )
                  "
                >
                  {{ "KF_QW2_B" in infoList ? infoList.KF_QW2_B : 0 }}
                </div>
                <div class="num3">%</div>
              </div>
            </div>
            <div class="line4 flex">
              <div class="flex">
                <div
                  class="num1"
                  @click="
                    toCompon(
                      2,
                      'KF_SW1_B',
                      infoList.KF_SW1_B_node_string,
                      'KF_SW1_BVV1',
                      '汽包水位A调节阀反馈'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.KF_SW1_B,
                      'KF_SW1_B',
                      infoList.KF_SW1_B_node_string,
                      'KF_SW1_BVV1'
                    )
                  "
                >
                  {{ "KF_SW1_B" in infoList ? infoList.KF_SW1_B : 0 }}
                </div>
                <div class="num3">%</div>
              </div>
              <div class="float3 valve"></div>
            </div>
            <div class="line5 flex">
              <div class="flex">
                <div
                  class="num1"
                  @click="
                    toCompon(
                      2,
                      'KF_SW2_B',
                      infoList.KF_SW2_B_node_string,
                      'KF_SW2_BVV1',
                      '汽包水位B调节阀反馈'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.KF_SW2_B,
                      'KF_SW2_B',
                      infoList.KF_SW2_B_node_string,
                      'KF_SW2_BVV1'
                    )
                  "
                >
                  {{ "KF_SW2_B" in infoList ? infoList.KF_SW2_B : 0 }}
                </div>
                <div class="num3">%</div>
              </div>
              <div class="float5 valve"></div>
            </div>
          </div>
          <div class="switch">
            <div class="flex">
              <div
                class="buttonr"
                :style="{
                  background:
                    'QK01' in infoList
                      ? infoList.QK01 && !infoList.QK01.TS
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.QK01
                    ? toDetail(
                        2,
                        'QK01',
                        infoList.QK01_node_string,
                        '',
                        '电负荷前馈'
                      )
                    : ''
                "
              >
                K
              </div>
              <!-- <div class="buttonr right">R</div> -->
            </div>
            <div class="top flex">
              <div
                class="buttonr"
                :style="{
                  background:
                    'QK02' in infoList
                      ? infoList.QK02 && !infoList.QK02.TS
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.QK02
                    ? toDetail(
                        2,
                        'QK02',
                        infoList.QK02_node_string,
                        '',
                        'QK02设定值'
                      )
                    : ''
                "
              >
                K
              </div>
              <div
                class="buttonx right"
                :style="{
                  background:
                    'XK07' in infoList
                      ? infoList.XK07 && infoList.XK07.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.XK07
                    ? toDetail(
                        1,
                        'XK07',
                        infoList.XK07_node_string,
                        '',
                        '二级左主汽温度调节'
                      )
                    : ''
                "
              >
                X
              </div>
              <!-- <div class="buttonx right">X</div> -->
            </div>
            <div class="top flex">
              <div
                class="buttonr"
                :style="{
                  background:
                    'QK03' in infoList
                      ? infoList.QK03 && !infoList.QK03.TS
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.QK03
                    ? toDetail(
                        2,
                        'QK03',
                        infoList.QK03_node_string,
                        '',
                        'QK03设定值'
                      )
                    : ''
                "
              >
                K
              </div>
              <div
                class="buttonx right"
                :style="{
                  background:
                    'XK08' in infoList
                      ? infoList.XK08 && infoList.XK08.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.XK08
                    ? toDetail(
                        1,
                        'XK08',
                        infoList.XK08_node_string,
                        '',
                        '二级右主汽温度调节'
                      )
                    : ''
                "
              >
                X
              </div>
              <!-- <div class="buttonx right">X</div> -->
              <div class="words" @click="toCompon(6)" style="cursor: pointer">
                减温水
              </div>
            </div>
            <div class="top flex">
              <div
                class="buttonr"
                :style="{
                  background:
                    'QK08' in infoList
                      ? infoList.QK08 && !infoList.QK08.TS
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.QK08
                    ? toDetail(
                        2,
                        'QK08',
                        infoList.QK08_node_string,
                        '',
                        'QK08设定值'
                      )
                    : ''
                "
              >
                K
              </div>
              <div
                class="buttonx right"
                :style="{
                  background:
                    'XK0I' in infoList
                      ? infoList.XK0I && !infoList.XK0I.RM
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.XK0I
                    ? toDetail(
                        1,
                        'XK0I',
                        infoList.XK0I_node_string,
                        '',
                        '一级左主汽温度调节'
                      )
                    : ''
                "
              >
                X
              </div>
              <!-- <div class="buttonx right">X</div> -->
            </div>
            <div class="top flex">
              <div
                class="buttonr"
                :style="{
                  background:
                    'QK09' in infoList
                      ? infoList.QK09 && !infoList.QK09.TS
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.QK09
                    ? toDetail(
                        2,
                        'QK09',
                        infoList.QK09_node_string,
                        '',
                        'QK09设定值'
                      )
                    : ''
                "
              >
                K
              </div>
              <div
                class="buttonx right"
                :style="{
                  background:
                    'XK0J' in infoList
                      ? infoList.XK0J && !infoList.XK0J.RM
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.XK0J
                    ? toDetail(
                        1,
                        'XK0J',
                        infoList.XK0J_node_string,
                        '',
                        '一级右主汽温度调节'
                      )
                    : ''
                "
              >
                X
              </div>
              <!-- <div class="buttonx right">X</div> -->
              <div
                class="buttonr right"
                :style="{
                  background:
                    'MAN7' || 'MAN8' || 'MAN9' || 'MAN10' in infoList
                      ? infoList.MAN7.RM == 1 ||
                        infoList.MAN8.RM == 1 ||
                        infoList.MAN9.RM == 1 ||
                        infoList.MAN10.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="toCompon(0, 'CFB_MANQW', 4)"
              >
                A
              </div>
            </div>
            <div class="float1 flex">
              <div class="valve"></div>
              <div class="float2">
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'TE101D_B',
                        infoList.TE101D_B_node_string,
                        'TE101D_BVV1',
                        '给水温度'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.TE101D_B,
                        'TE101D_B',
                        infoList.TE101D_B_node_string,
                        'TE101D_BVV1'
                      )
                    "
                  >
                    {{ "TE101D_B" in infoList ? infoList.TE101D_B : 0 }}
                  </div>
                  <div class="num3">℃</div>
                </div>
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'PT101D_B',
                        infoList.PT101D_B_node_string,
                        'PT101D_BVV1',
                        '给水压力'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.PT101D_B,
                        'PT101D_B',
                        infoList.PT101D_B_node_string,
                        'PT101D_BVV1'
                      )
                    "
                  >
                    {{ "PT101D_B" in infoList ? infoList.PT101D_B : 0 }}
                  </div>
                  <div class="num3">MPa</div>
                </div>
              </div>
            </div>
            <div class="float3 flex">
              <div
                class="buttonx"
                :style="{
                  background:
                    'XK01' in infoList
                      ? infoList.XK01 && infoList.XK01.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.XK01
                    ? toDetail(
                        1,
                        'XK01',
                        infoList.XK01_node_string,
                        '',
                        '汽包液位调节'
                      )
                    : ''
                "
              >
                X
              </div>
              <div
                class="buttonr right"
                :style="{
                  background:
                    'XK0M' in infoList
                      ? infoList.XK0M && infoList.XK0M.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.XK0M
                    ? toDetail(
                        1,
                        'XK0M',
                        infoList.XK0M_node_string,
                        '',
                        '主给水阀门调节先控'
                      )
                    : ''
                "
              >
                X
              </div>
              <div
                class="buttonr right"
                :style="{
                  background:
                    'XK0N' in infoList
                      ? infoList.XK0N && infoList.XK0N.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.XK0N
                    ? toDetail(
                        1,
                        'XK0N',
                        infoList.XK0N_node_string,
                        '',
                        '副给水阀门调节先控'
                      )
                    : ''
                "
              >
                X
              </div>
              <div
                class="buttonr right"
                :style="{
                  background:
                    'MAN6' in infoList
                      ? infoList.MAN5.RM == 1 || infoList.MAN6.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="toCompon(0, 'CFB_MANGS', 2)"
              >
                A
              </div>
              <div class="float4 flex">
                <div
                  class="num1"
                  @click="
                    toCompon(
                      2,
                      'FT101D_B',
                      infoList.FT101D_B_node_string,
                      'FT101D_BVV1',
                      '给水流量'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.FT101D_B,
                      'FT101D_B',
                      infoList.FT101D_B_node_string,
                      'FT101D_BVV1'
                    )
                  "
                >
                  {{ "FT101D_B" in infoList ? infoList.FT101D_B : 0 }}
                </div>
                <div class="num3">t/h</div>
              </div>
            </div>
          </div>
        </div>
        <div class="two flex">
          <div>
            <div class="up">
              <div class="flex">
                <div class="float1 flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'KF_ECFBP_B',
                        infoList.KF_ECFBP_B_node_string,
                        'KF_ECFBP_BVV1',
                        '二次风机变频反馈'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.KF_ECFBP_B,
                        'KF_ECFBP_B',
                        infoList.KF_ECFBP_B_node_string,
                        'KF_ECFBP_BVV1'
                      )
                    "
                  >
                    {{ "KF_ECFBP_B" in infoList ? infoList.KF_ECFBP_B : 0 }}
                  </div>
                  <div class="num3">m3/h</div>
                </div>
                <div class="float2">
                  <div class="flex">
                    <div
                      class="num1"
                      @click="
                        toCompon(
                          2,
                          'ECFBPAO_B',
                          infoList.ECFBPAO_B_node_string,
                          'ECFBPAO_BVV1',
                          '1#二次风机变频阀位输出'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.ECFBPAO_B,
                          'ECFBPAO_B',
                          infoList.ECFBPAO_B_node_string,
                          'ECFBPAO_BVV1'
                        )
                      "
                    >
                      {{ "ECFBPAO_B" in infoList ? infoList.ECFBPAO_B : 0 }}
                    </div>
                    <div class="num3">%</div>
                  </div>
                  <div class="flex">
                    <div
                      class="num1"
                      @click="
                        toCompon(
                          2,
                          'KF_ECFDB_B',
                          infoList.KF_ECFDB_B_node_string,
                          'KF_ECFDB_BVV1',
                          '二次风机挡板反馈'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.KF_ECFDB_B,
                          'KF_ECFDB_B',
                          infoList.KF_ECFDB_B_node_string,
                          'KF_ECFDB_BVV1'
                        )
                      "
                    >
                      {{ "KF_ECFDB_B" in infoList ? infoList.KF_ECFDB_B : 0 }}
                    </div>
                    <div class="num3">%</div>
                  </div>
                </div>
                <div class="fan float3"></div>
                <div class="float4 flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'II_ECFJ_B',
                        infoList.II_ECFJ_B_node_string,
                        'II_ECFJ_BVV1',
                        '1#二次风机电流'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.II_ECFJ_B,
                        'II_ECFJ_B',
                        infoList.II_ECFJ_B_node_string,
                        'II_ECFJ_BVV1'
                      )
                    "
                  >
                    {{ "II_ECFJ_B" in infoList ? infoList.II_ECFJ_B : 0 }}
                  </div>
                  <div class="num3">A</div>
                </div>
                <div>
                  <div class="valve float5"></div>
                  <div class="float6">
                    <div class="flex">
                      <div
                        class="num1"
                        @click="
                          toCompon(
                            2,
                            'ECFDBAO_B',
                            infoList.ECFDBAO_B_node_string,
                            'ECFDBAO_BVV1',
                            '1#二次风机挡板阀位输出'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.ECFDBAO_B,
                            'ECFDBAO_B',
                            infoList.ECFDBAO_B_node_string,
                            'ECFDBAO_BVV1'
                          )
                        "
                      >
                        {{ "ECFDBAO_B" in infoList ? infoList.ECFDBAO_B : 0 }}
                      </div>
                      <div class="num3">%</div>
                    </div>
                    <div class="flex">
                      <div
                        class="num1"
                        @click="
                          toCompon(
                            2,
                            'ECFDBAO_B',
                            infoList.ECFDBAO_B_node_string,
                            'ECFDBAO_BVV1',
                            '1#二次风机挡板阀位输出'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.ECFDBAO_B,
                            'ECFDBAO_B',
                            infoList.ECFDBAO_B_node_string,
                            'ECFDBAO_BVV1'
                          )
                        "
                      >
                        {{ "ECFDBAO_B" in infoList ? infoList.ECFDBAO_B : 0 }}
                      </div>
                      <div class="num3">%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="down">
              <div class="flex">
                <div class="float1 flex">
                  <div class="num1">0.0</div>
                  <div class="num3">m3/h</div>
                </div>
                <div class="float2">
                  <div class="flex">
                    <div
                      class="num1"
                      @click="
                        toCompon(
                          2,
                          'KF_ECFBP2_B',
                          infoList.KF_ECFBP2_B_node_string,
                          'KF_ECFBP2_BVV1',
                          '2#二次风变频反馈'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.KF_ECFBP2_B,
                          'KF_ECFBP2_B',
                          infoList.KF_ECFBP2_B_node_string,
                          'KF_ECFBP2_BVV1'
                        )
                      "
                    >
                      {{ "KF_ECFBP2_B" in infoList ? infoList.KF_ECFBP2_B : 0 }}
                    </div>
                    <div class="num3">%</div>
                  </div>
                  <div class="flex">
                    <div
                      class="num1"
                      @click="
                        toCompon(
                          2,
                          'ECFBPAO2_B',
                          infoList.ECFBPAO2_B_node_string,
                          'ECFBPAO2_BVV1',
                          '2#二次风机变频阀位输出'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.ECFBPAO2_B,
                          'ECFBPAO2_B',
                          infoList.ECFBPAO2_B_node_string,
                          'ECFBPAO2_BVV1'
                        )
                      "
                    >
                      {{ "ECFBPAO2_B" in infoList ? infoList.ECFBPAO2_B : 0 }}
                    </div>
                    <div class="num3">%</div>
                  </div>
                </div>
                <div class="fan float3"></div>
                <div class="float4 flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'II_ECFJ2_B',
                        infoList.II_ECFJ2_B_node_string,
                        'II_ECFJ2_BVV1',
                        '2#二次风机电流'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.II_ECFJ2_B,
                        'II_ECFJ2_B',
                        infoList.II_ECFJ2_B_node_string,
                        'II_ECFJ2_BVV1'
                      )
                    "
                  >
                    {{ "II_ECFJ2_B" in infoList ? infoList.II_ECFJ2_B : 0 }}
                  </div>
                  <div class="num3">A</div>
                </div>
                <div>
                  <div class="valve float5"></div>
                  <div class="float6">
                    <div class="flex">
                      <div
                        class="num1"
                        @click="
                          toCompon(
                            2,
                            'KF_ECFDB2_B',
                            infoList.KF_ECFDB2_B_node_string,
                            'KF_ECFDB2_BVV1',
                            '2#二次风挡板反馈'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.KF_ECFDB2_B,
                            'KF_ECFDB2_B',
                            infoList.KF_ECFDB2_B_node_string,
                            'KF_ECFDB2_BVV1'
                          )
                        "
                      >
                        {{
                          "KF_ECFDB2_B" in infoList ? infoList.KF_ECFDB2_B : 0
                        }}
                      </div>
                      <div class="num3">%</div>
                    </div>
                    <div class="flex">
                      <div
                        class="num1"
                        @click="
                          toCompon(
                            2,
                            'ECFDBAO2_B',
                            infoList.ECFDBAO2_B_node_string,
                            'ECFDBAO2_BVV1',
                            '2#二次风机挡板阀位输出'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.ECFDBAO2_B,
                            'ECFDBAO2_B',
                            infoList.ECFDBAO2_B_node_string,
                            'ECFDBAO2_BVV1'
                          )
                        "
                      >
                        {{ "ECFDBAO2_B" in infoList ? infoList.ECFDBAO2_B : 0 }}
                      </div>
                      <div class="num3">%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="buttons">
            <div class="flex">
              <div class="button1">
                <div
                  class="word1"
                  @click="
                    toIpt(
                      infoList.O2_ECF_QY,
                      '按钮',
                      'O2_ECF_QY',
                      infoList.O2_ECF_QY_node_string
                    )
                  "
                >
                  {{
                    "O2_ECF_QY" in infoList
                      ? infoList.O2_ECF_QY
                        ? "氧量投用"
                        : "氧量切除"
                      : "氧量切除"
                  }}
                </div>
              </div>
              <div
                class="buttonx right"
                :style="{
                  background:
                    'XK0Q' in infoList
                      ? infoList.XK0Q && infoList.XK0Q.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.XK0Q
                    ? toDetail(
                        1,
                        'XK0Q',
                        infoList.XK0Q_node_string,
                        '',
                        '烟气含氧量调节先控'
                      )
                    : ''
                "
              >
                X
              </div>
            </div>
            <div class="line1 flex">
              <div class="button2">
                <div
                  class="word2"
                  @click="
                    toIpt(
                      infoList.ECF_CWSEL,
                      '按钮',
                      'ECF_CWSEL',
                      infoList.ECF_CWSEL_node_string
                    )
                  "
                >
                  {{
                    "ECF_CWSEL" in infoList
                      ? infoList.ECF_CWSEL
                        ? "床温投用"
                        : "床温切除"
                      : 0
                  }}
                </div>
              </div>
              <div
                class="buttonr right"
                :style="{
                  background:
                    'RSF0E' in infoList
                      ? !infoList.RSF0E.TS
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.RSF0E
                    ? toDetail(
                        3,
                        'RSF0E',
                        infoList.RSF0E_node_string,
                        '',
                        '床温二次风软伺服'
                      )
                    : ''
                "
              >
                R
              </div>
            </div>
            <div class="line1 flex">
              <div
                class="buttonr"
                :style="{
                  background:
                    'RSF07' in infoList
                      ? !infoList.RSF07.TS
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.RSF07
                    ? toDetail(
                        3,
                        'RSF07',
                        infoList.RSF07_node_string,
                        '',
                        '二次风电流纠偏'
                      )
                    : ''
                "
              >
                R
              </div>
              <div
                class="buttonx right"
                :style="{
                  background:
                    'XK0D' in infoList
                      ? infoList.XK0D && !infoList.XK0D.RM
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.XK0D
                    ? toDetail(
                        1,
                        'XK0D',
                        infoList.XK0D_node_string,
                        '',
                        '二次风挡板调节'
                      )
                    : ''
                "
              >
                X
              </div>
              <div
                class="buttonx right"
                :style="{
                  background:
                    'MAN17' || 'MAN18' || 'MAN26' || 'MAN30' in infoList
                      ? infoList.MAN17.RM == 1 ||
                        infoList.MAN18.RM == 1 ||
                        infoList.MAN26.RM == 1 ||
                        infoList.MAN30.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="toCompon(0, 'CFB_MANECF', 4)"
              >
                A
              </div>
              <div
                class="buttonx right"
                :style="{
                  background:
                    'XK0E' in infoList
                      ? infoList.XK0E && infoList.XK0E.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.XK0E
                    ? toDetail(
                        1,
                        'XK0E',
                        infoList.XK0E_node_string,
                        '',
                        '二次风变频调节'
                      )
                    : ''
                "
              >
                X
              </div>
            </div>
            <div class="words" @click="toCompon(7)" style="cursor: pointer">
              二次风机
            </div>
          </div>
        </div>
        <div class="three flex">
          <div>
            <div class="up">
              <div class="flex">
                <div class="float1 flex">
                  <div class="num1">0</div>
                  <div class="num3">m3/h</div>
                </div>
                <div class="float2">
                  <div class="flex">
                    <div
                      class="num1"
                      @click="
                        toCompon(
                          2,
                          'KF_YCFBP_B',
                          infoList.KF_YCFBP_B_node_string,
                          'KF_YCFBP_BVV1',
                          '一次风机变频反馈'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.KF_YCFBP_B,
                          'KF_YCFBP_B',
                          infoList.KF_YCFBP_B_node_string,
                          'KF_YCFBP_BVV1'
                        )
                      "
                    >
                      {{ "KF_YCFBP_B" in infoList ? infoList.KF_YCFBP_B : 0 }}
                    </div>
                    <div class="num3">%</div>
                  </div>
                  <div class="flex">
                    <div
                      class="num1"
                      @click="
                        toCompon(
                          2,
                          'YCFBPAO_B',
                          infoList.YCFBPAO_B_node_string,
                          'YCFBPAO_BVV1',
                          '1#一次风机变频阀位输出'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.YCFBPAO_B,
                          'YCFBPAO_B',
                          infoList.YCFBPAO_B_node_string,
                          'YCFBPAO_BVV1'
                        )
                      "
                    >
                      {{ "YCFBPAO_B" in infoList ? infoList.YCFBPAO_B : 0 }}
                    </div>
                    <div class="num3">%</div>
                  </div>
                </div>
                <div class="fan float3"></div>
                <div class="float4 flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'II_YCFJ_B',
                        infoList.II_YCFJ_B_node_string,
                        'II_YCFJ_BVV1',
                        '1#一次风机电流'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.II_YCFJ_B,
                        'II_YCFJ_B',
                        infoList.II_YCFJ_B_node_string,
                        'II_YCFJ_BVV1'
                      )
                    "
                  >
                    {{ "II_YCFJ_B" in infoList ? infoList.II_YCFJ_B : 0 }}
                  </div>
                  <div class="num3">A</div>
                </div>
                <div>
                  <div class="valve float5"></div>
                  <div class="float6">
                    <div class="flex">
                      <div
                        class="num1"
                        @click="
                          toCompon(
                            2,
                            'KF_YCFDB_B',
                            infoList.KF_YCFDB_B_node_string,
                            'KF_YCFDB_BVV1',
                            '一次风机挡板反馈'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.KF_YCFDB_B,
                            'KF_YCFDB_B',
                            infoList.KF_YCFDB_B_node_string,
                            'KF_YCFDB_BVV1'
                          )
                        "
                      >
                        {{ "KF_YCFDB_B" in infoList ? infoList.KF_YCFDB_B : 0 }}
                      </div>
                      <div class="num3">%</div>
                    </div>
                    <div class="flex">
                      <div
                        class="num1"
                        @click="
                          toCompon(
                            2,
                            'YCFDBAO_B',
                            infoList.YCFDBAO_B_node_string,
                            'YCFDBAO_BVV1',
                            '1#一次风机挡板阀位输出'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.YCFDBAO_B,
                            'YCFDBAO_B',
                            infoList.YCFDBAO_B_node_string,
                            'YCFDBAO_BVV1'
                          )
                        "
                      >
                        {{ "YCFDBAO_B" in infoList ? infoList.YCFDBAO_B : 0 }}
                      </div>
                      <div class="num3">%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="down">
              <div class="flex">
                <div class="float1 flex">
                  <div class="num1">0</div>
                  <div class="num3">m3/h</div>
                </div>
                <div class="float2">
                  <div class="flex">
                    <div
                      class="num1"
                      @click="
                        toCompon(
                          2,
                          'KF_YCFBP2_B',
                          infoList.KF_YCFBP2_B_node_string,
                          'KF_YCFBP2_BVV1',
                          '2#一次风变频反馈'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.KF_YCFBP2_B,
                          'KF_YCFBP2_B',
                          infoList.KF_YCFBP2_B_node_string,
                          'KF_YCFBP2_BVV1'
                        )
                      "
                    >
                      {{ "KF_YCFBP2_B" in infoList ? infoList.KF_YCFBP2_B : 0 }}
                    </div>
                    <div class="num3">%</div>
                  </div>
                  <div class="flex">
                    <div
                      class="num1"
                      @click="
                        toCompon(
                          2,
                          'YCFBPAO2_B',
                          infoList.YCFBPAO2_B_node_string,
                          'YCFBPAO2_BVV1',
                          '2#一次风机变频阀位输出'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.YCFBPAO2_B,
                          'YCFBPAO2_B',
                          infoList.YCFBPAO2_B_node_string,
                          'YCFBPAO2_BVV1'
                        )
                      "
                    >
                      {{ "YCFBPAO2_B" in infoList ? infoList.YCFBPAO2_B : 0 }}
                    </div>
                    <div class="num3">%</div>
                  </div>
                </div>
                <div class="fan float3"></div>
                <div class="float4 flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'II_YCFJ2_B',
                        infoList.II_YCFJ2_B_node_string,
                        'II_YCFJ2_BVV1',
                        '2#一次风机电流'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.II_YCFJ2_B,
                        'II_YCFJ2_B',
                        infoList.II_YCFJ2_B_node_string,
                        'II_YCFJ2_BVV1'
                      )
                    "
                  >
                    {{ "II_YCFJ2_B" in infoList ? infoList.II_YCFJ2_B : 0 }}
                  </div>
                  <div class="num3">A</div>
                </div>
                <div>
                  <div class="valve float5"></div>
                  <div class="float6">
                    <div class="flex">
                      <div
                        class="num1"
                        @click="
                          toCompon(
                            2,
                            'KF_YCFDB2_B',
                            infoList.KF_YCFDB2_B_node_string,
                            'KF_YCFDB2_BVV1',
                            '2#一次风挡板反馈'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.KF_YCFDB2_B,
                            'KF_YCFDB2_B',
                            infoList.KF_YCFDB2_B_node_string,
                            'KF_YCFDB2_BVV1'
                          )
                        "
                      >
                        {{
                          "KF_YCFDB2_B" in infoList ? infoList.KF_YCFDB2_B : 0
                        }}
                      </div>
                      <div class="num3">%</div>
                    </div>
                    <div class="flex">
                      <div
                        class="num1"
                        @click="
                          toCompon(
                            2,
                            'YCFDBAO2_B',
                            infoList.YCFDBAO2_B_node_string,
                            'YCFDBAO2_BVV1',
                            '2#一次风机挡板阀位输出'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.YCFDBAO2_B,
                            'YCFDBAO2_B',
                            infoList.YCFDBAO2_B_node_string,
                            'YCFDBAO2_BVV1'
                          )
                        "
                      >
                        {{ "YCFDBAO2_B" in infoList ? infoList.YCFDBAO2_B : 0 }}
                      </div>
                      <div class="num3">%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="buttons">
            <div
              class="buttonr float1"
              :style="{
                background:
                  'RSF08' in infoList
                    ? !infoList.RSF08.TS
                      ? '#2AFC30'
                      : 'red'
                    : 'red',
              }"
              @click="
                infoList.RSF08
                  ? toDetail(
                      3,
                      'RSF08',
                      infoList.RSF08_node_string,
                      '',
                      '一次风机电流纠偏'
                    )
                  : ''
              "
            >
              R
            </div>
            <div class="line1 flex">
              <div
                class="buttonr"
                :style="{
                  background:
                    'RSF04' in infoList
                      ? !infoList.RSF04.TS
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.RSF04
                    ? toDetail(
                        3,
                        'RSF04',
                        infoList.RSF04_node_string,
                        '',
                        '一次风变频软伺服'
                      )
                    : ''
                "
              >
                R
              </div>
              <div
                class="buttonx right"
                :style="{
                  background:
                    'MAN15' || 'MAN16' || 'MAN25' || 'MAN27' in infoList
                      ? infoList.MAN15.RM == 1 ||
                        infoList.MAN16.RM == 1 ||
                        infoList.MAN25.RM == 1 ||
                        infoList.MAN27.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="toCompon(0, 'CFB_MANYCF', 4)"
              >
                A
              </div>
              <div
                class="buttonr right"
                :style="{
                  background:
                    'RSF05' in infoList
                      ? !infoList.RSF05.TS
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.RSF05
                    ? toDetail(
                        3,
                        'RSF05',
                        infoList.RSF05_node_string,
                        '',
                        '一次风挡板软伺服'
                      )
                    : ''
                "
              >
                R
              </div>
            </div>
            <div
              class="words float2"
              @click="toCompon(8)"
              style="cursor: pointer"
            >
              一次风机
            </div>
          </div>
        </div>
        <div class="four flex">
          <div>
            <div class="up flex">
              <div>
                <div class="valve float1"></div>
                <div class="float2">
                  <div class="flex">
                    <div
                      class="num1"
                      @click="
                        toCompon(
                          2,
                          'KF_YFDB1_B',
                          infoList.KF_YFDB1_B_node_string,
                          'KF_YFDB1_BVV1',
                          '1#引风机挡板反馈'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.KF_YFDB1_B,
                          'KF_YFDB1_B',
                          infoList.KF_YFDB1_B_node_string,
                          'KF_YFDB1_BVV1'
                        )
                      "
                    >
                      {{ "KF_YFDB1_B" in infoList ? infoList.KF_YFDB1_B : 0 }}
                    </div>
                    <div class="num3">%</div>
                  </div>
                  <div class="flex">
                    <div
                      class="num1"
                      @click="
                        toCompon(
                          2,
                          'YFDBAO1_B',
                          infoList.YFDBAO1_B_node_string,
                          'YFDBAO1_BVV1',
                          '1#引风挡板调节输出'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.YFDBAO1_B,
                          'YFDBAO1_B',
                          infoList.YFDBAO1_B_node_string,
                          'YFDBAO1_BVV1'
                        )
                      "
                    >
                      {{ "YFDBAO1_B" in infoList ? infoList.YFDBAO1_B : 0 }}
                    </div>
                    <div class="num3">%</div>
                  </div>
                </div>
              </div>
              <div class="float3">
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'KF_YFBP1_B',
                        infoList.KF_YFBP1_B_node_string,
                        'KF_YFBP1_BVV1',
                        '1#引风机变频反馈'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.KF_YFBP1_B,
                        'KF_YFBP1_B',
                        infoList.KF_YFBP1_B_node_string,
                        'KF_YFBP1_BVV1'
                      )
                    "
                  >
                    {{ "KF_YFBP1_B" in infoList ? infoList.KF_YFBP1_B : 0 }}
                  </div>
                  <div class="num3">%</div>
                </div>
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'YFBPAO1_B',
                        infoList.YFBPAO1_B_node_string,
                        'YFBPAO1_BVV1',
                        '1#引风变频阀位输出'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.YFBPAO1_B,
                        'YFBPAO1_B',
                        infoList.YFBPAO1_B_node_string,
                        'YFBPAO1_BVV1'
                      )
                    "
                  >
                    {{ "YFBPAO1_B" in infoList ? infoList.YFBPAO1_B : 0 }}
                  </div>
                  <div class="num3">%</div>
                </div>
              </div>
              <div class="fan float4"></div>
              <div class="float5 flex">
                <div
                  class="num1"
                  @click="
                    toCompon(
                      2,
                      'II_YFJ1_B',
                      infoList.II_YFJ1_B_node_string,
                      'II_YFJ1_BVV1',
                      '1#引风机电流'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.II_YFJ1_B,
                      'II_YFJ1_B',
                      infoList.II_YFJ1_B_node_string,
                      'II_YFJ1_BVV1'
                    )
                  "
                >
                  {{ "II_YFJ1_B" in infoList ? infoList.II_YFJ1_B : 0 }}
                </div>
                <div class="num3">A</div>
              </div>
            </div>
            <div class="down flex">
              <div>
                <div class="valve float1"></div>
                <div class="float2">
                  <div class="flex">
                    <div
                      class="num1"
                      @click="
                        toCompon(
                          2,
                          'KF_YFDB2_B',
                          infoList.KF_YFDB2_B_node_string,
                          'KF_YFDB2_BVV1',
                          '2#引风机挡板反馈'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.KF_YFDB2_B,
                          'KF_YFDB2_B',
                          infoList.KF_YFDB2_B_node_string,
                          'KF_YFDB2_BVV1'
                        )
                      "
                    >
                      {{ "KF_YFDB2_B" in infoList ? infoList.KF_YFDB2_B : 0 }}
                    </div>
                    <div class="num3">%</div>
                  </div>
                  <div class="flex">
                    <div
                      class="num1"
                      @click="
                        toCompon(
                          2,
                          'YFDBAO2_B',
                          infoList.YFDBAO2_B_node_string,
                          'YFDBAO2_BVV1',
                          '2#引风挡板调节输出'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.YFDBAO2_B,
                          'YFDBAO2_B',
                          infoList.YFDBAO2_B_node_string,
                          'YFDBAO2_BVV1'
                        )
                      "
                    >
                      {{ "YFDBAO2_B" in infoList ? infoList.YFDBAO2_B : 0 }}
                    </div>
                    <div class="num3">%</div>
                  </div>
                </div>
              </div>
              <div class="float3">
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'KF_YFBP2_B',
                        infoList.KF_YFBP2_B_node_string,
                        'KF_YFBP2_BVV1',
                        '2#引风机变频反馈'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.KF_YFBP2_B,
                        'KF_YFBP2_B',
                        infoList.KF_YFBP2_B_node_string,
                        'KF_YFBP2_BVV1'
                      )
                    "
                  >
                    {{ "KF_YFBP2_B" in infoList ? infoList.KF_YFBP2_B : 0 }}
                  </div>
                  <div class="num3">%</div>
                </div>
                <div class="flex">
                  <div
                    class="num1"
                    @click="
                      toCompon(
                        2,
                        'YFBPAO2_B',
                        infoList.YFBPAO2_B_node_string,
                        'YFBPAO2_BVV1',
                        '2#引风变频阀位输出'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.YFBPAO2_B,
                        'YFBPAO2_B',
                        infoList.YFBPAO2_B_node_string,
                        'YFBPAO2_BVV1'
                      )
                    "
                  >
                    {{ "YFBPAO2_B" in infoList ? infoList.YFBPAO2_B : 0 }}
                  </div>
                  <div class="num3">%</div>
                </div>
              </div>
              <div class="fan float4"></div>
              <div class="float5 flex">
                <div
                  class="num1"
                  @click="
                    toCompon(
                      2,
                      'II_YFJ2_B',
                      infoList.II_YFJ2_B_node_string,
                      'II_YFJ2_BVV1',
                      '2#引风机电流'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.II_YFJ2_B,
                      'II_YFJ2_B',
                      infoList.II_YFJ2_B_node_string,
                      'II_YFJ2_BVV1'
                    )
                  "
                >
                  {{ "II_YFJ2_B" in infoList ? infoList.II_YFJ2_B : 0 }}
                </div>
                <div class="num3">A</div>
              </div>
            </div>
          </div>
          <div class="buttons">
            <div class="float1 flex">
              <div
                class="buttonr"
                :style="{
                  background:
                    'QK04' in infoList
                      ? infoList.QK04 && !infoList.QK04.TS
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.QK04
                    ? toDetail(
                        2,
                        'QK04',
                        infoList.QK04_node_string,
                        '',
                        '送风前馈引风'
                      )
                    : ''
                "
              >
                K
              </div>
              <div
                class="buttonr right"
                :style="{
                  background:
                    'RSF0H' in infoList
                      ? infoList.RSF0H && !infoList.RSF0H.TS
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.RSF0H
                    ? toDetail(
                        3,
                        'RSF0H',
                        infoList.RSF0H_node_string,
                        '',
                        '引风机电流纠偏'
                      )
                    : ''
                "
              >
                R
              </div>
            </div>
            <div class="line1 flex">
              <div
                class="buttonx"
                :style="{
                  background:
                    'XK05' in infoList
                      ? infoList.XK05.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.XK05
                    ? toDetail(
                        1,
                        'XK05',
                        infoList.XK05_node_string,
                        '',
                        '负压挡板调节'
                      )
                    : ''
                "
              >
                X
              </div>
              <div
                class="buttonx right"
                :style="{
                  background:
                    'MAN19' || 'MAN20' || 'MAN21' || 'MAN22' in infoList
                      ? infoList.MAN19.RM == 1 ||
                        infoList.MAN20.RM == 1 ||
                        infoList.MAN21.RM == 1 ||
                        infoList.MAN22.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="toCompon(0, 'CFB_MANYF', 4)"
              >
                A
              </div>
              <div
                class="buttonx right"
                :style="{
                  background:
                    'XK06' in infoList
                      ? infoList.XK06 && infoList.XK06.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.XK06
                    ? toDetail(
                        1,
                        'XK06',
                        infoList.XK06_node_string,
                        '',
                        '负压变频调节'
                      )
                    : ''
                "
              >
                X
              </div>
            </div>
            <div
              class="words float2"
              @click="toCompon(9)"
              style="cursor: pointer"
            >
              引风机
            </div>
          </div>
        </div>
      </div>
    </div>
    <inputVal ref="inputVal"></inputVal>
    <Historical
      v-if="isHshow"
      @sendStatus="isClose"
      :historyname="historyname"
      :node="nodename"
      :Lkname="Aname"
      :chName="chName"
      :infoList="infoList"
    ></Historical>
    <Parameter04
      v-if="Parameter04"
      @sendStatus="isClose"
      :infoList="infoList"
    ></Parameter04>
    <manyManual
      v-if="manyManual"
      :titname="Manualtitname"
      :numM="Manualnode"
      @sendStatus="isClose"
      :infoList="infoList"
    ></manyManual>
    <Parameter022
      v-if="Parameter022"
      @sendStatus="isClose"
      :infoList="infoList"
    ></Parameter022>
    <Parameter08
      v-if="Parameter08"
      @sendStatus="isClose"
      :infoList="infoList"
    ></Parameter08>
    <Parameter02
      v-if="Parameter02"
      @sendStatus="isClose"
      :infoList="infoList"
    ></Parameter02>
    <Parameter07
      v-if="Parameter07"
      @sendStatus="isClose"
      :infoList="infoList"
    ></Parameter07>
    <Parameter06
      v-if="Parameter06"
      @sendStatus="isClose"
      :infoList="infoList"
    ></Parameter06>
    <Parameter05
      v-if="Parameter05"
      @sendStatus="isClose"
      :infoList="infoList"
    ></Parameter05>
    <Parameter03
      v-if="Parameter03"
      @sendStatus="isClose"
      :infoList="infoList"
    ></Parameter03>
    <Parameter01
      v-if="Parameter01"
      @sendStatus="isClose"
      :infoList="infoList"
    ></Parameter01>
    <Cfbcysz
      v-if="flag == 5 ? (isComShow = true) : (isComShow = false)"
      @sendStatus="isClose"
      :infoList="infoList"
    >
    </Cfbcysz>
    <Cfbserve
      v-if="flag == 7 ? (isComShow = true) : (isComShow = false)"
      @sendStatus="isClose"
      :infoList="infoList"
    ></Cfbserve>
  </div>
</template>

<script>
import index from "./_index/index.js";
import inputVal from "@/components/inputVal.vue"; //输入框组件
import Historical from "@/components/Historicaljrl.vue"; //历史趋势
// import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
import Parameter01 from "@/views/CfbBoiler/CfbParameter1/index.vue"; //参数01
import Parameter02 from "@/views/CfbBoiler/CfbParameter2/index.vue"; //参数02-1
import Parameter022 from "@/views/CfbBoiler/CfbParameterSmoke2/index.vue"; //参数02-2
import Parameter08 from "@/views/CfbBoiler/CfbParameter8/index.vue"; //参数08组件
import Parameter04 from "@/views/CfbBoiler/CfbParameter4/index.vue"; //参数04组件
import Parameter07 from "@/views/CfbBoiler/CfbParameter7/index.vue"; //参数07组件
import Parameter06 from "@/views/CfbBoiler/CfbParameter6/index.vue"; //参数06组件
import Parameter05 from "@/views/CfbBoiler/CfbParameter5/index.vue"; //参数05组件
import Parameter03 from "@/views/CfbBoiler/CfbParameter3/index.vue"; //参数03组件
import manyManual from "@/components/manyManual1.vue"; //多手操器
import Cfbcysz from "@/views/CfbBoiler/CfbCommonSetting/index.vue"; //常用设置组件
import Cfbserve from "@/views/CfbBoiler/CfbOnlineService/index.vue"; //客服组件
// import  from ;
export default {
  name: "Cfbindex",
  props: {
    infoList: {
      type: Object,
      default: () => {
        return {}; // 默认值
      },
    },
  },
  components: {
    inputVal,
    Historical,
    // Firstcontrol,
    Parameter022,
    Parameter08,
    Parameter04,
    Parameter02,
    Parameter07,
    Parameter06,
    Parameter05,
    Parameter03,
    Parameter01,
    manyManual,
    Cfbcysz,
    Cfbserve,
  },
  data() {
    return {
      chName: "",
      projectData: {},
      Parameter04: false,
      Parameter022: false,
      Parameter08: false,
      Parameter02: false,
      Parameter07: false,
      Parameter06: false,
      Parameter05: false,
      Parameter03: false,
      Parameter01: false,
      Cfbcysz: false,
      manyManual: false,
      spotArr: [],
      grouptime: null,
      Jrconst: false,
      JrSecurity: false,
      flag: 0,
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      isComShow: false,
      historyname: "",
      nodename: "",
      Observername: "",
      Observernode: "",
      Rsfname: "",
      Rsfnode: "",
      bodyHeight: "",
      Firstcontrolname: "",
      Firstcontrolnode: "",
      Firstcontroltitname: "",
      fullscreenLoading: true,
      isHshow: false,
      isOshow: false,
      isRshow: false,
      Aname: "",
    };
  },
  mixins: [index],
  watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
        this.config = {
          data: [n.PT102D1_B] || 0,
          shape: "round",
          colors: ["#43ff44", "#43ff44"],
          waveOpacity: "1",
          waveNum: "50",
          formatter: "{value}mpa",
        };
      },

      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  created() {
    this.config = {
      data: [this.infoList.PT102D1_B || 0],
      shape: "round",
      colors: ["#43ff44", "#43ff44"],
      waveOpacity: "1",
      waveNum: "50",
      formatter: "{value}mpa",
    };
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
    this.$bus.$on("sendMsg", (msg) => {
      // 底部组件发送来的消息
      this.flag = msg;
      if (this.flag == 0) {
        return this.$router.push({
          path: "/Cfbindex",
        });
      } else if (this.flag == 1) {
        return this.$router.push({
          path: "/CfbDesulphurization",
        });
      } else if (this.flag == 2) {
        return this.$router.push({
          path: "/CfbOperationAssessment",
        });
      } else if (this.flag == 3) {
        return this.$router.push({
          path: "/CfbSwitchPage",
        });
      } else if (this.flag == 4) {
        return this.$router.push({
          path: "/CfbSecurity",
        });
      } else if (this.flag == 6) {
        return this.$router.push({
          path: "/CfbDataStatistics",
        });
      }
    });
  },
  mounted() {},
  methods: {
    // utilFun() {
    //   let that = this;
    //   this.$nextTick(function () {
    //     // console.log(that.infoList.PT102D1_B);
    //   });
    //   return [this.infoList.PT102D1_B] || [0];
    // },
    // 打开趋势组图页面
    qsGroup() {
      this.$router.push({
        path: "/trendGroup",
      });
    },
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }

        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
    // 打开下置输入窗口
    toIpt(data, name, historyname, node, type) {
      if (!this.authInfo[1]) {
        let str = this.authInfo[0];
        // 判断是否为调试还是查看权限
        if (str.charAt(str.length - 1) == "r") {
          this.$message.error("暂无操作权限");
        }
      } else {
        this.$refs.inputVal.open(data, name, historyname, node, type);
      }
    },
    // 打开历史趋势窗口
    toHistory(data) {
      this.$refs.history.open(data);
    },
  },
};
</script>

<style lang="scss" scoped>
.fl-t {
  float: left;
}

.fr-t {
  float: right;
}

.title-hang {
  height: 5vh;
  width: 96vw;
  font-family: MicrosoftYaHei;
  font-size: 2vh;
  font-weight: normal;
  font-stretch: normal;
  line-height: 2vh;
  letter-spacing: 0vh;
}

.zhuangshizb {
  width: 14vw;
  height: 2vh;
  background-image: url("~@/assets/images/rfl_evaluate06.png");
  background-size: 100% 100%;
  margin-left: 3.5vw;
  margin-top: 1.5vh;
}

.zhuangshizj {
  width: 68vw;
  height: 4vh;
  line-height: 4vh;
  margin-top: 1vh;
  text-align: center;
  // background-image: url("~@/assets/images/rfl_evaluate05.png");
  background-size: 100% 100%;
  // margin-left: 5vw;
  font-family: MicrosoftYaHei;
  font-size: 3vh;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0vh;
  color: #0ef7ff;
}

.zhuangshiyb {
  width: 14vw;
  height: 2vh;
  background-image: url("~@/assets/images/rfl_evaluate04.png");
  background-size: 100% 100%;
  margin-left: 5vw;
  margin-top: 1.5vh;
}

.total {
  width: 99%;
  height: 100%;
  // margin-top: 0vh;
  margin-left: 1vw;
  font-family: MicrosoftYaHei;
  font-size: 0.78vw;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0vh;

  .maskbok {
    width: 103vw;
    height: 100vh;
    margin-left: -3vw;
    background: rgba(0, 0, 0, 0.5);
    margin-top: -7vh;
    position: absolute;
    z-index: 99;
  }

  .part1 {
    // background-color: blue;
    line-height: 1.85vh;
    margin-top: 2.2vh;

    .words {
      width: 5vw;
      color: #5ca4a6;
      text-align: center;
    }

    .num1 {
      width: 5vw;
      color: #d9feff;
      text-align: center;
      cursor: pointer;
    }

    .num2 {
      width: 5vw;
      color: #14a4ff;
      text-align: center;
    }

    .float1 {
      margin-left: 6.41vw;
    }

    .float2 {
      margin-left: 6.7vw;
    }

    .float3 {
      margin-left: 7.08vw;
    }

    .float4 {
      margin-left: 3.44vw;
    }
  }

  .part2 {
    width: 96vw;
    height: 73vh;
    background-image: url("~@/assets/images/CfbBoiler/index_bg.png");
    background-size: 100% 100%;
    margin-top: -1vh;

    .buttonx {
      width: 0.94vw;
      height: 1.67vh;
      border: 2px solid rgb(217, 243, 145);
      text-align: center;
      line-height: 1.7vh;
      background-color: #00e4ff;
      cursor: pointer;
      color: white;
    }

    .buttonr {
      width: 0.94vw;
      height: 1.67vh;
      border: 2px solid rgb(217, 243, 145);
      text-align: center;
      line-height: 1.7vh;
      background-color: #04ff57;
      cursor: pointer;
      color: white;
    }

    .left {
      width: 45vw;

      // background-color: black;
      .block1 {
        margin-top: 1vh;

        .num1 {
          // margin-top: -2vh;
          width: 5vw;
          color: #d9feff;
          text-align: center;
          cursor: pointer;
        }

        .float1 {
          margin-left: 6.41vw;
        }

        .float2 {
          margin-left: 2.98vw;
        }

        .float3 {
          margin-left: 1.98vw;
        }

        .float4 {
          margin-left: 2vw;
        }

        .float5 {
          margin-left: 0vw;
        }
      }

      .block2 {
        .device {
          width: 9.32vw;
          height: 2.69vh;
          font-size: 1.56vw;
          line-height: 1.85vh;
          text-align: center;
          color: #ffffff;
          margin-top: 13vh;
          cursor: pointer;
        }

        .water_level_ball {
          margin-left: 6.45vw;
          margin-top: 3.8vh;

          .water_level {
            line-height: 1.85vh;

            .words {
              width: 2.7vw;
              color: white;
              text-align: center;
            }

            .num1 {
              width: 5vw;
              color: #d9feff;
              text-align: right;
              cursor: pointer;
            }

            .top {
              margin-top: 0.2vh;
            }
          }

          .location {
            margin-left: -6vw;
            margin-top: 2.9vh;
            z-index: 2;

            .num1 {
              font-size: 13px;
              font-weight: bold;
              width: 5vw;
              color: #43ff44;
              text-align: right;
              cursor: pointer;
            }
          }
        }

        .location1 {
          line-height: 1.85vh;
          margin-top: 13.63vh;
          margin-left: 4vw;

          .num1 {
            width: 5vw;
            color: #d9feff;
            text-align: right;
            cursor: pointer;
          }
        }

        .location2 {
          width: 4vw;
          line-height: 1.85vh;
          margin-top: 3.54vh;
          margin-left: 3vw;

          .num1 {
            width: 4vw;
            color: #d9feff;
            text-align: right;
            cursor: pointer;
          }

          .num3 {
            color: #d9feff;
            margin-left: 0.3vw;
          }
        }
      }

      .block3 {
        position: relative;

        .zy {
          width: 7.5vw;
          height: 16vh;
          position: absolute;
          right: -2vw;
          top: -2vh;
          cursor: pointer;
        }

        .one {
          margin-top: 28.8vh;
          margin-left: 0.52vw;

          .valve {
            width: 1.35vw;
            height: 2.31vh;
            background-image: url("~@/assets/images/CfbBoiler/valve01.png");
            background-size: 100% 100%;
          }

          .float1 {
            margin-left: 0.1vw;
            margin-top: 6.07vh;
          }

          .float2 {
            margin-left: 0.2vw;
          }

          .float3 {
            margin-left: 0.2vw;
            margin-top: 6.07vh;
          }

          .float4 {
            margin-left: 0.2vw;
          }

          .float5 {
            margin-left: 0.2vw;
            margin-top: 6.07vh;
          }
        }

        .two {
          margin-left: -1vw;

          .location1 {
            line-height: 1.85vh;
            color: #d9feff;
            margin-top: 7.32vh;

            .num1 {
              width: 4vw;
              color: #d9feff;
              text-align: center;
              cursor: pointer;
              cursor: pointer;
            }

            .float2 {
              margin-top: -0.7vh;
              margin-left: 3.54vw;
            }

            .float3 {
              margin-top: -0.9vh;
            }

            .float4 {
              margin-top: -0.8vh;
              margin-left: 3.54vw;
            }

            .float5 {
              margin-top: -0.9vh;
            }

            .float6 {
              margin-top: -0.8vh;
              margin-left: 3.54vw;
            }
          }

          .location2 {
            margin-top: 16vh;
            margin-left: 1vw;

            .words {
              line-height: 1.85vh;
              letter-spacing: 0.14vh;
              width: 5vw;
              color: #5ca4a6;
              text-align: center;
            }

            .num1 {
              width: 5vw;
              color: #d9feff;
              line-height: 2.22vh;
              text-align: center;
              cursor: pointer;
            }

            .num3 {
              color: #d9feff;
              line-height: 2.22vh;
              margin-left: 0.3vw;
            }

            .float1 {
              margin-left: 1.2vw;
              margin-bottom: 0.2vh;
            }
          }
        }

        .three {
          .location1 {
            margin-left: 3.5vw;
            margin-top: 2.7vh;

            .shadow {
              width: 3vw;
              height: 2.13vh;
              background-color: #000000;
              opacity: 0.2;
            }

            .words {
              line-height: 1.85vh;
              letter-spacing: 0.14vh;
              width: 3vw;
              color: #d9feff;
              text-align: center;
            }

            .num1 {
              width: 5vw;
              color: #d9feff;
              line-height: 2.22vh;
              text-align: center;
              cursor: pointer;
            }

            .num4 {
              width: 5vw;
              color: #14a4ff;
              line-height: 2.22vh;
              text-align: center;
              cursor: pointer;
            }

            .num6 {
              width: 5vw;
              color: #d9feff;
              line-height: 2.22vh;
              text-align: center;
              cursor: pointer;
            }

            .float1 {
              margin-top: 0.8vh;
            }

            .float2 {
              margin-left: 5.2vw;
            }
          }

          .location2 {
            width: 14vw;
            line-height: 1.85vh;
            margin-top: 1vh;
            margin-left: 1.5vw;

            .num1 {
              width: 3.5vw;
              color: #d9feff;
              line-height: 2.22vh;
              text-align: right;
              cursor: pointer;
            }

            .num3 {
              color: #d9feff;
              line-height: 2.22vh;
              margin-left: 0.3vw;
            }

            .left {
              margin-left: 2vw;
            }

            .right {
              margin-left: -1vw;
            }
          }

          .location3 {
            line-height: 1.75vh;
            margin-left: 2.7vw;

            .num1 {
              width: 4vw;
              color: #d9feff;
              text-align: center;
              cursor: pointer;
            }

            .num3 {
              width: 5vw;
              color: #d9feff;
              text-align: center;
              cursor: pointer;
            }

            .num4 {
              width: 5vw;
              color: #14a4ff;
              text-align: center;
              cursor: pointer;
            }

            .float1 {
              margin-left: 4vw;
            }

            .float2 {
              margin-left: 4.2vw;
            }

            .float3 {
              margin-left: 0.3vw;
            }

            .float4 {
              margin-left: 0.3vw;
            }

            .float5 {
              margin-left: 0.3vw;
            }

            .float6 {
              margin-left: 3.8vw;
            }
          }

          .location4 {
            margin-left: 7vw;

            .num4 {
              width: 2vw;
              color: #14a4ff;
              line-height: 2.22vh;
              text-align: right;
              cursor: pointer;
            }

            .num5 {
              width: 2vw;
              color: #14a4ff;
              line-height: 2.22vh;
              margin-left: 0.3vw;
              cursor: pointer;
            }
          }

          .location5 {
            width: 12vw;
            line-height: 1.85vh;
            margin-top: 1vh;
            margin-left: 1vw;

            .num1 {
              width: 4.2vw;
              color: #d9feff;
              line-height: 2.22vh;
              text-align: right;
              cursor: pointer;
            }

            .num3 {
              color: #d9feff;
              line-height: 2.22vh;
              margin-left: 0.3vw;
            }

            .left {
              width: 6vw;
              margin-left: 0vw;
            }

            .right {
              width: 6vw;
              margin-left: 0vw;
            }
          }

          .location6 {
            margin-top: 1.7vh;
            margin-left: -0.3vw;
            line-height: 2.22vh;

            .num1 {
              width: 1.6vw;
              color: #d9feff;
              text-align: right;
              cursor: pointer;
            }

            .num3 {
              color: #d9feff;
              margin-left: 0.5vw;
            }

            .float1 {
              margin-left: 1.78vw;
            }
          }

          .location7 {
            width: 16vw;
            height: 8.8vh;
            line-height: 1.55vh;
            margin-top: 1vh;
            margin-left: 1vw;

            .num1 {
              color: #d9feff;
              line-height: 2.22vh;
              text-align: right;
              cursor: pointer;
              // background-color: red;
            }

            .left {
              width: 5.5vw;
              margin-left: 0vw;
              // background-color: red;
            }

            .right {
              width: 5.5vw;
              margin-left: 0vw;
            }
          }
        }

        .four {
          margin-left: -0.7vw;

          .location1 {
            margin-left: 2.5vw;
            margin-top: 8vh;
            line-height: 1.87vh;

            .words {
              width: 5vw;
              color: #5ca4a6;
            }

            .num2 {
              width: 5vw;
              color: #14a4ff;
              text-align: center;
              margin-left: -4.5vw;
              cursor: pointer;
            }

            .num4 {
              width: 1.2vw;
              color: #14a4ff;
              text-align: right;
              cursor: pointer;
            }

            .num5 {
              width: 2vw;
              color: #14a4ff;
              margin-left: 0.9vw;
              cursor: pointer;
            }

            .float1 {
              margin-top: 0.3vh;
            }
          }

          .location2 {
            margin-top: 26.5vh;
            margin-left: 2.5vw;

            .float1 {
              margin-left: 0.1vw;
            }

            .words {
              line-height: 2.22vh;
              color: #5ca4a6;
            }
          }
        }
      }
    }

    .middle {
      width: 17vw;
      // background-color: red;
      // margin-left: 0vw;
      margin-top: 6.8vh;
      line-height: 1.87vh;

      .words {
        line-height: 1.85vh;
        letter-spacing: 0.14vh;
        width: 4vw;
        color: #5ca4a6;
        text-align: center;
      }

      .num1 {
        width: 4vw;
        color: #d9feff;
        cursor: pointer;
      }

      .num6 {
        width: 3vw;
        color: #14a4ff;
        cursor: pointer;
      }

      .num3 {
        color: #d9feff;
        margin-left: 0.3vw;
      }

      .num4 {
        width: 3.6vw;
        color: #14a4ff;
        line-height: 2.22vh;
        text-align: right;
        cursor: pointer;
      }

      .num5 {
        width: 2vw;
        color: #14a4ff;
        line-height: 2.22vh;
        margin-left: 0.3vw;
        cursor: pointer;
      }

      .button1 {
        width: 2.6vw;
        height: 1.85vh;
        background-color: #14705b;
        text-align: center;
        cursor: pointer;
      }

      .word1 {
        line-height: 1.8vh;
        color: #75e3ca;
      }

      .location1 {
        width: 4vw;
        height: 3vh;
        margin-left: 7vw;
      }

      .location2 {
        margin-top: 2vh;
        margin-left: 7.1vw;

        .up {
          margin-top: 0.3vh;
        }

        .right {
          margin-left: 0.2vw;
        }
      }

      .location3 {
        margin-top: 0.3vh;
        margin-left: 4.5vw;
      }

      .location4 {
        width: 6vw;
        height: 15vh;
        margin-top: -1.6vh;
        margin-left: 11.6vw;

        .float1 {
          margin-left: 2vw;
        }

        .float2 {
          margin-left: 0.3vw;
        }

        .float3 {
          margin-left: 0.3vw;
        }

        .float4 {
          margin-top: 6.2vh;
        }
      }

      .location5 {
        // background-color: red;
        margin-top: 0.5vh;
        margin-left: 0vw;

        .num1 {
          width: 7vw;
          color: #d9feff;
          cursor: pointer;
        }
      }

      .location6 {
        margin-top: 3vh;
        margin-left: -0.3vw;

        .num1 {
          width: 7vw;
          color: #d9feff;
          cursor: pointer;
        }
      }

      .location7 {
        margin-top: 7vh;
        margin-left: 11.6vw;

        .float1 {
          margin-left: 0.5vw;
        }
      }

      .location8 {
        margin-top: 4.5vh;
        margin-left: 17vw;
      }
    }

    .rightt {
      width: 30vw;
      // background-color: blueviolet;
      margin-left: 4vw;
      line-height: 1.85vh;

      .valve {
        width: 1.25vw;
        height: 2.41vh;
        background-image: url("~@/assets/images/CfbBoiler/valve02.png");
        background-size: 100% 100%;
      }

      .fan {
        width: 3.13vw;
        height: 4.81vh;
        background-image: url("~@/assets/images/CfbBoiler/fan.png");
        background-size: 100% 100%;
      }

      .words {
        letter-spacing: 0.14vh;
        width: 5vw;
        color: #5ca4a6;
        text-align: center;
      }

      .num1 {
        width: 2vw;
        color: #d9feff;
        text-align: right;
        cursor: pointer;
      }

      .num3 {
        color: #d9feff;
        margin-left: 0.1vw;
      }

      .num4 {
        width: 2vw;
        color: #14a4ff;
        line-height: 2.22vh;
        text-align: right;
        cursor: pointer;
      }

      .num5 {
        width: 2vw;
        color: #14a4ff;
        line-height: 2.22vh;
        margin-left: 0.3vw;
        cursor: pointer;
      }

      .one {
        .tap {
          line-height: 3.7vh;
          margin-top: 3.3vh;
          margin-left: 4.59vw;

          .float1 {
            margin-left: 1.51vw;
            margin-top: 0.7vh;
          }

          .float2 {
            margin-left: 1.51vw;
          }

          .float3 {
            margin-left: 1.51vw;
            margin-top: 0.9vh;
          }

          .float4 {
            margin-left: 1.51vw;
            margin-top: 1vh;
          }

          .float5 {
            margin-left: 1.51vw;
            margin-top: 1vh;
          }
        }

        .switch {
          margin-top: 3vh;
          margin-left: 4vw;

          .right {
            margin-left: 0.1vw;
          }

          .top {
            margin-top: 1vh;
          }

          .float1 {
            margin-top: 1.4vh;
            margin-left: 2vw;
          }

          .float2 {
            line-height: 1.5vh;
            margin-top: -1.2vh;
            margin-left: 0.6vw;
            .num3 {
              margin-left: 0.6vw;
            }
          }

          .float3 {
            margin-left: -1.2vw;
            margin-top: 0.5vh;
          }

          .float4 {
            line-height: 1.5vh;
            margin-top: -0.8vh;
            margin-left: 0.1vw;
            .num3 {
              margin-left: 0.6vw;
            }
          }
        }
      }

      .two {
        // background-color: red;
        // margin-top: 0.5vh;
        .up {
          .float1 {
            margin-left: 0.5vw;
            margin-top: 5.8vh;
          }

          .float2 {
            margin-left: 1vw;
            margin-top: 2.4vh;
          }

          .float3 {
            margin-left: 0.83vw;
            margin-top: 0.66vh;
          }

          .float4 {
            margin-top: 2.41vh;
          }

          .float5 {
            margin-left: 1.2vw;
            margin-top: 2.31vh;
          }

          .float6 {
            margin-left: 0.46vw;
          }
        }

        .down {
          margin-top: 0.3vh;

          .float1 {
            margin-left: 0.5vw;
            margin-top: -0.5vh;
          }

          .float2 {
            margin-left: 1vw;
            margin-top: 0.5vh;
          }

          .float3 {
            margin-left: 0.83vw;
            margin-top: -1vh;
          }

          .float4 {
            margin-top: 0.5vh;
          }

          .float5 {
            margin-left: 1.26vw;
            margin-top: 0.71vh;
          }

          .float6 {
            margin-left: 0.46vw;
          }
        }

        .buttons {
          margin-top: 4.4vh;
          margin-left: 1vw;

          .button1 {
            width: 4vw;
            height: 1.85vh;
            background-color: #0c6080;
            border-radius: 1px;
            text-align: center;

            .word1 {
              line-height: 1.7vh;
              color: #ffffff;
            }
          }

          .button2 {
            width: 4vw;
            height: 1.85vh;
            background-color: #0c6080;
            border-radius: 1px;
            text-align: center;

            .word2 {
              line-height: 1.7vh;
              color: #49c3f1;
            }
          }

          .line1 {
            margin-top: 0.15vh;
          }

          .right {
            margin-left: 0.2vw;
          }

          .words {
            margin-top: 0.8vh;
          }
        }
      }

      .three {
        // margin-top: 0.2vh;
        .up {
          .float1 {
            margin-left: 0.5vw;
            margin-top: 5vh;
          }

          .float2 {
            margin-left: 1vw;
            margin-top: 2.4vh;
          }

          .float3 {
            margin-left: 0.83vw;
            margin-top: 0.46vh;
          }

          .float4 {
            margin-left: 0.3vw;
            margin-top: 1.4vh;
          }

          .float5 {
            margin-left: 0.86vw;
            margin-top: 1.5vh;
          }

          .float6 {
            margin-left: 0.26vw;
          }
        }

        .down {
          margin-top: 0.3vh;

          .float1 {
            margin-left: 0.5vw;
            margin-top: -0.5vh;
          }

          .float2 {
            margin-left: 1vw;
            margin-top: 0.5vh;
          }

          .float3 {
            margin-left: 0.83vw;
            margin-top: -1vh;
          }

          .float4 {
            margin-left: 0.3vw;
            margin-top: 0.2vh;
          }

          .float5 {
            margin-left: 1.06vw;
            margin-top: 0.51vh;
          }

          .float6 {
            margin-left: 0.26vw;
          }
        }

        .buttons {
          margin-top: 5.8vh;
          margin-left: 1.5vw;

          .line1 {
            margin-top: 0.2vh;
          }

          .right {
            margin-left: 0.2vw;
          }

          .float1 {
            margin-left: 1.4vw;
          }

          .float2 {
            margin-left: -0.5vw;
            margin-top: 0.3vh;
          }

          .words {
            margin-top: 0.8vh;
          }
        }
      }

      .four {
        margin-left: 9.5vw;

        // background-color: red;
        .up {
          .float1 {
            margin-top: 2.4vh;
          }

          .float2 {
            margin-left: -0.3vw;
          }

          .float3 {
            margin-top: 4.6vh;
            margin-left: 0.5vw;
          }

          .float4 {
            margin-left: 0.5vw;
            margin-top: 0.5vh;
          }

          .float5 {
            margin-top: 2vh;
            .num3 {
              margin-left: 0.6vw;
            }
          }
        }

        .down {
          margin-top: 1.2vh;

          .float1 {
            margin-top: 1.6vh;
          }

          .float2 {
            margin-left: -0.3vw;
          }

          .float3 {
            margin-top: 4vh;
            margin-left: 0.5vw;
          }

          .float4 {
            margin-left: 0.5vw;
            margin-top: 0vh;
          }

          .float5 {
            margin-top: 1vh;
            .num3 {
              margin-left: 0.6vw;
            }
          }
        }

        .buttons {
          margin-top: 4.8vh;
          margin-left: 1vw;

          .line1 {
            margin-top: 0.3vh;
          }

          .right {
            margin-left: 0.2vw;
          }

          .float1 {
            margin-left: 1.4vw;
          }

          .float2 {
            margin-left: -0.5vw;
            margin-top: 0.3vh;
          }
        }
      }
    }
  }
}

::v-deep {
  .dv-water-pond-level text {
    font-size: 13px;
    dominant-baseline: hanging;
  }
}
</style>
