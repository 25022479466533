<template>
  <div class="total drag" v-draw id="CfbParameter1">
    <!-- 抬头标题 -->
    <div class="flex">
      <div class="title-hang flex">
        <div class="title">{{  projectData.project }}_参数01_1</div>
        <div class="icon" @click="closeCompon"></div>
      </div>
    </div>
    <!-- 标题 -->
    <div class="main">
      <div class="main_title">汽包水位优化控制模型</div>
    </div>
    <!-- 主体内容 -->
    <div class="context">
      <div class="top flex">
        <div>
          <div class="flex">
            <div class="button">
              <div
                class="button_word"
                :style="{
                  background:
                    infoList.SWHSEL == 1 ? '#2AFC30' : 'red',
                }"
                @click="toIpt('1', '水位1', 'SWHSEL', infoList.SWHSEL_node_string, 'true')"
              >
                水位1
              </div>
            </div>
            <div
              class="column3"
              @click="
                toCompon(2, 'LT101D_B', infoList.LT101D_B_node_string, 'LT101D_BVV1', '汽包水位1')
              "
              @dblclick="
                Cclick(
                  infoList.LT101D_B,
                  'LT101D_B',
                  infoList.LT101D_B_node_string,
                  'LT101D_BVV1'
                )
              "
            >
              {{ 'LT101D_B' in infoList? infoList.LT101D_B :0}}
            </div>
          </div>
          <div class="float1 flex">
            <div class="button">
              <div
                class="button_word"
                :style="{
                  background:
                    infoList.SWHSEL == 2 ? '#2AFC30' : 'red',
                }"
                @click="toIpt('2', '水位2', 'SWHSEL', infoList.SWHSEL_node_string, 'true')"
              >
                水位2
              </div>
              <!-- @click="chanereast(infoList[node].MCSGEISHUI.SWHSEL && infoList[node].MCSGEISHUI.SWHSEL == 2 ? '253' : '2','确定要改变该水位的选择吗','SWHSEL',node)">
								水位
							</div> -->
            </div>
            <div
              class="column3"
              @click="
                toCompon(2, 'LT102D_B', infoList.LT102D_B_node_string, 'LT102D_BVV1', '汽包水位2')
              "
              @dblclick="
                Cclick(
                  infoList.LT102D_B,
                  'LT102D_B',
                  infoList.LT102D_B_node_string,
                  'LT102D_BVV1'
                )
              "
            >
              {{ 'LT102D_B' in infoList? infoList.LT102D_B :0}}
            </div>
          </div>
        </div>
        <div class="shadow shadow1">
          <div class="flex">
            <div class="column1">A01-JBSP</div>
            <div
              class="column2"
              @click="
                toIpt(
                  infoList.SWSP_B1,
                  'A01-JBSP',
                  'SWSP_B1',
                  infoList.SWSP_B1_node_string
                )
              "
            >
              {{ 'SWSP_B1' in infoList? infoList.SWSP_B1 :0}}
            </div>
          </div>
          <div class="flex">
            <div class="flex">
              <div class="column1">A01-KSP</div>
              <div
                class="column2"
                @click="
                  toIpt(
                    infoList.KZQSW,
                    'A01-KSP',
                    'KZQSW',
                    infoList.KZQSW_node_string
                  )
                "
              >
                {{ 'KZQSW' in infoList? infoList.KZQSW :0}}
              </div>
            </div>
            <div class="flex">
              <div class="column1">A01-SJSP</div>
              <div
                class="column3"
                @click="
                  toCompon(
                    2,
                    'SWSJSP',
                    infoList.SWSJSP_node_string,
                    'SWSJSPVV1',
                    '水位实际控制点'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.SWSJSP,
                    'SWSJSP',
                    infoList.SWSJSP_node_string,
                    'SWSJSPVV1'
                  )
                "
              >
                {{ 'SWSJSP' in infoList? infoList.SWSJSP :0}}
              </div>
            </div>
          </div>
        </div>
        <div class="button1">
          <div
            class="button1_word"
            @click="
              toIpt(infoList.GSMOD, '按钮', 'GSMOD', infoList.GSMOD_node_string)
            "
          >
            {{ 'GSMOD' in infoList? infoList.GSMOD ? "单回路模式" : "三冲量模式" :0}}
          </div>
        </div>
      </div>
      <div class="bottom flex">
        <div class="one">
          <div class="flex">
            <div>
              <div class="flex">
                <div class="button">
                  <div
                    class="button_word"
                    :style="{
                      background:
                        infoList.SWHSEL == 4 ? '#2AFC30' : 'red',
                    }"
                    @click="toIpt('4', '水位3', 'SWHSEL', infoList.SWHSEL_node_string, 'true')"
                  >
                    水位3
                  </div>
                  <!-- @click="chanereast(infoList[node].MCSGEISHUI.SWHSEL && infoList[node].MCSGEISHUI.SWHSEL == 4 ? '251' : '4','确定要改变该水位的选择吗','SWHSEL',node)">
										水位3
									</div> -->
                </div>
                <div
                  class="column3"
                  @click="
                    toCompon(
                      2,
                      'LT103D_B',
                      infoList.LT103D_B_node_string,
                      'LT103D_BVV1',
                      '汽包水位3'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.LT103D_B,
                      'LT103D_B',
                      infoList.LT103D_B_node_string,
                      'LT103D_BVV1'
                    )
                  "
                >
                  {{ 'LT103D_B' in infoList? infoList.LT103D_B :0}}
                </div>
              </div>
              <div class="float1 flex">
                <div class="button">
                  <div
                    class="button_word"
                    :style="{
                      background:
                        infoList.SWHSEL == 8 ? '#2AFC30' : 'red',
                    }"
                    @click="toIpt('8', '水位4', 'SWHSEL', infoList.SWHSEL_node_string, 'true')"
                  >
                    水位4
                  </div>
                  <!-- @click="chanereast(infoList[node].MCSGEISHUI.SWHSEL && infoList[node].MCSGEISHUI.SWHSEL == 8 ? '247' : '8','确定要改变该水位的选择吗','SWHSEL',node)">
										水位4
									</div> -->
                </div>
                <div
                  class="column3"
                  @click="
                    toCompon(
                      2,
                      'LT104D_B',
                      infoList.LT104D_B_node_string,
                      'LT104D_BVV1',
                      '汽包水位4'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.LT104D_B,
                      'LT104D_B',
                      infoList.LT104D_B_node_string,
                      'LT104D_BVV1'
                    )
                  "
                >
                  {{ 'LT104D_B' in infoList? infoList.LT104D_B :0}}
                </div>
              </div>
              <div class="flex">
                <div class="word">坏值阈值</div>
                <div
                  class="column4"
                  @click="
                    toIpt(
                      infoList.TEMP3,
                      '坏值阈值',
                      'TEMP3',
                      infoList.TEMP3_node_string
                    )
                  "
                >
                  {{ 'TEMP3' in infoList? infoList.TEMP3 :0}}
                </div>
              </div>
            </div>
            <div class="shadow shadow1">
              <div class="flex">
                <div class="column1">A01-SWPV</div>
                <div
                  class="column3"
                  @click="
                    toCompon(
                      2,
                      'SWSJPV',
                      infoList.SWSJPV_node_string,
                      'SWSJPVVV1',
                      '汽包水位均值'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.SWSJPV,
                      'SWSJPV',
                      infoList.SWSJPV_node_string,
                      'SWSJPVVV1'
                    )
                  "
                >
                  {{ 'SWSJPV' in infoList? infoList.SWSJPV :0}}
                </div>
              </div>
              <div class="flex">
                <div class="column1">A01-SWTC</div>
                <div
                  class="column2"
                  @click="
                    toIpt(
                      infoList.SWTC,
                      'A01-SWTC',
                      'SWTC',
                      infoList.SWTC_node_string
                    )
                  "
                >
                  {{ 'SWTC' in infoList? infoList.SWTC :0}}
                </div>
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="shadow shadow2">
              <div class="flex">
                <div class="column1">A02-FGS</div>
                <div
                  class="column3"
                  @click="
                    toCompon(
                      2,
                      'FT101D_B',
                      infoList.FT101D_B_node_string,
                      'FT101D_BVV1',
                      '给水流量'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.FT101D_B,
                      'FT101D_B',
                      infoList.FT101D_B_node_string,
                      'FT101D_BVV1'
                    )
                  "
                >
                  {{ 'FT101D_B' in infoList? infoList.FT101D_B :0}}
                </div>
              </div>
              <div class="flex">
                <div class="column1">A02-FZQ</div>
                <div
                  class="column3"
                  @click="
                    toCompon(2, 'BYMNL59', infoList.BYMNL59_node_string, 'BYMNL59VV1', '主蒸汽流量')
                  "
                  @dblclick="
                    Cclick(
                      infoList.BYMNL59,
                      'BYMNL59',
                      infoList.BYMNL59_node_string,
                      'BYMNL59VV1'
                    )
                  "
                >
                  {{ 'BYMNL59' in infoList? infoList.BYMNL59 :0}}
                </div>
              </div>
              <div class="flex">
                <div class="column1">A02-QSTC</div>
                <div
                  class="column2"
                  @click="
                    toIpt(
                      infoList.QSTC,
                      'A02-QSTC',
                      'QSTC',
                      infoList.QSTC_node_string
                    )
                  "
                >
                  {{ 'QSTC' in infoList? infoList.QSTC :0}}
                </div>
              </div>
            </div>
            <div class="shadow shadow3 flex">
              <div class="column1">A02-QSPC</div>
              <div
                class="column3"
                @click="
                  toCompon(
                    2,
                    'PV',
                    infoList.XK0M_node_string,
                    'XK0M_PVVV1',
                    'XK0L测量值',
					'XK0M'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.XK0M.PV,
                    'PV',
                    infoList.XK0M_node_string,
                    'XK0M_PVVV1'
                  )
                "
              >
                {{ 'XK0M' in infoList? infoList.XK0M.PV :0}}
              </div>
            </div>
          </div>
        </div>
        <div class="two">
          <div
            class="buttonx float1"
            :style="{
              background:
                infoList.XK01 && !infoList.XK01.SP
                  ? '#2AFC30'
                  : 'red',
            }"
            @click="
              infoList.XK01
                ? toDetail(1, 'XK01', infoList.XK01_node_string, '', '汽包液位调节')
                : ''
            "
          >
            X
          </div>
          <div
            class="buttonx float2"
            :style="{
              background:
                infoList.XK0M && !infoList.XK0M.SP
                  ? '#2AFC30'
                  : 'red',
            }"
            @click="
              infoList.XK0M
                ? toDetail(
                    1,
                    'XK0M',
                    infoList.XK0M_node_string,
                    '',
                    '主给水阀门调节先控'
                  )
                : ''
            "
          >
            X
          </div>
          <div
            class="buttonx float3"
            :style="{
              background:
			  'MAN5'||'MAN6' in infoList?
                infoList.MAN5.RM == 1 ||
                infoList.MAN6.RM == 1
                  ? '#2AFC30'
                  : 'red': 'red',
            }"
            @click="toCompon(0, 'CFB_MANGS', 2)"
          >
            A
          </div>
          <div
            class="buttonx float4"
            :style="{
              background:
                infoList.XK0N && !infoList.XK0N.SP
                  ? '#2AFC30'
                  : 'red',
            }"
            @click="
              infoList.XK0N
                ? toDetail(
                    1,
                    'XK0N',
                    infoList.XK0N_node_string,
                    '',
                    '副给水阀门调节先控'
                  )
                : ''
            "
          >
            X
          </div>
        </div>
        <div class="three">
          <div class="shadow shadow1 float1 flex">
            <div class="column1">A03-A01</div>
            <div
              class="column3"
              @click="
                toCompon(
                  2,
                  'KF_SW1_B',
                  infoList.KF_SW1_B_node_string,
                  'KF_SW1_BVV1',
                  '汽包水位A调节阀反馈'
                )
              "
              @dblclick="
                Cclick(
                  infoList.KF_SW1_B,
                  'KF_SW1_B',
                  infoList.KF_SW1_B_node_string,
                  'KF_SW1_BVV1'
                )
              "
            >
              {{ 'KF_SW1_B' in infoList? infoList.KF_SW1_B :0}}
            </div>
          </div>
          <div class="shadow shadow1 float2 flex">
            <div class="column1">A03-A02</div>
            <div
              class="column3"
              @click="
                toCompon(
                  2,
                  'KF_SW2_B',
                  infoList.KF_SW2_B_node_string,
                  'KF_SW2_BVV1',
                  '汽包水位B调节阀反馈'
                )
              "
              @dblclick="
                Cclick(
                  infoList.KF_SW2_B,
                  'KF_SW2_B',
                  infoList.KF_SW2_B_node_string,
                  'KF_SW2_BVV1'
                )
              "
            >
              {{ 'KF_SW2_B' in infoList? infoList.KF_SW2_B :0}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <inputVal ref="inputVal"></inputVal>
    <Historical
      v-if="isHshow"
      @sendStatus="isHshow = false"
      :historyname="historyname"
      :node="node"
      :node1="node1"
      :Lkname="Lkname"
      :chName="chName"
      :infoList="infoList"
    ></Historical>
    <manyManual
      v-if="manyManual"
      :titname="Manualtitname"
      :numM="Manualnode"
      @sendStatus="isClose"
      :infoList="infoList"
    ></manyManual>
  </div>
</template>

 <script>
import inputVal from "@/components/inputVal.vue"; //输入框组件
import Historical from "@/components/Historicaljrl.vue"; //历史趋势
import manyManual from "@/components/manyManual1.vue"; //手操器组件
export default {
  name: "CfbParameter1",
  props: {
    infoList: {
      type: Object,
      default: () => {
        return {}; // 默认值
      },
    },
  },
  components: {
    inputVal,
    Historical,
    manyManual,
  },
  data: () => {
    return {
      chName: "",
      manyManual: false,
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      isComShow: false,
      isHshow: false,
      isMshow: false,
      isRshow: false,
      historyname: "",
      node: "",
      Lkname: "",
      isIndex: "",
      Firstcontrolname: "",
      Firstcontroltitname: "",
      Firstcontrolnode: "",
      isFshow: false,
      Rsfname: "",
      Rsfnode: "",
      Rsftitname: "",
      projectData: "",
      grouptime: null,
      spotArr: [],
      authInfo: [],
      UserInfo: [],
	  node1:''
    };
  },
  watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
        console.log(this.infoList);
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  created() {
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
    this.authInfo = JSON.parse(localStorage.getItem("autharr"));
    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
  },
  methods: {
    // 打开先控多窗口
    toDetail(key, name, nodename, Aname, titname) {
      if (!this.authInfo[1]) {
        let str = this.authInfo[0];
        // 判断是否为调试还是查看权限
        if (str.charAt(str.length - 1) == "r") {
          this.$message.error("暂无调试权限");
        }
      } else {
        let pathname = "";
        switch (key) {
			case 1:
            pathname = "firstcontolJRL";
            break;
          case 2:
            pathname = "Cfbkfirstcontol";
            break;
          case 3:
            pathname = "CfbRfirstcontol";
            break;
        }
        const newurl = this.$router.resolve({
          name: pathname,
          query: {
            Firstcontrolname: name,
            Firstcontrolnode: nodename,
            Firstcontroltitname: titname,
          },
        });
        window.open(newurl.href, "_blank");
      }
    },
    //水位点击事件
    chanereast(val, name, mark, node) {
      this.$confirm(name, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (typeof val == "boolean") {
            val = !val;
          }
          downAction({
            device: this.projectData.device,
            mark: mark,
            node: node,
            value: val,
            cp_type: this.projectData.type,
          })
            .then((res) => {
              if (res.info.res == 1) {
                this.infoList[node].mark = val;
                this.$message.success("成功");
              } else {
                this.$message.error("出错了");
              }
            })
            .catch((err) => {
              this.$message.error("下置失败");
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }

        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
    // 打开下置输入窗口
    toIpt(data, name, historyname, node, type) {
      if (!this.authInfo[1]) {
        let str = this.authInfo[0];
        // 判断是否为调试还是查看权限
        if (str.charAt(str.length - 1) == "r") {
          this.$message.error("暂无操作权限");
        }
      } else {
        this.$refs.inputVal.open(data, name, historyname, node, type);
      }
    },
    isshowfase() {
      this.isComShow = false;
    },
    closeCompon() {
      this.$emit("sendStatus", "Parameter01", false);
    },
    isClose(val, val1) {
      switch (val) {
        case "Historical":
          return (this.isHshow = val1);
        case "manyManual":
          return (this.manyManual = val1);
      }
    },
    toCompon(key, name, name2, name3, name4, titname) {
      this.isIndex = key;
      this.historyname = name;
      this.node = name2;
      this.Lkname = name3;
      clearTimeout(this.grouptime);
      this.grouptime = setTimeout(() => {
        switch (key) {
          case 0:
            return (
              (this.manyManual = true),
              (this.Manualtitname = name),
              (this.Manualnode = name2)
            );
          case 1:
            return (
              (this.isFshow = true),
              (this.Firstcontrolname = name),
              (this.Firstcontrolnode = name2),
              (this.Firstcontroltitname = titname)
            );
          case 2:
            return (this.isHshow = true), (this.chName = name4),this.node1=titname;
          case 4:
            return (
              (this.isRshow = true),
              (this.Rsfname = name),
              (this.Rsfnode = name2),
              (this.Rsftitname = titname)
            );
        }
      }, 300);
    },
  },
};
</script>

 <style lang="scss" scoped>
#CfbParameter1 {
  width: 53vw;
  height: 67vh;
  background-image: url("~@/assets/images/CfbBoiler/para031_bg.png");
  background-size: 100% 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -33.5vh 0 0 -26.5vw;
  z-index: 999;

  .title-hang {
    width: 53vw;
    height: 5vh;
    font-family: MicrosoftYaHei;
    font-size: 2vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vh;
    letter-spacing: 0vh;
    margin-left: 15vw;
  }

  .title {
    width: 19vw;
    height: 4vh;
    font-family: MicrosoftYaHei;
    font-size: 2vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 4vh;
    letter-spacing: 0vh;
    color: #0ef7ff;
    margin-top: 1vh;
    text-align: center;
    margin-left: 1.5vw;
  }

  .icon {
    width: 1.8vw;
    height: 3.2vh;
    background-image: url("~@/assets/images/RqBoiler/icon_close1.png");
    background-size: 100% 100%;
    margin-left: 15vw;
    margin-top: 1.8vh;
  }

  .main {
    width: 53vw;
    height: 2vh;
    margin-top: 3vh;

    .main_title {
      font-family: PingFang-SC-Regular;
      font-size: 3vh;
      font-weight: normal;
      font-stretch: normal;
      line-height: 2vh;
      letter-spacing: 1vh;
      color: #d5fffe;
      text-align: center;
    }
  }

  .context {
    width: 47.55vw;
    height: 24.54vh;
    margin-top: 7vh;
    margin-left: 3.13vw;
    font-family: PingFang-SC-Regular;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0vw;

    .shadow {
      background-color: #001b2860;
      box-shadow: 0vw 0vw 0vw 0vw #15384c;
      border-radius: 1px;
      border: solid 1px #236f8d;
      font-size: 1.2vh;
      line-height: 2.7vh;

      .column1 {
        width: 4.5vw;
        color: #8aeaff;
        margin-left: 0.3vw;
      }

      .column2 {
        width: 3vw;
        color: #00ffb4;
        text-align: right;cursor: pointer;
      }

      .column3 {
        width: 3vw;
        color: #00e4ff;
        text-align: right;cursor: pointer;
      }
    }

    .buttonx {
      width: 1.3vw;
      height: 2.2vh;
      border: 2px solid rgb(217, 243, 145);
      text-align: center;
      line-height: 2vh;
      color: white;cursor: pointer;
    }

    .buttonr {
      width: 1.3vw;
      height: 2.2vh;
      border: 2px solid rgb(217, 243, 145);
      text-align: center;
      line-height: 2vh;
      color: white;
    }

    .float1 {
      margin-top: 0.2vh;
    }

    .top {
      .column3 {
        width: 3vw;
        color: #00e4ff;
        text-align: right;cursor: pointer;
      }

      .button {
        width: 3.13vw;
        height: 2.59vh;
        background-color: #22fff7;
        border-radius: 1px;
        text-align: center;

        .button_word {
          font-size: 1.48vh;
          line-height: 2.59vh;
          color: #0a4c62;cursor: pointer;
        }
      }

      .shadow1 {
        width: 17vw;
        height: 5.56vh;
        margin-left: 0.73vw;
      }

      .button1 {
        width: 4.69vw;
        height: 2.22vh;
        background-color: #22fff7;
        border-radius: 1px;
        text-align: center;
        margin-left: 0.73vw;
        margin-top: 1.85vh;

        .button1_word {
          font-size: 1.2vh;
          line-height: 2.29vh;
          color: #0a4c62;cursor: pointer;
        }
      }
    }

    .bottom {
      .one {
        z-index: 1;

        .column3 {
          width: 3vw;
          color: #00e4ff;
          text-align: right;cursor: pointer;
        }

        .column4 {
          width: 2.75vw;
          color: #00ffb4;
          text-align: right;cursor: pointer;
        }

        .button {
          width: 3.13vw;
          height: 2.59vh;
          background-color: #22fff7;
          border-radius: 1px;
          text-align: center;

          .button_word {
            font-size: 1.48vh;
            line-height: 2.59vh;
            color: #0a4c62;cursor: pointer;
          }
        }

        .word {
          font-size: 1.48vh;
          line-height: 2.59vh;
          color: #8aeaff;
        }

        .shadow1 {
          width: 8.33vw;
          height: 5.56vh;
          margin-top: 3.13vh;
          margin-left: 0.73vw;
        }

        .shadow2 {
          width: 8.96vw;
          height: 8.33vh;
          margin-top: 1.7vh;
        }

        .shadow3 {
          width: 8.96vw;
          height: 2.78vh;
          margin-top: 7.09vh;
          margin-left: 2.34vw;
        }
      }

      .two {
        width: 30.26vw;
        height: 17.78vh;
        background-image: url("~@/assets/images/CfbBoiler/para011.png");
        background-size: 100% 100%;
        margin-left: -11.5vw;

        .float1 {
          margin-left: 12.6vw;
          margin-top: 3.24vh;
        }

        .float2 {
          margin-left: 18.33vw;
          margin-top: 4.35vh;
        }

        .float3 {
          margin-left: 28.8vw;
          margin-top: 0.32vh;
        }

        .float4 {
          margin-left: 23.33vw;
          margin-top: 0.32vh;
        }
      }

      .three {
        .shadow1 {
          width: 8.33vw;
          height: 2.78vh;
        }

        .float1 {
          margin-top: 9.44vh;
        }

        .float2 {
          margin-top: 3.8vh;
        }
      }
    }
  }
}
</style>
