var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"draw",rawName:"v-draw"}],staticClass:"total drag",attrs:{"id":"CfbParameterSmoke2"}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"title-hang flex"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.projectData.project)+"_参数02_2")]),_c('div',{staticClass:"icon",on:{"click":_vm.closeCompon}})])]),_vm._m(0),_c('div',{staticClass:"context flex"},[_c('div',{staticClass:"one"},[_c('div',{staticClass:"shadow shadow1 float1"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("C05-SP")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.ZQTSP_B2,
                'C05-SP',
                'ZQTSP_B2',
                _vm.infoList.ZQTSP_B2_node_string
              )}}},[_vm._v(" "+_vm._s("ZQTSP_B2" in _vm.infoList ? _vm.infoList.ZQTSP_B2 : 0)+" ")])])]),_c('div',{staticClass:"shadow shadow1"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("C05-PV")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toCompon(
                2,
                'BYMNL10',
                _vm.infoList.BYMNL10_node_string,
                'BYMNL10VV1',
                '再热蒸汽高过出口温度均值'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.BYMNL10,
                'BYMNL10',
                _vm.infoList.BYMNL10_node_string,
                'BYMNL10VV1'
              )}}},[_vm._v(" "+_vm._s("BYMNL10" in _vm.infoList ? _vm.infoList.BYMNL10 : 0)+" ")])])])]),_c('div',{staticClass:"two"},[_c('div',{staticClass:"bg1"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"buttonr float1",style:({
              background:
                _vm.infoList.RSF0J && !_vm.infoList.RSF0J.SP ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.RSF0J
                ? _vm.toDetail(
                    3,
                    'RSF0J',
                    _vm.infoList.RSF0J_node_string,
                    '',
                    '氨水流量软伺服'
                  )
                : ''}}},[_vm._v(" R ")]),_c('div',{staticClass:"button float2"},[_c('div',{staticClass:"button_word",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.RSF0JSEL,
                  '按钮',
                  'RSF0JSEL',
                  _vm.infoList.RSF0JSEL_node_string
                )}}},[_vm._v(" "+_vm._s("RSF0JSEL" in _vm.infoList ? _vm.infoList.RSF0JSEL ? "投用" : "切除" : 0)+" ")])])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"word"},[_vm._v("水煤比前馈")]),_c('div',{staticClass:"buttonr float3",style:({
              background:
                _vm.infoList.QK01 && !_vm.infoList.QK01.TS ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.QK01
                ? _vm.toDetail(
                    2,
                    'QK01',
                    _vm.infoList.QK01_node_string,
                    '',
                    '电负荷前馈'
                  )
                : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"button float4"},[_c('div',{staticClass:"button_word",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.SEL22,
                  '按钮',
                  'SEL22',
                  _vm.infoList.SEL22_node_string
                )}}},[_vm._v(" "+_vm._s("SEL22" in _vm.infoList ? (_vm.infoList.SEL22 ? "投用" : "切除") : 0)+" ")])])]),_c('div',{staticClass:"buttonx float5",style:({
            background:
              'MAN33' || 'MAN34' || 'MAN35' || 'MAN36' in _vm.infoList
                ? _vm.infoList.MAN33.RM == 1 ||
                  _vm.infoList.MAN34.RM == 1 ||
                  _vm.infoList.MAN35.RM == 1 ||
                  _vm.infoList.MAN36.RM == 1
                  ? '#2AFC30'
                  : 'red'
                : 'red',
          }),on:{"click":function($event){return _vm.toCompon(0, 'CFB_MANQWZ', 4)}}},[_vm._v(" A ")])])]),_c('div',{staticClass:"three"},[_c('div',{staticClass:"float1"},[_c('div',{staticClass:"shadow shadow2 flex"},[_c('div',{staticClass:"column1"},[_vm._v("C01-SP")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.ZQTSP21,
                'C01-SP',
                'ZQTSP21',
                _vm.infoList.ZQTSP21_node_string
              )}}},[_vm._v(" "+_vm._s("ZQTSP21" in _vm.infoList ? _vm.infoList.ZQTSP21 : 0)+" ")])]),_c('div',{staticClass:"shadow shadow2 flex"},[_c('div',{staticClass:"column1"},[_vm._v("C01-PV")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                2,
                'QWSJPV1',
                _vm.infoList.QWSJPV1_node_string,
                'QWSJPV1VV1',
                '一级左低过出口蒸汽温度'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.QWSJPV1,
                'QWSJPV1',
                _vm.infoList.QWSJPV1_node_string,
                'QWSJPV1VV1'
              )}}},[_vm._v(" "+_vm._s("QWSJPV1" in _vm.infoList ? _vm.infoList.QWSJPV1 : 0)+" ")])]),_c('div',{staticClass:"shadow shadow2 flex"},[_c('div',{staticClass:"column1"},[_vm._v("C01-TC")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(_vm.infoList.QWTC1, 'C01-TC', 'QWTC1', _vm.infoList.QWTC1_node_string)}}},[_vm._v(" "+_vm._s("QWTC1" in _vm.infoList ? _vm.infoList.QWTC1 : 0)+" ")])])]),_c('div',{staticClass:"float2"},[_c('div',{staticClass:"shadow shadow2 flex"},[_c('div',{staticClass:"column1"},[_vm._v("C02-SP")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.ZQTSP22,
                'C02-SP',
                'ZQTSP22',
                _vm.infoList.ZQTSP22_node_string
              )}}},[_vm._v(" "+_vm._s("ZQTSP22" in _vm.infoList ? _vm.infoList.ZQTSP22 : 0)+" ")])]),_c('div',{staticClass:"shadow shadow2 flex"},[_c('div',{staticClass:"column1"},[_vm._v("C02-PV")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                2,
                'QWSJPV2',
                _vm.infoList.QWSJPV2_node_string,
                'QWSJPV2VV1',
                '一级右低过出口蒸汽温度'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.QWSJPV2,
                'QWSJPV2',
                _vm.infoList.QWSJPV2_node_string,
                'QWSJPV2VV1'
              )}}},[_vm._v(" "+_vm._s("QWSJPV2" in _vm.infoList ? _vm.infoList.QWSJPV2 : 0)+" ")])]),_c('div',{staticClass:"shadow shadow2 flex"},[_c('div',{staticClass:"column1"},[_vm._v("C02-TC")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(_vm.infoList.QWTC2, 'C02-TC', 'QWTC2', _vm.infoList.QWTC2_node_string)}}},[_vm._v(" "+_vm._s("QWTC2" in _vm.infoList ? _vm.infoList.QWTC2 : 0)+" ")])])]),_c('div',{staticClass:"float3"},[_c('div',{staticClass:"shadow shadow2 flex"},[_c('div',{staticClass:"column1"},[_vm._v("C03-SP")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.ZQTSP_B2,
                'C03-SP',
                'ZQTSP_B2',
                _vm.infoList.ZQTSP_B2_node_string
              )}}},[_vm._v(" "+_vm._s("ZQTSP_B2" in _vm.infoList ? _vm.infoList.ZQTSP_B2 : 0)+" ")])]),_c('div',{staticClass:"shadow shadow2 flex"},[_c('div',{staticClass:"column1"},[_vm._v("C03-PV")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                2,
                'QWSJPV3',
                _vm.infoList.QWSJPV3_node_string,
                'QWSJPV3VV1',
                '二级左高过出口蒸汽温度'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.QWSJPV3,
                'QWSJPV3',
                _vm.infoList.QWSJPV3_node_string,
                'QWSJPV3VV1'
              )}}},[_vm._v(" "+_vm._s("QWSJPV3" in _vm.infoList ? _vm.infoList.QWSJPV3 : 0)+" ")])]),_c('div',{staticClass:"shadow shadow2 flex"},[_c('div',{staticClass:"column1"},[_vm._v("C03-TC")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(_vm.infoList.QWTC3, 'C03-TC', 'QWTC3', _vm.infoList.QWTC3_node_string)}}},[_vm._v(" "+_vm._s("QWTC3" in _vm.infoList ? _vm.infoList.QWTC3 : 0)+" ")])])]),_c('div',{staticClass:"float4"},[_c('div',{staticClass:"shadow shadow2 flex"},[_c('div',{staticClass:"column1"},[_vm._v("C04-SP")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.ZQTSP_B2,
                'C04-SP',
                'ZQTSP_B2',
                _vm.infoList.ZQTSP_B2_node_string
              )}}},[_vm._v(" "+_vm._s("ZQTSP_B2" in _vm.infoList ? _vm.infoList.ZQTSP_B2 : 0)+" ")])]),_c('div',{staticClass:"shadow shadow2 flex"},[_c('div',{staticClass:"column1"},[_vm._v("C04-PV")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                2,
                'QWSJPV4',
                _vm.infoList.QWSJPV4_node_string,
                'QWSJPV4VV1',
                '二级右高过出口蒸汽温度'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.QWSJPV4,
                'QWSJPV4',
                _vm.infoList.QWSJPV4_node_string,
                'QWSJPV4VV1'
              )}}},[_vm._v(" "+_vm._s("QWSJPV4" in _vm.infoList ? _vm.infoList.QWSJPV4 : 0)+" ")])]),_c('div',{staticClass:"shadow shadow2 flex"},[_c('div',{staticClass:"column1"},[_vm._v("C04-TC")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(_vm.infoList.QWTC4, 'C04-TC', 'QWTC4', _vm.infoList.QWTC4_node_string)}}},[_vm._v(" "+_vm._s("QWTC4" in _vm.infoList ? _vm.infoList.QWTC4 : 0)+" ")])])])]),_c('div',{staticClass:"four"},[_c('div',{staticClass:"bg2"},[_c('div',[_c('div',{staticClass:"buttonx float1",style:({
              background:
                _vm.infoList.XK0K && !_vm.infoList.XK0K.RM
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.XK0K
                ? _vm.toDetail(1, 'XK0K', _vm.infoList.XK0K_node_string, '', '左1烟气挡板调节')
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"buttonr float2",style:({
                background:
                  _vm.infoList.QK0A && !_vm.infoList.QK0A.TS ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.QK0A
                  ? _vm.toDetail(2, 'QK0A', _vm.infoList.QK0A_node_string, '', 'QK0A设定值')
                  : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"button float3"},[_c('div',{staticClass:"button_word",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.QK0ASEL,
                    '按钮',
                    'QK0ASEL',
                    _vm.infoList.QK0ASEL_node_string
                  )}}},[_vm._v(" "+_vm._s("QK0ASEL" in _vm.infoList ? (_vm.infoList.QK0ASEL ? "投用" : "切除") : 0)+" ")])])])]),_c('div',{staticClass:"float4"},[_c('div',{staticClass:"buttonx float1",style:({
              background:
                _vm.infoList.XK0L && !_vm.infoList.XK0L.RM
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.XK0L
                ? _vm.toDetail(1, 'XK0L', _vm.infoList.XK0L_node_string, '', '左1烟气挡板调节')
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"buttonr float2",style:({
                background:
                  _vm.infoList.QK0B && !_vm.infoList.QK0B.TS ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.QK0B
                  ? _vm.toDetail(2, 'QK0B', _vm.infoList.QK0B_node_string, '', 'QK0B设定值')
                  : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"button float3"},[_c('div',{staticClass:"button_word",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.QK0BSEL,
                    '按钮',
                    'QK0BSEL',
                    _vm.infoList.QK0BSEL_node_string
                  )}}},[_vm._v(" "+_vm._s("QK0BSEL" in _vm.infoList ? (_vm.infoList.QK0BSEL ? "投用" : "切除") : 0)+" ")])])])])]),_c('div',{staticClass:"bg2 float5"},[_c('div',[_c('div',{staticClass:"buttonx float1",style:({
              background:
                _vm.infoList.XK0A && !_vm.infoList.XK0A.RM
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.XK0A
                ? _vm.toDetail(1, 'XK0A', _vm.infoList.XK0A_node_string, '', '右1烟气挡板调节')
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"buttonr float2",style:({
                background:
                  _vm.infoList.QK05 && !_vm.infoList.QK05.TS ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.QK05
                  ? _vm.toDetail(
                      2,
                      'QK05',
                      _vm.infoList.QK05_node_string,
                      '',
                      '二级左再热前馈'
                    )
                  : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"button float3"},[_c('div',{staticClass:"button_word",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.QK05SEL,
                    '按钮',
                    'QK05SEL',
                    _vm.infoList.QK05SEL_node_string
                  )}}},[_vm._v(" "+_vm._s("QK05SEL" in _vm.infoList ? (_vm.infoList.QK05SEL ? "投用" : "切除") : 0)+" ")])])])]),_c('div',{staticClass:"float4"},[_c('div',{staticClass:"buttonx float1",style:({
              background:
                _vm.infoList.XK0B && !_vm.infoList.XK0B.RM
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.XK0B
                ? _vm.toDetail(1, 'XK0B', _vm.infoList.XK0B_node_string, '', '右2烟气挡板调节')
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"buttonr float2",style:({
                background:
                  _vm.infoList.QK06 && !_vm.infoList.QK06.TS ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.QK06
                  ? _vm.toDetail(
                      2,
                      'QK06',
                      _vm.infoList.QK06_node_string,
                      '',
                      '二级右再热前馈'
                    )
                  : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"button float3"},[_c('div',{staticClass:"button_word",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.QK06SEL,
                    '按钮',
                    'QK06SEL',
                    _vm.infoList.QK06SEL_node_string
                  )}}},[_vm._v(" "+_vm._s("QK06SEL" in _vm.infoList ? (_vm.infoList.QK06SEL ? "投用" : "切除") : 0)+" ")])])])])])]),_c('div',{staticClass:"five"},[_c('div',{staticClass:"shadow shadow3 float1 flex"},[_c('div',{staticClass:"column1"},[_vm._v("C01-A01")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(2, 'BCSZRAO1_B', _vm.infoList.BCSZRAO1_B_node_string, 'BCSZRAO1_BVV1')},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.BCSZRAO1_B,
              'BCSZRAO1_B',
              _vm.infoList.BCSZRAO1_B_node_string,
              'BCSZRAO1_BVV1'
            )}}},[_vm._v(" "+_vm._s("BCSZRAO1_B" in _vm.infoList ? _vm.infoList.BCSZRAO1_B : 0)+" ")])]),_c('div',{staticClass:"shadow shadow3 float2 flex"},[_c('div',{staticClass:"column1"},[_vm._v("C02-A02")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(2, 'BCSZRAO2_B', _vm.infoList.BCSZRAO2_B_node_string, 'BCSZRAO2_BVV1')},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.BCSZRAO2_B,
              'BCSZRAO2_B',
              _vm.infoList.BCSZRAO2_B_node_string,
              'BCSZRAO2_BVV1'
            )}}},[_vm._v(" "+_vm._s("BCSZRAO2_B" in _vm.infoList ? _vm.infoList.BCSZRAO2_B : 0)+" ")])]),_c('div',{staticClass:"shadow shadow3 float3 flex"},[_c('div',{staticClass:"column1"},[_vm._v("C03-A03")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(2, 'BCSZRAO3_B', _vm.infoList.BCSZRAO3_B_node_string, 'BCSZRAO3_BVV1')},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.BCSZRAO3_B,
              'BCSZRAO3_B',
              _vm.infoList.BCSZRAO3_B_node_string,
              'BCSZRAO3_BVV1'
            )}}},[_vm._v(" "+_vm._s("BCSZRAO3_B" in _vm.infoList ? _vm.infoList.BCSZRAO3_B : 0)+" ")])]),_c('div',{staticClass:"shadow shadow3 float4 flex"},[_c('div',{staticClass:"column1"},[_vm._v("C04-A04")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(2, 'BCSZRAO4_B', _vm.infoList.BCSZRAO4_B_node_string, 'BCSZRAO4_BVV1')},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.BCSZRAO4_B,
              'BCSZRAO4_B',
              _vm.infoList.BCSZRAO4_B_node_string,
              'BCSZRAO4_BVV1'
            )}}},[_vm._v(" "+_vm._s("BCSZRAO4_B" in _vm.infoList ? _vm.infoList.BCSZRAO4_B : 0)+" ")])])])]),_c('inputVal',{ref:"inputVal"}),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.node,"node1":_vm.node1,"Lkname":_vm.Lkname,"chName":_vm.chName,"infoList":_vm.infoList},on:{"sendStatus":function($event){_vm.isHshow = false}}}):_vm._e(),(_vm.isFshow)?_c('Firstcontrol',{attrs:{"infoList":_vm.infoList,"isIndex":_vm.isIndex,"historyname":_vm.Firstcontrolname,"titname":_vm.Firstcontroltitname,"node":_vm.Firstcontrolnode},on:{"sendStatus":function($event){_vm.isFshow = false}}}):_vm._e(),(_vm.isRshow)?_c('Rsf',{attrs:{"titname":_vm.Rsftitname,"infoList":_vm.infoList,"historyname":_vm.Rsfname,"node":_vm.Rsfnode},on:{"sendStatus":function($event){_vm.isRshow = false}}}):_vm._e(),(_vm.isComShow)?_c('ParameterYh',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isshowfase}}):_vm._e(),(_vm.manyManual)?_c('manyManual',{attrs:{"titname":_vm.Manualtitname,"numM":_vm.Manualnode,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"main_title"},[_vm._v("再热蒸汽温度控制模型（烟气挡板）")])])}]

export { render, staticRenderFns }